import DashboardHeader from '@/components/dashboard/header';
import InvoicesTable from '@/components/invoices/inovoices-table';
import { useCurrentUserContext } from '@/components/providers/user/use-current-user-context';
import { useTranslation } from 'react-i18next';

export default function Invoices() {
  const { t } = useTranslation('invoice');

  const { user } = useCurrentUserContext();

  return (
    <div>
      <DashboardHeader title={t('title')} />
      <InvoicesTable clientId={user?.client?.id} clientName={user?.client?.name} />
    </div>
  );
}
