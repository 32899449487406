import { Button } from 'antd';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { monthsMap } from '@/lib/utils';

type Props = {
  onDateFilterClick: (selected: { month: number; year: number }) => Promise<void>;
  selectedDateFilter: { month: number; year: number } | undefined;
  isLoading: boolean;
};

export default function StatementDateFilters({
  onDateFilterClick,
  selectedDateFilter,
  isLoading,
}: Props) {
  const { t } = useTranslation('common');

  const [dateFilterArray, setDateFilterArray] = useState(
    Array.from({ length: 4 }, (_, index) => {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();

      const month = (currentMonth - index + 12) % 12;
      const year = month === 12 ? currentYear - 1 : currentYear;

      return { month, year };
    }),
  );

  function onLeftArrowClick() {
    setDateFilterArray(
      dateFilterArray.map(item => ({
        month: item.month === 12 ? 1 : item.month + 1,
        year: item.month === 12 ? item.year + 1 : item.year,
      })),
    );
  }

  function onRightArrowClick() {
    setDateFilterArray(
      dateFilterArray.map(item => ({
        month: item.month === 1 ? 12 : item.month - 1,
        year: item.month === 1 ? item.year - 1 : item.year,
      })),
    );
  }

  return (
    <div className={twMerge('flex  gap-2 mb-6 flex-wrap')}>
      <LeftOutlined
        onClick={onLeftArrowClick}
        disabled={
          dateFilterArray[0].month === new Date().getMonth() &&
          dateFilterArray[0].year === new Date().getFullYear()
        }
      />

      {dateFilterArray.map(item => {
        const isSelected =
          selectedDateFilter?.month === item.month && selectedDateFilter.year === item.year;

        return (
          <Button
            onClick={() => onDateFilterClick(item)}
            ghost={isSelected}
            type={isSelected ? 'primary' : 'default'}
            loading={isSelected && isLoading}
            className="w-36"
          >
            {/* @ts-expect-error month will always match something as they are stored in db from 1 to 12 */}
            {t(`months.${monthsMap[item.month]}`)} {item.year}
          </Button>
        );
      })}

      <RightOutlined onClick={onRightArrowClick} />
    </div>
  );
}
