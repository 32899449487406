import { create } from 'zustand';

type SideMenuStore = {
  isSideMenuOpen: boolean;
  openSideMenu: () => void;
  closeSideMenu: () => void;
  toggleSideMenu: () => void;
};

export const useSideMenuStore = create<SideMenuStore>(set => ({
  isSideMenuOpen: true,
  openSideMenu: () => set({ isSideMenuOpen: true }),
  closeSideMenu: () => set({ isSideMenuOpen: false }),
  toggleSideMenu: () => set(state => ({ isSideMenuOpen: !state.isSideMenuOpen })),
}));
