import { useRef, useState } from 'react';
import { z } from 'zod';
import { Button, Input, Modal } from 'antd';
import EditTwoTone from '@ant-design/icons/EditTwoTone';
import { components } from '@/types/api-schema';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';
import { createFetcher } from '@/lib/api/fetchers';
import { ApiUrls } from '@/config/urls';
import ErrorNotification from '@/components/form/error-notification';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { useNotify } from '@/lib/hooks/useNofity';
import FormItemWrapper from '@/components/form/form-item-wrapper';
import { useStatementStore } from '@/store/useStatementStore';

type UpdateStatementOpenAdvanceType = components['schemas']['UpdateStatementOpenAdvanceInput'];

const validateNumber = (val: unknown) => {
  if (val === '0') {
    return true;
  }
  return Boolean(val) && Number(val);
};

const updateOpenAdvanceSchema: z.ZodType<UpdateStatementOpenAdvanceType> = z
  .object({
    openAdvance: z
      .string({ required_error: 'common.required-field' })
      .refine(validateNumber, { message: 'common.number' }),
  })
  .strict();

export type UpdateOpenAdvanceSchema = z.infer<typeof updateOpenAdvanceSchema>;

export default function OpenAdvanceModal() {
  const { t } = useTranslation(['statement', 'common', 'errors']);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { notify } = useNotify();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { statement, summary, fetchStatement } = useStatementStore();

  const {
    trigger,
    error,
    isMutating,
    reset: resetMutation,
  } = useSWRMutation(
    `${ApiUrls.STATEMENT_OPEN_ADVANCE}/${statement!.id}`,
    createFetcher<UpdateOpenAdvanceSchema>('PATCH'),
  );

  const { control, handleSubmit, reset } = useForm<UpdateOpenAdvanceSchema>({
    resolver: zodResolver(updateOpenAdvanceSchema),
    defaultValues: {
      openAdvance: String(summary?.openAdvance || 0),
    },
  });

  const onSubmit: SubmitHandler<UpdateOpenAdvanceSchema> = async data => {
    try {
      await trigger({
        body: data,
      });
      setIsModalOpen(false);
      reset();
      notify(t('open-advance-modal.success'));
      await fetchStatement(statement!.id, false);
    } catch (err) {
      // error is handled by swr hook
    }
  };

  const submitForm = () => {
    buttonRef.current?.click();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    submitForm();
  };

  const handleCancel = () => {
    reset();
    resetMutation();
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="link" size="small" onClick={showModal}>
        <EditTwoTone />
      </Button>
      <Modal
        maskClosable={false}
        title={t('open-advance-modal.title')}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: isMutating ? true : false }}
        cancelText={t('actions.cancel', { ns: 'common' })}
        okText={t('actions.submit', { ns: 'common' })}
      >
        <div className="py-4">
          {error && (
            <ErrorNotification
              text={t([`apiErrors.${error?.message}`, 'apiErrors.generic'], {
                ns: 'errors',
              })}
            />
          )}
        </div>
        <form className="pb-4" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="openAdvance"
            control={control}
            render={({ field, formState: { errors } }) => (
              <FormItemWrapper
                hasError={!!errors.openAdvance}
                errorMessage={
                  errors.openAdvance?.message && t(errors.openAdvance.message, { ns: 'errors' })
                }
                label={t('open-advance-modal.label')}
              >
                <Input prefix={'$'} {...field} />
              </FormItemWrapper>
            )}
          />
          <button type="submit" className="hidden" ref={buttonRef}></button>
        </form>
      </Modal>
    </>
  );
}
