import DashboardSection from '@/components/dashboard/section';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import { StatementStatus, StatementSummary } from '@/types';
import { useTranslation } from 'react-i18next';
import InfoTile from '@/components/common/infoTile';
import { calculateCommissions, formatCurrency, getFirstAndLastDayOfMonth } from '@/lib/utils';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useStatementStore } from '@/store/useStatementStore';
import { Popover } from 'antd';
import OpenAdvanceModal from '../open-advance-modal';
import { twMerge } from 'tailwind-merge';

type Props = {
  summary: StatementSummary;
  linkToClient: string;
  isAdmin: boolean;
  statementStatus: StatementStatus;
};

export default function StatementsSummarySection({
  summary,
  linkToClient,
  isAdmin,
  statementStatus,
}: Props) {
  const { t } = useTranslation('statement');

  const { loading, updating } = useStatementStore();

  const { firstDay, lastDay } = getFirstAndLastDayOfMonth(
    summary.statementMonth,
    summary.statementYear,
  );

  const {
    accountName,
    commission,
    handling,
    discounts,
    costOfGoodsSold,
    expensesTotal,
    openAdvance,
  } = summary;

  const { commissionRateMerch, commissionRateDigital, commissionRateMedia, DRMCommissionTotal } =
    commission;

  const commissions = calculateCommissions({
    commissionRateMerch,
    commissionRateDigital,
    commissionRateMedia,
  });

  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-[2fr_3fr]">
      <DashboardSection>
        <div className="grid lg:grid-cols-2 gap-x-6 gap-y-4">
          <InfoTile
            fieldName={t('account-name')}
            value={<Link to={linkToClient}>{accountName}</Link>}
            variant="md"
            loading={loading}
          />
          <InfoTile
            fieldName={
              <div className="flex items-center justify-center gap-1">
                {t('commission-rate')}
                <Popover content={t('transactions-popover')}>
                  <QuestionCircleOutlined />
                </Popover>
              </div>
            }
            value={commissions}
            variant="md"
            loading={loading}
          />
          <InfoTile
            fieldName={t('statement-period')}
            value={`${format(firstDay, 'MM/dd/yyyy')} - ${format(lastDay, 'MM/dd/yyyy')}`}
            variant="md"
            loading={loading}
          />
          <InfoTile
            fieldName={t('handling-fee')}
            value={formatCurrency(handling.handlingFee)}
            variant="md"
            loading={loading}
          />
        </div>
      </DashboardSection>
      <DashboardSection>
        <div
          className={twMerge(
            'grid gap-x-6 gap-y-4',
            isAdmin || summary.openAdvance ? 'lg:grid-cols-3' : 'lg:grid-cols-2',
          )}
        >
          <InfoTile
            fieldName={t('discounts')}
            value={formatCurrency(discounts.discountsTotal)}
            variant="md"
            loading={loading || updating}
          />
          <InfoTile
            fieldName={t('cogs')}
            value={formatCurrency(costOfGoodsSold.costOfGoodsSoldTotal)}
            variant="md"
            loading={loading || updating}
          />
          {isAdmin || summary.openAdvance ? (
            <InfoTile
              fieldName={t('open-advance')}
              value={
                <>
                  {formatCurrency(openAdvance)}
                  {isAdmin && statementStatus === StatementStatus.IN_PROGRESS && (
                    <OpenAdvanceModal />
                  )}
                </>
              }
              variant="md"
              loading={loading || updating}
            />
          ) : null}
          <InfoTile
            fieldName={t('commission')}
            value={formatCurrency(DRMCommissionTotal)}
            variant="md"
            loading={loading || updating}
          />
          <InfoTile
            fieldName={t('additional-expenses')}
            value={formatCurrency(expensesTotal)}
            variant="md"
            loading={loading || updating}
          />
        </div>
      </DashboardSection>
    </div>
  );
}
