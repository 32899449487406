import './styles/globals.css';
import './i18n';
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import ThemeProvider from './theme/ThemeProvider.tsx';
import { QueryParamProvider } from 'use-query-params';
import Routes from './routes.tsx';
import { SuperTokensProvider } from './components/providers/supertokens/supertokensProvider.tsx';
import { CurrentUserProvider } from './components/providers/user/current-user.provider.tsx';
import { SWRConfig } from 'swr';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorScreen from './components/common/errorScreen.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ErrorBoundary fallback={<ErrorScreen />}>
      <ThemeProvider>
        <SuperTokensProvider>
          <CurrentUserProvider>
            <BrowserRouter>
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <SWRConfig value={{ shouldRetryOnError: false }}>
                  <Routes />
                </SWRConfig>
              </QueryParamProvider>
            </BrowserRouter>
          </CurrentUserProvider>
        </SuperTokensProvider>
      </ThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);
