import ErrorComponent from '@/components/common/errorComponent';
import Loading from '@/components/common/loading';
import { format } from 'date-fns';
import DashboardHeader from '@/components/dashboard/header';
import StatementsSummarySection from '@/components/statements/statement-summary-section';
import StatementsHighLights from '@/components/statements/statements-highlights';
import StatementsNavLinks from '@/components/statements/statements-nav-links';
import { StatementStatus, StatementTabNavLink, UserRole } from '@/types';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { StatementsActions } from '@/components/statements/statements-actions';
import { ToastContainer } from 'react-toastify';
import { useCurrentUserContext } from '@/components/providers/user/use-current-user-context';
import { StatementDownload } from '@/components/statements/statment-download';
import { PageUrls } from '@/config/urls';
import { getFirstAndLastDayOfMonth } from '@/lib/utils';
import StatementInvoicesTable from '@/components/statements/statement-invoice-section';
import { useEffect } from 'react';
import { useStatementStore } from '@/store/useStatementStore';
import ResponsiveTableContainer from '@/components/common/responsiveTableContainer';
import StatementNotes from '@/components/statements/statement-notes';

export default function ClientStatement() {
  const { t } = useTranslation('statement');
  const { user } = useCurrentUserContext();
  const {
    statement,
    invoices,
    summary,
    loading,
    error,
    fetchStatement,
    merchItems,
    mediaItems,
    digitalItems,
    expenseItems,
  } = useStatementStore();

  const isAdmin = user?.role === UserRole.ADMIN;

  const params = useParams();

  const id = params.id!;

  useEffect(() => {
    fetchStatement(id, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const linkToClient =
    isAdmin && statement
      ? `${PageUrls.CLIENTS}/${statement.clientId}/summary`
      : `${PageUrls.SUMMARY}`;

  if (loading) {
    return <Loading />;
  }

  if (error || !statement || !summary) {
    return <ErrorComponent message={error || 'generic'} />;
  }

  const summaryData = summary;

  const { firstDay } = getFirstAndLastDayOfMonth(
    summaryData.statementMonth,
    summaryData.statementYear,
  );

  const formattedDate = format(firstDay, 'MMMM yyyy');

  return (
    <div>
      <ToastContainer />
      <div className="flex flex-col justify-between gap-2 md:flex-row">
        <DashboardHeader title={`${summaryData.accountName} ${formattedDate} ${t('title')}`} />
        <div className="flex flex-col gap-2 mb-6 sm:gap-0 sm:flex-row">
          <StatementDownload
            statementId={id}
            date={formattedDate}
            clientName={summaryData.accountName}
          />
          {isAdmin && <StatementsActions status={statement.status as StatementStatus} id={id} />}
        </div>
      </div>

      <Divider className="mt-0 mb-4" />
      <div className="flex flex-col gap-4">
        <StatementsHighLights summary={summaryData} />
        <StatementsSummarySection
          summary={summaryData}
          linkToClient={linkToClient}
          isAdmin={isAdmin}
          statementStatus={statement.status as StatementStatus}
        />
        {invoices.hasQuickbooks && <StatementInvoicesTable isAdmin={isAdmin} />}
      </div>
      {isAdmin && (
        <div className="mt-8">
          <ResponsiveTableContainer>
            <StatementNotes statement={statement} />
          </ResponsiveTableContainer>
        </div>
      )}
      <div className="mt-8">
        <ResponsiveTableContainer>
          <StatementsNavLinks
            statementId={id}
            n={{
              [StatementTabNavLink.SOFT_GOODS]: isAdmin
                ? merchItems.visible.items.length + merchItems.hidden.items.length
                : merchItems.visible.items.length,

              [StatementTabNavLink.MEDIA]: isAdmin
                ? mediaItems.visible.items.length + mediaItems.hidden.items.length
                : mediaItems.visible.items.length,
              [StatementTabNavLink.DIGITAL]: isAdmin
                ? digitalItems.visible.items.length + digitalItems.hidden.items.length
                : digitalItems.visible.items.length,
              [StatementTabNavLink.EXPENSES]: expenseItems.items.length,
            }}
          />
        </ResponsiveTableContainer>
        <Outlet />
      </div>
    </div>
  );
}
