import type { ColumnsType } from 'antd/es/table';
import { ClientTableDataType, SortOrder } from '@/types';
import ClientName from './table/client-name-cell';
import DRMCell from './table/drm-cell';
import { useTranslation } from 'react-i18next';
import ClientStatusTag from './client-status-tag';
import ClientRatesCell from './client-commissions-cell';
import { formatCurrency } from '@/lib/utils';
import ClientPaymentProcessingCell from './client-payment-procesing-cell';
import { tableWidths } from '@/styles/constants';

const columns: ColumnsType<ClientTableDataType> = [
  {
    title: 'name',
    key: 'name',
    dataIndex: 'name',
    render: (_, { id, name }) => <ClientName id={id} name={name} />,
    sorter: true,
    width: tableWidths.clientName,
  },
  {
    title: 'status',
    key: 'status',
    dataIndex: 'status',
    render: status => <ClientStatusTag status={status} />,
    width: tableWidths.status,
    filters: [
      {
        text: 'active',
        value: 'active',
      },
      {
        text: 'inactive',
        value: 'inactive',
      },
    ],
  },
  {
    title: 'drm-client',
    key: 'isDRMMallClient',
    dataIndex: 'isDRMMallClient',
    render: isDRMMallClient => <DRMCell isDRMMallClient={isDRMMallClient} />,
    width: tableWidths.status,
    filters: [
      {
        text: 'yes',
        value: 'true',
      },
      {
        text: 'no',
        value: 'false',
      },
    ],
  },
  {
    title: 'commission-rates',
    key: 'commissionRates',
    render: (_, { royaltyRate, royaltyRateMedia, royaltyRateDigital }) => (
      <ClientRatesCell
        royaltyRate={royaltyRate}
        royaltyRateMedia={royaltyRateMedia}
        royaltyRateDigital={royaltyRateDigital}
      />
    ),
    width: tableWidths.commissions,
  },
  {
    title: 'payment-processing',
    key: 'paymentProcessing',
    render: (_, { paymentProcessing, paymentProcessingFlat }) => (
      <ClientPaymentProcessingCell
        paymentProcessing={paymentProcessing}
        paymentProcessingFlat={paymentProcessingFlat}
      />
    ),
    width: tableWidths.paymentProcessing,
  },
  {
    title: 'handling-fee',
    key: 'handlingFee',
    dataIndex: 'handlingFee',
    render: handlingFee => <>{formatCurrency(handlingFee)}</>,
    width: tableWidths.handling,
  },
  {
    title: 'tour-manager',
    key: 'tourAccountManager',
    dataIndex: 'tourAccountManager',
    width: tableWidths.managerName,
  },
  {
    title: 'e-com-manager',
    key: 'eComAccountManager',
    dataIndex: 'eComAccountManager',
    width: tableWidths.managerName,
  },
  {
    title: 'payable-manager',
    key: 'accountsPayableManager',
    dataIndex: 'accountsPayableManager',
    width: tableWidths.managerName,
  },
  {
    title: 'quickBooks-id',
    key: 'quickBooksId',
    dataIndex: 'quickBooksId',
    width: tableWidths.quickbooks,
  },
  {
    title: 'note',
    key: 'note',
    dataIndex: 'note',
    width: tableWidths.note,
  },
];

export const useClientsColumns = (
  sort?: string | null,
  order?: string | null,
  isDRMMallClient?: (string | null)[] | null,
  status?: (string | null)[] | null,
): ColumnsType<ClientTableDataType> => {
  const { t } = useTranslation('clients');

  const defaultDRMMallClient = isDRMMallClient
    ? (isDRMMallClient.filter(item => item) as string[])
    : [];

  const defaultStatus = status ? (status.filter(item => item) as string[]) : [];

  return columns.map(column => {
    if (column.key === sort) {
      return {
        ...column,
        title: t(`table.${column.title}`),
        defaultSortOrder: order === SortOrder.ASC ? ('ascend' as const) : ('descend' as const),
      };
    }
    if (column.key === 'isDRMMallClient') {
      return {
        ...column,
        // All clients are by default drm clients, as there is no support for custom clients
        hidden: true,
        title: t(`table.${column.title}`),
        defaultFilteredValue: defaultDRMMallClient,
        filters: column.filters?.map(item => ({ ...item, text: t(`table.filters.${item.text}`) })),
      };
    }
    if (column.key === 'status') {
      return {
        ...column,
        title: t(`${column.title}`),
        defaultFilteredValue: defaultStatus,
        filters: column.filters?.map(item => ({ ...item, text: t(`table.filters.${item.text}`) })),
      };
    }
    return {
      ...column,
      title: t(`table.${column.title}`),
    };
  });
};
