import { useTranslation } from 'react-i18next';

type Props = {
  link: string;
};

export default function ViewLinkCell({ link }: Props) {
  const { t } = useTranslation('common');

  return (
    <a href={link} target="_blank">
      {t('actions.view')}
    </a>
  );
}
