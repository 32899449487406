export const dashboardContainerPadding = {
  mobile: 12,
  desktop: 24,
};

export const dashboardSectionPadding = {
  mobile: 12,
  desktop: 24,
};

export const sideMenuWidth = {
  mobile: 64,
  desktop: 224,
};

export const tableWidths = {
  faqAnswer: 400,
  clientName: 240,
  expenseDescription: 220,
  productName: 200,
  profileAndEmail: 200,
  expenseNote: 220,
  sku: 200,
  statementDate: 145,
  date: 110,
  orderNumber: 100,
  commissions: 150,
  paymentProcessing: 250,
  managerName: 200,
  invoiceNumber: 100,
  productType: 100,
  title: 120,
  vendor: 120,
  status: 85,
  actions: 85,
  role: 85,
  price: 100,
  balance: 85,
  quantity: 85,
  territory: 50,
  quickbooks: 150,
  note: 400,
  handling: 150,
  checkbox: 50,
  royalties: 120,
  cogs: 100,
  dateApproved: 130,
  invoiceTableBalance: 100,
  royaltyAmountApplied: 130,
  payoutMethod: 120,
};

export const screenWidths = {
  desktop: 1024,
};

export const tableScroll = 850;
