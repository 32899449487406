import { useCurrentUserContext } from '@/components/providers/user/use-current-user-context';
import TransactionsTableSection from '@/components/statements/transactions-table-section';
import { UserRole } from '@/types';
import { useTranslation } from 'react-i18next';

export default function ClientStatementSoftGoods() {
  const { t } = useTranslation('statement');
  const { user } = useCurrentUserContext();

  const isAdmin = user?.role === UserRole.ADMIN;

  return (
    <TransactionsTableSection
      title={t('nav.soft-goods')}
      transactionType={'merchItems'}
      isAdmin={isAdmin}
    />
  );
}
