import { CustomError } from '../customError';

export const defaultHeaders = {
  'Content-type': 'application/json',
};

type SupportedHTTPMethods = 'POST' | 'PATCH' | 'DELETE' | 'PUT';

type FetcherArgs<T> = {
  body?: T;
};

export const parseResponseOrThrowErr = async (res: Response) => {
  if (res.ok === true) {
    return res.json();
  }
  const info = await res.json();
  const error = new CustomError(info.message, res.status);

  throw error;
};

export const createFetcher =
  <T>(method: SupportedHTTPMethods) =>
  async (url: string, { arg }: { arg: FetcherArgs<T> }) => {
    const body = JSON.stringify(arg.body);

    const res = await fetch(url, {
      method: method,
      headers: defaultHeaders,
      body,
    });

    return parseResponseOrThrowErr(res);
  };
