import { useRef, useState } from 'react';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { Button, Modal } from 'antd';
import { components } from '@/types/api-schema';
import { SubmitHandler } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';
import { createFetcher } from '@/lib/api/fetchers';
import { ApiUrls } from '@/config/urls';
import ErrorNotification from '@/components/form/error-notification';
import { useTranslation } from 'react-i18next';
import AddEditExpenseForm, {
  AddEditFormHandle,
  CreateExpenseFormSchema,
} from '../add-edit-expense-form';
import { useStatementStore } from '@/store/useStatementStore';
import { StatementStatus } from '@/types';

type CreateExpenseInputType = components['schemas']['CreateExpenseRequestBody'];

type CreateExpenseResponse = components['schemas']['Expense'];

type Props = {
  statementId: string | undefined;
};

export default function AddExpenseModal({ statementId }: Props) {
  const { t } = useTranslation(['statement', 'common', 'errors']);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const childRef = useRef<AddEditFormHandle>(null);

  const { addExpense, statement } = useStatementStore();

  const { trigger, error, isMutating } = useSWRMutation(
    ApiUrls.EXPENSE,
    createFetcher<CreateExpenseInputType>('POST'),
  );

  const onSubmit: SubmitHandler<CreateExpenseFormSchema> = async data => {
    if (statementId) {
      try {
        const expense: CreateExpenseResponse = await trigger({
          body: {
            statementId,
            ...data,
          },
        });
        addExpense(expense);
        resetForm();
        setIsModalOpen(false);
      } catch (err) {
        // error is handled by swr hook
      }
    }
  };

  const resetForm = () => childRef.current?.resetForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    childRef.current?.submitForm();
  };

  const handleCancel = () => {
    childRef.current?.resetForm();
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        type="default"
        onClick={showModal}
        icon={<PlusOutlined />}
        disabled={statement?.status !== StatementStatus.IN_PROGRESS}
      >
        {t('expenses.add')}
      </Button>
      <Modal
        maskClosable={false}
        title={t('expenses.add')}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: isMutating ? true : false }}
        cancelText={t('actions.cancel', { ns: 'common' })}
        okText={t('actions.submit', { ns: 'common' })}
      >
        <div className="py-4">
          {error && (
            <ErrorNotification
              text={t([`apiErrors.${error?.message}`, 'apiErrors.generic'], {
                ns: 'errors',
              })}
            />
          )}
        </div>
        <AddEditExpenseForm ref={childRef} onSubmit={onSubmit} defaultValues={{}} />
      </Modal>
    </>
  );
}
