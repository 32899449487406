import type { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  token: {
    colorTextDescription: '#000000a6',
    colorTextTertiary: '#00000073',
    colorBgContainerDisabled: 'rgba(0, 0, 0, 0.01)',
    colorTextDisabled: '#000000a6',
    colorBorderSecondary: '#E6E6E6',
    colorSplit: '#E6E6E6',
  },
  components: {
    Tabs: {
      cardBg: 'transparent',
    },
    Collapse: {
      headerBg: '#fafafa',
    },
  },
};

export default theme;
