import { PageUrls } from '@/config/urls';
import {
  DashboardOutlined,
  TeamOutlined,
  FileTextOutlined,
  DollarCircleOutlined,
  SettingOutlined,
  DeliveredProcedureOutlined,
  IssuesCloseOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

const links = [
  {
    href: PageUrls.SUMMARY,
    labelKey: 'side-menu.navigation.summary',
    icon: <DashboardOutlined />,
  },
  {
    href: PageUrls.CLIENTS,
    labelKey: 'side-menu.navigation.clients',
    icon: <TeamOutlined />,
  },
  {
    href: PageUrls.SHIPMENTS,
    labelKey: 'side-menu.navigation.pending-shipments',
    icon: <DeliveredProcedureOutlined />,
  },
  {
    href: PageUrls.UNSORTED_ORDERS,
    labelKey: 'side-menu.navigation.unsorted-orders',
    icon: <IssuesCloseOutlined />,
  },
  {
    href: PageUrls.STATEMENTS,
    labelKey: 'side-menu.navigation.statements',
    icon: <FileTextOutlined />,
  },
  {
    href: PageUrls.INVOICES,
    labelKey: 'side-menu.navigation.invoices',
    icon: <DollarCircleOutlined />,
  },
  {
    href: PageUrls.FAQ,
    labelKey: 'side-menu.navigation.faq',
    icon: <QuestionCircleOutlined />,
  },
  {
    href: PageUrls.PROFILE,
    labelKey: 'side-menu.navigation.settings',
    icon: <SettingOutlined />,
  },
];

export function getLinks(isAdmin = false, hasQuickBooks = true) {
  return isAdmin
    ? links.filter(link => link.href !== PageUrls.INVOICES)
    : links
        .filter(link => {
          return !(link.href === PageUrls.CLIENTS || link.href === PageUrls.UNSORTED_ORDERS);
        })
        .filter(link => {
          return !(link.href === PageUrls.INVOICES && !hasQuickBooks);
        });
}
