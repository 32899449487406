import { useBodyScrollable } from '@/lib/hooks/useBodyScrollable';
import { useDashboardContainerWidth } from '@/lib/hooks/useDashboardContainerWidth';
import { useMediaQuery } from '@/lib/hooks/useMediaQuery';
import { dashboardContainerPadding, dashboardSectionPadding } from '@/styles/constants';
import { PropsWithChildren } from 'react';
import { useScrollbarWidth } from 'react-use';

type Props = {
  inDashboardSection?: boolean;
};

export default function ResponsiveTableContainer({
  children,
  inDashboardSection = false,
}: PropsWithChildren<Props>) {
  const dashboardContainerWidth = useDashboardContainerWidth();
  const scrollBarWidth = useScrollbarWidth();
  const isBodyScrollable = useBodyScrollable();
  const isDesktop = useMediaQuery();

  const scrollWidthApplied = (isBodyScrollable && scrollBarWidth) || 0;

  // padding plus border
  const dashboardSectionPaddingApplied = inDashboardSection
    ? isDesktop
      ? 2 * dashboardContainerPadding.desktop + 2
      : 2 * dashboardContainerPadding.mobile + 2
    : 0;

  const dashboardContainerPaddingApplied = isDesktop
    ? 2 * dashboardSectionPadding.desktop
    : 2 * dashboardSectionPadding.mobile;

  const padding = dashboardContainerPaddingApplied + dashboardSectionPaddingApplied;

  const innerSpaceWidth = dashboardContainerWidth - padding - scrollWidthApplied;

  return <div style={{ width: innerSpaceWidth }}>{children}</div>;
}
