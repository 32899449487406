import { useEffect, useState } from 'react';
import { getLinks } from './components/links';
import NavLink from './components/navLink';
import { Button } from 'antd';
import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined';
import DoubleLeftOutlined from '@ant-design/icons/DoubleLeftOutlined';
import { twMerge } from 'tailwind-merge';
import UserInfo from './components/userInfo';
import { UserRole } from '@/types';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import { useSideMenuStore } from '@/store/useSideMenuStore';
import { screenWidths } from '@/styles/constants';
import { useCurrentUserContext } from '@/components/providers/user/use-current-user-context';

export default function SideMenu() {
  const { t } = useTranslation('common');
  const { width } = useWindowSize();
  const { user } = useCurrentUserContext();
  const { isSideMenuOpen, closeSideMenu, toggleSideMenu } = useSideMenuStore();
  const [isTabletOrMobile, setIsTabletOrMobile] = useState(width < screenWidths.desktop);

  useEffect(() => {
    if (width < screenWidths.desktop) {
      closeSideMenu();
      setIsTabletOrMobile(true);
    } else {
      setIsTabletOrMobile(false);
    }
  }, [width, closeSideMenu]);

  const links = getLinks(user!.role === UserRole.ADMIN, Boolean(user?.client?.quickBooksId));

  const toggleMenu = () => {
    toggleSideMenu();
  };

  return (
    <>
      <div
        className={twMerge(
          'transition-all duration-150 overflow-hidden',
          isSideMenuOpen ? `w-[14rem]` : 'w-[4rem]',
        )}
      />
      <div
        className={twMerge(
          'fixed h-screen flex flex-col justify-between p-1 w-[13rem] border-solid border-0 border-r border-border-gray transition-all duration-150 overflow-hidden',
          isSideMenuOpen ? 'w-[14rem]' : 'w-[4rem]',
        )}
      >
        <div>
          <div
            className={twMerge(
              " h-20 bg-[url('../assets/logo-side-nav.png')] bg-center bg-no-repeat bg-contain",
              isSideMenuOpen ? 'm-4' : 'h-8 my-2 mx-0',
            )}
          ></div>
          <nav className="flex flex-col gap-2 py-1">
            {links.map(link => (
              <NavLink key={link.labelKey} isCollapsed={!isSideMenuOpen} {...link} />
            ))}
          </nav>
        </div>
        <div>
          <UserInfo isCollapsed={!isSideMenuOpen} />
          {!isTabletOrMobile && (
            <>
              <div className="mx-1 my-2 h-[1px] bg-border-gray"></div>
              <div className={twMerge('py-1', isSideMenuOpen ? 'px-2' : 'px-3')}>
                <Button
                  onClick={toggleMenu}
                  className="!text-[#1d4ed8]"
                  icon={isSideMenuOpen ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
                  type="text"
                >
                  {isSideMenuOpen ? t('side-menu.collapse') : ''}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
