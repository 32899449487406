import Session from 'supertokens-auth-react/recipe/session';
import SuperTokens from 'supertokens-auth-react';
import { Button } from 'antd';

export default function LogoutButton() {
  async function signOut() {
    await Session.signOut();
    SuperTokens.redirectToAuth();
  }

  return <Button onClick={signOut}>Logout</Button>;
}
