import { StatementResponse, getStatement } from '@/lib/hooks/useStatement';
import { ExpenseData, TabsState } from '@/types';
import { create } from 'zustand';
import { produce } from 'immer';
import { InvoiceItems, StatementStore, TransactionItems } from './types';
import { ApiUrls } from '@/config/urls';

const getStateDataFromResponse = (state: StatementStore, data: StatementResponse) => {
  return {
    ...state,
    ...state,
    loading: false,
    updating: false,
    error: undefined,
    statement: data.statement,
    summary: data.statementData.summary,
    expenseItems: {
      ...state.expenseItems,
      items: data.statementData.expenseItems,
    },
    digitalItems: {
      ...state.digitalItems,
      visible: {
        ...state.digitalItems.visible,
        items: data.statementData.digitalItems.filter(item => item.isHiddenFromStatement !== true),
      },
      hidden: {
        ...state.digitalItems.hidden,
        items: data.statementData.digitalItems.filter(item => item.isHiddenFromStatement === true),
      },
    },
    mediaItems: {
      ...state.mediaItems,
      visible: {
        ...state.mediaItems.visible,
        items: data.statementData.mediaItems.filter(item => item.isHiddenFromStatement !== true),
      },
      hidden: {
        ...state.mediaItems.hidden,
        items: data.statementData.mediaItems.filter(item => item.isHiddenFromStatement === true),
      },
    },
    merchItems: {
      ...state.merchItems,
      visible: {
        ...state.merchItems.visible,
        items: data.statementData.merchItems.filter(item => item.isHiddenFromStatement !== true),
      },
      hidden: {
        ...state.merchItems.hidden,
        items: data.statementData.merchItems.filter(item => item.isHiddenFromStatement === true),
      },
    },
    invoices: {
      ...state.invoices,
      hasQuickbooks: Boolean(data.statementData.invoices),
      visible: {
        ...state.invoices.visible,
        items: data.statementData.invoices
          ? data.statementData.invoices.filter(invoice => invoice.isHiddenFromStatement !== true)
          : [],
      },
      hidden: {
        ...state.invoices.hidden,
        items: data.statementData.invoices
          ? data.statementData.invoices.filter(invoice => invoice.isHiddenFromStatement === true)
          : [],
      },
    },
  };
};

const initialTransactions = {
  activeTab: TabsState.VISIBLE,
  searchTerm: '',
  visible: {
    page: 1,
    limit: 10,
    items: [] as TransactionItems,
  },
  hidden: { page: 1, limit: 10, items: [] as TransactionItems },
};

export const useStatementStore = create<StatementStore>(set => ({
  loading: true,
  updating: false,
  error: undefined,
  digitalItems: initialTransactions,
  mediaItems: initialTransactions,
  merchItems: initialTransactions,
  expenseItems: {
    searchTerm: '',
    page: 1,
    limit: 2,
    items: [],
  },
  statement: undefined,
  summary: undefined,
  invoices: {
    hasQuickbooks: false,
    activeTab: TabsState.VISIBLE,
    visible: {
      page: 1,
      limit: 10,
      items: [],
    },
    hidden: {
      page: 1,
      limit: 10,
      items: [],
    },
  },
  updateStatement: (data: StatementResponse) =>
    set(state => {
      const newState = getStateDataFromResponse(state, data);
      return { ...newState };
    }),
  changeLineItemStatus: (type, items) => {
    set(state => {
      const changedItems = [...state[type].visible.items, ...state[type].hidden.items].reduce(
        (acc, cur) => {
          const hasChangedStatus = items.find(
            itemWithChangedStatus =>
              cur.id === itemWithChangedStatus.lineItemId &&
              cur.fulfillmentId === itemWithChangedStatus.fulfillmentId,
          );
          const isHiddenFromStatement = hasChangedStatus
            ? hasChangedStatus.isHiddenFromStatement
            : cur.isHiddenFromStatement;

          if (isHiddenFromStatement) {
            return {
              visible: [...acc.visible],
              hidden: [...acc.hidden, { ...cur, isHiddenFromStatement }],
            };
          } else {
            return {
              visible: [...acc.visible, { ...cur, isHiddenFromStatement }],
              hidden: [...acc.hidden],
            };
          }
        },
        { visible: [], hidden: [] } as { visible: TransactionItems; hidden: TransactionItems },
      );

      return {
        ...state,
        [type]: {
          ...state[type],
          visible: { ...state[type].visible, items: changedItems.visible },
          hidden: { ...state[type].hidden, items: changedItems.hidden },
        },
      };
    });
  },
  changeInvoiceStatus: items => {
    set(state => {
      const changedItems = [...state.invoices.visible.items, ...state.invoices.hidden.items].reduce(
        (acc, cur) => {
          const hasChangedStatus = items.find(
            invoiceWithChangedStatus => cur.id === invoiceWithChangedStatus.id,
          );

          const isHiddenFromStatement = hasChangedStatus
            ? hasChangedStatus.isHiddenFromStatement
            : cur.isHiddenFromStatement;

          if (isHiddenFromStatement) {
            return {
              visible: [...acc.visible],
              hidden: [...acc.hidden, { ...cur, isHiddenFromStatement }],
            };
          } else {
            return {
              visible: [...acc.visible, { ...cur, isHiddenFromStatement }],
              hidden: [...acc.hidden],
            };
          }
        },
        { visible: [], hidden: [] } as { visible: InvoiceItems; hidden: InvoiceItems },
      );

      return {
        ...state,
        invoices: {
          ...state.invoices,
          visible: { ...state.invoices.visible, items: changedItems.visible },
          hidden: { ...state.invoices.hidden, items: changedItems.hidden },
        },
      };
    });
  },
  setActiveTab: (val, type) =>
    set(
      produce(state => {
        state[type].activeTab = val;
      }),
    ),
  setPage: (page, limit, type, activeTab) =>
    set(state => {
      return {
        ...state,
        [type]: {
          ...state[type],
          [activeTab]: {
            page,
            limit,
            items: state[type][activeTab].items,
          },
        },
      };
    }),
  setSort: (type, activeTab, sort, order) =>
    set(state => {
      if (sort && order) {
        return {
          ...state,
          [type]: {
            ...state[type],
            [activeTab]: { ...state[type][activeTab], sort, order },
          },
        };
      } else {
        return {
          ...state,
          [type]: {
            ...state[type],
            [activeTab]: {
              page: state[type][activeTab].page,
              limit: state[type][activeTab].limit,
              items: state[type][activeTab].items,
            },
          },
        };
      }
    }),
  setSearch: (type, searchTerm) =>
    set(state => {
      return {
        ...state,
        [type]: {
          ...state[type],
          searchTerm,
        },
      };
    }),
  setActiveInvoices: invoices =>
    set(state => {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          visible: {
            ...state.invoices.visible,
            items: invoices,
          },
        },
      };
    }),
  addExpense: (expense: ExpenseData) =>
    set(state => {
      return {
        ...state,
        summary: {
          ...state.summary!,
          expensesTotal: (state.summary?.expensesTotal || 0) + Number(expense.total),
        },
        expenseItems: { ...state.expenseItems, items: [expense, ...state.expenseItems.items] },
      };
    }),
  editExpense: (updatedExpense: ExpenseData) =>
    set(state => {
      const existingExpense = state.expenseItems.items.find(
        existingExpense => existingExpense.id === updatedExpense.id,
      );

      const updatedExpenses = state.expenseItems.items.map(expense =>
        expense.id === updatedExpense.id ? updatedExpense : expense,
      );

      return {
        ...state,
        summary: {
          ...state.summary!,
          expensesTotal:
            (state.summary?.expensesTotal || 0) -
            Number(existingExpense?.total || 0) +
            Number(updatedExpense.total),
        },
        expenseItems: { ...state.expenseItems, items: updatedExpenses },
      };
    }),
  removeExpense: (expenseId: string) =>
    set(state => {
      const expenseToRemove = state.expenseItems.items.find(expense => expenseId === expense.id);

      return {
        ...state,
        summary: {
          ...state.summary!,
          expensesTotal: (state.summary?.expensesTotal || 0) + Number(expenseToRemove?.total || 0),
        },
        expenseItems: {
          ...state.expenseItems,
          items: [...state.expenseItems.items.filter(expense => expense.id !== expenseId)],
        },
      };
    }),
  setExpensesPage: (page: number, limit: number) =>
    set(state => {
      return {
        ...state,
        expenseItems: {
          ...state.expenseItems,
          page: page,
          limit: limit,
        },
      };
    }),
  setExpensesSort: (sort, order) =>
    set(state => {
      if (sort && order) {
        return {
          ...state,
          expenseItems: {
            ...state.expenseItems,
            sort: sort,
            order: order,
          },
        };
      } else {
        return {
          ...state,
        };
      }
    }),
  setExpensesSearch: searchTerm => {
    set(state => {
      return {
        ...state,
        expenseItems: {
          ...state.expenseItems,
          searchTerm,
        },
      };
    });
  },
  setLoading: loading => {
    set(state => ({ ...state, loading }));
  },
  fetchStatement: async (id: string, initialFetch = true) => {
    try {
      if (initialFetch) {
        set(state => ({ ...state, loading: true, error: undefined }));
      } else {
        set(state => ({ ...state, updating: true, error: undefined }));
      }
      const data = await getStatement({ url: `${ApiUrls.STATEMENT}/${id}` });

      set(state => {
        const newState = getStateDataFromResponse(state, data);
        return { ...newState };
      });
    } catch (err: unknown) {
      set(state => ({
        ...state,
        loading: false,
        error: err instanceof Error ? err.message : 'generic',
      }));
    }
  },
}));
