import { useTranslation } from 'react-i18next';
import { useNotify } from '@/lib/hooks/useNofity';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { components } from '@/types/api-schema';
import { zodResolver } from '@hookform/resolvers/zod';
import useSWRMutation from 'swr/mutation';
import { ApiUrls } from '@/config/urls';
import { createFetcher } from '@/lib/api/fetchers';
import { useState } from 'react';
import EditableFormWrapper from '@/components/form/editable-form-wrapper';
import FormItemWrapper from '@/components/form/form-item-wrapper';
import TextArea from 'antd/es/input/TextArea';
import { StatementResponse } from '@/lib/hooks/useStatement';

type EditStatementInputType = Pick<
  components['schemas']['UpdateStatementNotesInput'],
  'note1' | 'note2' | 'note3' | 'note4' | 'note5'
>;

const editStatementFormSchema: z.ZodType<EditStatementInputType> = z.object({
  note1: z.string().optional(),
  note2: z.string().optional(),
  note3: z.string().optional(),
  note4: z.string().optional(),
  note5: z.string().optional(),
});

type EditStatementFormSchema = z.infer<typeof editStatementFormSchema>;

type Props = {
  statement: StatementResponse['statement'];
};

export default function StatementNotes({ statement }: Props) {
  const { notify } = useNotify();
  const { t } = useTranslation('statement');

  const { control, handleSubmit, reset } = useForm<EditStatementFormSchema>({
    resolver: zodResolver(editStatementFormSchema),
    defaultValues: {
      note1: statement?.note1 || undefined,
      note2: statement?.note2 || undefined,
      note3: statement?.note3 || undefined,
      note4: statement?.note4 || undefined,
      note5: statement?.note5 || undefined,
    },
  });

  const {
    trigger,
    error,
    isMutating,
    reset: resetMutation,
  } = useSWRMutation(
    `${ApiUrls.STATEMENT_NOTES}/${statement.id}`,
    createFetcher<EditStatementInputType>('PATCH'),
  );
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleCancel = () => {
    reset({
      note1: statement?.note1 || undefined,
      note2: statement?.note2 || undefined,
      note3: statement?.note3 || undefined,
      note4: statement?.note4 || undefined,
      note5: statement?.note5 || undefined,
    });
    setIsEditing(false);
  };

  const onSubmit: SubmitHandler<EditStatementFormSchema> = async data => {
    try {
      resetMutation();
      await trigger({
        body: {
          ...data,
        },
      });
      setIsEditing(false);
      notify(t('notes.edit-success'));
    } catch (err) {
      // error is handled by swr hook
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditableFormWrapper
          title={t('notes.title')}
          isEditing={isEditing}
          handleCancel={handleCancel}
          handleEdit={handleEdit}
          loading={isMutating}
          error={
            error?.message &&
            t([`apiErrors.${error?.message}`, 'apiErrors.generic'], {
              ns: 'errors',
            })
          }
        >
          <div>
            <Controller
              name="note1"
              control={control}
              render={({ field, formState: { errors } }) => (
                <FormItemWrapper
                  hasError={!!errors.note1}
                  errorMessage={errors.note1?.message}
                  label={t('notes.note1')}
                >
                  <TextArea rows={5} {...field} disabled={!isEditing} />
                </FormItemWrapper>
              )}
            />
          </div>
          <div>
            <Controller
              name="note2"
              control={control}
              render={({ field, formState: { errors } }) => (
                <FormItemWrapper
                  hasError={!!errors.note2}
                  errorMessage={errors.note2?.message}
                  label={t('notes.note2')}
                >
                  <TextArea rows={5} {...field} disabled={!isEditing} />
                </FormItemWrapper>
              )}
            />
          </div>
          <div>
            <Controller
              name="note3"
              control={control}
              render={({ field, formState: { errors } }) => (
                <FormItemWrapper
                  hasError={!!errors.note3}
                  errorMessage={errors.note3?.message}
                  label={t('notes.note3')}
                >
                  <TextArea rows={5} {...field} disabled={!isEditing} />
                </FormItemWrapper>
              )}
            />
          </div>
          <div>
            <Controller
              name="note4"
              control={control}
              render={({ field, formState: { errors } }) => (
                <FormItemWrapper
                  hasError={!!errors.note4}
                  errorMessage={errors.note4?.message}
                  label={t('notes.note4')}
                >
                  <TextArea rows={5} {...field} disabled={!isEditing} />
                </FormItemWrapper>
              )}
            />
          </div>
          <div>
            <Controller
              name="note5"
              control={control}
              render={({ field, formState: { errors } }) => (
                <FormItemWrapper
                  hasError={!!errors.note5}
                  errorMessage={errors.note5?.message}
                  label={t('notes.note5')}
                >
                  <TextArea rows={5} {...field} disabled={!isEditing} />
                </FormItemWrapper>
              )}
            />
          </div>
        </EditableFormWrapper>
      </form>
    </>
  );
}
