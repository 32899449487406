import { ApiUrls } from '@/config/urls';
import useSWR, { Fetcher } from 'swr';
import { components } from '@/types/api-schema';
import { defaultHeaders, parseResponseOrThrowErr } from '../api/fetchers';

type ClientResponse = components['schemas']['GetClientResponse'];

const fetcher: Fetcher<ClientResponse, string> = async (url: string) => {
  const res = await fetch(url, { headers: defaultHeaders });

  return parseResponseOrThrowErr(res);
};

export const useClient = (id: string | undefined) => {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    id ? `${ApiUrls.CLIENT}/${id}` : null,
    fetcher,
  );

  return {
    client: data,
    mutate,
    isLoading,
    isValidating,
    isError: error,
  };
};
