import { components } from './api-schema';

export type User = components['schemas']['GetPaginatedUsersResponse']['users'][0];

export type UserWithClient = components['schemas']['UserResponseWithClient'];

export type Client = components['schemas']['GetPaginatedClientsResponse']['clients'][0];

export type ClientTableDataType = Omit<
  Client,
  'tourAccountManager' | 'eComAccountManager' | 'accountsPayableManager'
> & {
  key: string;
  tourAccountManager: string | undefined;
  eComAccountManager: string | undefined;
  accountsPayableManager: string | undefined;
};

export type UserTableDataType = User & {
  key: string;
  status: UserStatus | undefined;
  queryToRevalidate: string;
  queryRole?: string;
  queryClientId?: string;
};

export type PendingShipment = components['schemas']['OrdersResponse']['orders'][0];

export type OrderTransactionData =
  components['schemas']['GetStatementResponse']['statementData']['merchItems'][0];

export type ExpenseData =
  components['schemas']['GetStatementResponse']['statementData']['expenseItems'][0];

export type StatementSummary =
  components['schemas']['GetStatementResponse']['statementData']['summary'];

export type Statement = components['schemas']['GetPaginatedStatementsResponse']['statements'][0];

export type Invoice = components['schemas']['PaginatedClientInvoicesResponse']['invoices'][0];

export type FAQ = components['schemas']['GetPaginatedFAQsResponse']['faqs'][0];

export type StatementExpense = {
  id: number;
  statementId: string;
  date: string | undefined | null;
  orderNumber: string | undefined | null;
  description: string | undefined | null;
  total: string;
  note: string | undefined | null;
};

export type InvoiceTableDataType = Invoice & {
  key: string;
  clientName: string;
  clientId: string;
};

export enum ClientStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum UserStatus {
  ACTIVE = 'Active',
  PENDING = 'Pending',
}

export enum StatementStatus {
  APPROVED = 'APPROVED',
  REVIEWED = 'REVIEWED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum UserRole {
  ADMIN = 'admin',
  USER = 'user',
  CONTACT = 'contact',
  INACTIVE = 'inactive',
}

export enum InvoicePaymentStatus {
  FULLY_PAID = 'fully-paid',
  NOT_PAID = 'not-paid',
  OVERDUE = 'overdue',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum AntSortOrder {
  ASC = 'ascend',
  DESC = 'descend',
}

export enum DRMClientFilterValues {
  TRUE = 'true',
  FALSE = 'false',
}

export enum ClientTableAllowedSort {
  NAME = 'name',
}

export enum TabsState {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
}

export enum ProductStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export enum OrderStatus {
  FULFILLED = 'fulfilled',
  REFUNDED = 'refunded',
  PARTLY_REFUNDED = 'partly_refunded',
  UNFULFILLED = 'unfulfilled',
}

export enum InventoryStatus {
  CLIENT_OWNED = 'client-owned',
  LICENSED = 'licensed',
}

export enum InventoryStructure {
  CLIENT_OWNED = 'client_owned',
  LICENSING = 'licensing',
  PRINT_ON_DEMAND = 'print_on_demand',
}

export enum PayoutMethod {
  ACH = 'ach',
  PAYPAL = 'paypal',
  CHECK = 'check',
}

export enum StatementTabNavLink {
  SOFT_GOODS = 'soft-goods',
  MEDIA = 'media',
  DIGITAL = 'digital',
  EXPENSES = 'expenses',
}
