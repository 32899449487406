import { formatCurrency } from '@/lib/utils';
import { useStatementStore } from '@/store/useStatementStore';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;

export default function PopoverContent() {
  const { t } = useTranslation('statement');
  const { summary } = useStatementStore();

  const invoiceBalanceAfterAppliedRoyalties = summary?.invoiceBalanceAfterAppliedRoyalties;
  const invoiceBalanceTotal = summary?.invoiceBalanceTotal;

  return (
    <div className="flex flex-col gap-1">
      {invoiceBalanceTotal != null ? (
        <div className="flex gap-1">
          <Text>{t('balance-before-royalties')}:</Text>
          <Text className="font-semibold">{formatCurrency(invoiceBalanceTotal)}</Text>
        </div>
      ) : null}
      {invoiceBalanceAfterAppliedRoyalties != null ? (
        <div className="flex gap-1">
          <Text>{t('balance-after-royalties')}:</Text>
          <Text className="font-semibold">
            {formatCurrency(invoiceBalanceAfterAppliedRoyalties)}
          </Text>
        </div>
      ) : null}
    </div>
  );
}
