import { StringParam, useQueryParam } from 'use-query-params';
import { formatAntTableOrder } from '../utils';

const filterSortOptions = <T extends string>(
  value: string | null | undefined,
  allowedValues: T[],
) => {
  if (value && allowedValues.includes(value as T)) {
    return value;
  }
};

export const useTableSort = <T extends string>(sortValues: T[]) => {
  const [sortParam, setSortParam] = useQueryParam('sort', StringParam);
  const [orderParam, setOrderParam] = useQueryParam('order', StringParam);

  const sort = filterSortOptions<T>(sortParam, sortValues) as T;
  const order = orderParam || undefined;

  const orderForApi = formatAntTableOrder(order);

  return { sort, setSort: setSortParam, order, orderForApi, setOrder: setOrderParam };
};
