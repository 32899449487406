import { Button, Typography } from 'antd';
import { PropsWithChildren } from 'react';
import LockFilled from '@ant-design/icons/LockFilled';
import ErrorNotification from '../error-notification';
import { useTranslation } from 'react-i18next';
import ProfileFormLayout from '@/components/user/profille-form-layout';

const { Text } = Typography;

type Props = {
  title: string;
  handleCancel: () => void;
  handleEdit: () => void;
  error?: string;
  loading: boolean;
  isEditing: boolean;
  locked?: boolean;
  hasData?: boolean;
  columnNumber?: number;
};

export default function EditableFormWrapper({
  title,
  isEditing,
  handleEdit,
  handleCancel,
  loading,
  error,
  children,
  locked = false,
  hasData = true,
  columnNumber,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation(['settings', 'common']);

  return (
    <div className="flex flex-col gap-6 bg-white rounded-md">
      <div className="flex flex-col justify-between gap-2 p-6 border-0 border-b border-solid sm:items-center border-border-gray sm:flex-row">
        <div className="flex items-center justify-between w-full">
          <Text strong className="!m-0 text-base">
            {title}
          </Text>
          {locked && (
            <LockFilled className="p-2 border border-solid rounded-md border-border-gray" />
          )}
        </div>
        {locked ? null : isEditing ? (
          <div className="flex flex-col-reverse gap-2 sm:flex-row">
            <Button type="text" onClick={handleCancel}>
              {t('actions.cancel', { ns: 'common' })}
            </Button>
            <Button type="primary" htmlType="submit" disabled={loading}>
              {t('actions.save', { ns: 'common' })}
            </Button>
          </div>
        ) : (
          <Button type="primary" onClick={handleEdit}>
            {t('actions.edit', { ns: 'common' })}
          </Button>
        )}
      </div>
      {(hasData || !locked) && (
        <div className="px-6">
          {error && <ErrorNotification text={error} />}
          <ProfileFormLayout columnNumber={columnNumber}>{children}</ProfileFormLayout>
        </div>
      )}
    </div>
  );
}
