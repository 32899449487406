import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { PayoutMethod, Statement, StatementStatus } from '@/types';
import { formatCurrency, formatDate, getPayoutMethodLabelKey } from '@/lib/utils';
import StatementDateCell from './statements-table/statement-date-cell';
import StatementStatusTag from './statements-table/statement-status-tag';
import StatementActionCell from './statements-table/statement-action-cell';
import { tableWidths } from '@/styles/constants';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { components } from '@/types/api-schema';
import { z } from 'zod';
import { ApiUrls } from '@/config/urls';
import { defaultHeaders } from '@/lib/api/fetchers';

type UpdateStatementIsPaidFlagInputType = components['schemas']['UpdateStatementIsPaidFlagInput'];

const updateIsPaidFlagInputSchema: z.ZodType<UpdateStatementIsPaidFlagInputType> = z
  .object({
    isPaid: z.boolean(),
  })
  .strict();

export type UpdateIsPaidFlagSchema = z.infer<typeof updateIsPaidFlagInputSchema>;

const getStatementsTableColumns = (
  isAdmin: boolean,
  isSingleClientView?: boolean,
): ColumnsType<Statement> => {
  const columns: ColumnsType<Statement> = [];

  if (!isSingleClientView && isAdmin) {
    columns.push({
      title: 'client-name',
      key: 'clientName',
      render: (_, { clientName }) => <>{clientName}</>,
      width: tableWidths.clientName,
    });
  }

  columns.push(
    {
      title: 'statement-period',
      key: 'period',
      sorter: true,
      render: (_, { year, month }) => <StatementDateCell month={month} year={year} />,
      width: tableWidths.statementDate,
    },
    {
      title: 'actions',
      key: 'actions',
      render: (_, { id }) => <StatementActionCell statementId={id} />,
      width: tableWidths.actions,
    },
    {
      title: 'status',
      key: 'status',
      render: (_, { status }) => <StatementStatusTag status={status as StatementStatus} />,
      width: tableWidths.status,
    },
    {
      title: 'approval-date',
      key: 'approvalDate',
      render: (_, { approvalDate }) => <>{formatDate(approvalDate as string | undefined)}</>,
      sorter: true,
      width: tableWidths.dateApproved,
    },
  );

  if (isAdmin) {
    columns.push(
      {
        title: 'reviewed',
        key: 'reviewed',
        render: (_, { status }) => (
          <Checkbox disabled={true} checked={status === StatementStatus.REVIEWED} />
        ),
        width: tableWidths.checkbox,
      },
      {
        title: 'sent',
        key: 'sent',
        render: (_, { status }) => (
          <Checkbox disabled={true} checked={status === StatementStatus.APPROVED} />
        ),
        width: tableWidths.checkbox,
      },
      {
        title: 'paid',
        key: 'paid',
        render: (_, { id, isPaid }) => {
          return (
            <Checkbox
              defaultChecked={isPaid || false}
              onClick={event => {
                const isChecked = (event.target as HTMLInputElement).checked;
                fetch(`${ApiUrls.STATEMENT_IS_PAID}/${id}`, {
                  headers: defaultHeaders,
                  method: 'PATCH',
                  body: JSON.stringify({
                    isPaid: isChecked,
                  }),
                });
              }}
            />
          );
        },
        width: tableWidths.checkbox,
      },
    );
  }

  if (!isSingleClientView) {
    columns.push({
      title: 'payout-method',
      key: 'payout-method',
      width: tableWidths.payoutMethod,
    });
  }

  columns.push(
    {
      title: 'royalties-due',
      key: 'royalties',
      render: (_, statement) => {
        const { data } = statement as Statement;

        return (
          <>
            {statement.status === StatementStatus.APPROVED
              ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                formatCurrency((data as Record<string, any>).summary.royaltiesDue)
              : '-'}
          </>
        );
      },
      width: tableWidths.royalties,
    },
    {
      title: 'balance',
      key: 'balance',
      render: (_, statement) => {
        const { data } = statement as Statement;

        return (
          <>
            {statement.status === StatementStatus.APPROVED
              ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                formatCurrency((data as Record<string, any>).summary.invoiceBalanceTotal)
              : '-'}
          </>
        );
      },
      width: tableWidths.balance,
    },
  );

  if (isAdmin && !isSingleClientView) {
    columns.push(
      {
        title: 'note1',
        key: 'note1',
        render: (_, { note1 }) => <>{note1}</>,
        width: tableWidths.note,
      },
      {
        title: 'note2',
        key: 'note2',
        render: (_, { note2 }) => <>{note2}</>,
        width: tableWidths.note,
      },
      {
        title: 'note3',
        key: 'note3',
        render: (_, { note3 }) => <>{note3}</>,
        width: tableWidths.note,
      },
      {
        title: 'note4',
        key: 'note4',
        render: (_, { note4 }) => <>{note4}</>,
        width: tableWidths.note,
      },
      {
        title: 'note5',
        key: 'note5',
        render: (_, { note5 }) => <>{note5}</>,
        width: tableWidths.note,
      },
    );
  }

  return columns;
};

export const useStatementsTable = (
  isAdmin: boolean,
  sort?: string | null,
  order?: string | null,
  isSingleClientView?: boolean,
): ColumnsType<Statement> => {
  const { t } = useTranslation(['statement']);
  const { t: c } = useTranslation(['settings']);

  const res = getStatementsTableColumns(isAdmin, isSingleClientView).map(column => {
    if (column.key === `${sort}`) {
      return {
        ...column,
        title: t(`table.${column.title}`),
        defaultSortOrder: order === 'ascend' ? ('ascend' as const) : ('descend' as const),
      };
    }
    if (column.key === 'payout-method') {
      return {
        ...column,
        title: t(`table.${column.title}`),
        render: (_: unknown, statement: Statement) => {
          const { payoutMethod } = statement;
          return (
            <>{payoutMethod ? c(getPayoutMethodLabelKey(payoutMethod as PayoutMethod)) : '-'}</>
          );
        },
      };
    }
    return {
      ...column,
      title: t(`table.${column.title}`),
    };
  });

  return res;
};
