import { ExpenseData } from '@/types';
import EditExpenseModal from '../edit-expense-modal';
import DeleteExpenseAction from '../delete-expense-action';

type Props = {
  expense: ExpenseData;
};

export default function ExpenseActions({ expense }: Props) {
  return (
    <div className="flex gap-2">
      <EditExpenseModal expense={expense} />
      <DeleteExpenseAction id={expense.id} />
    </div>
  );
}
