import { monthsMap } from '@/lib/utils';
import { useTranslation } from 'react-i18next';

type Props = {
  month: number;
  year: number;
};

export default function StatementDateCell({ month, year }: Props) {
  const { t } = useTranslation('common');

  return (
    <>
      {/* @ts-expect-error month will always match something as they are stored in db from 1 to 12 */}
      {t(`months.${monthsMap[month]}`)} {year}
    </>
  );
}
