import DashboardHeader from '@/components/dashboard/header';
import FaultyOrdersTable from '@/components/shipments/unsorted-orders-table';
import useRangeParams from '@/lib/hooks/useRangeParams';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { endOfMonth, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
const { RangePicker } = DatePicker;

const formatRangeParams = (from?: string | null, to?: string | null) => {
  if (from && to) {
    const [fromYear, fromMonth] = from.split('-');
    const [toYear, toMonth] = to.split('-');

    const fromDate = format(new Date(`${fromYear}-${fromMonth}`), 'yyyy-MM-dd');
    const toDate = format(endOfMonth(new Date(`${toYear}-${toMonth}`)), 'yyyy-MM-dd');

    return { fromDate, toDate };
  }
  return { fromDate: undefined, toDate: undefined };
};

export default function UnsortedOrders() {
  const { t } = useTranslation('common');
  const { from, to, onChange } = useRangeParams(false);

  const { fromDate, toDate } = formatRangeParams(from, to);

  return (
    <div>
      <DashboardHeader title={t('side-menu.navigation.unsorted-orders')} />
      <RangePicker
        picker="month"
        format="YYYY-MM"
        value={from && to ? [dayjs(from, 'YYYY-MM'), dayjs(to, 'YYYY-MM')] : undefined}
        onChange={onChange}
        allowClear={false}
      />
      <div className="mb-6" />
      <FaultyOrdersTable from={fromDate} to={toDate} />
    </div>
  );
}
