import { ApiUrls } from '@/config/urls';
import useSWR, { Fetcher } from 'swr';
import { ClientStatus, SortOrder } from '@/types';
import { components } from '@/types/api-schema';
import { defaultHeaders, parseResponseOrThrowErr } from '../api/fetchers';

type ClientsResponse = components['schemas']['GetPaginatedClientsResponse'];

type ClientsRequest = components['schemas']['GetPaginatedClientsRequestBody'];

export const CLIENTS_PER_PAGE = 10;

const DEFAULT_SORT = {
  name: 'asc' as const,
};

const getClients: Fetcher<
  ClientsResponse,
  {
    url: string;
    sort: ClientsRequest['sort'];
    filter: ClientsRequest['filter'];
    search: ClientsRequest['search'];
  }
> = async ({ url, sort, filter, search }) => {
  const body: ClientsRequest = {
    sort: sort ? { ...sort } : { ...DEFAULT_SORT },
    filter,
    search,
  };

  const res = await fetch(url, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify(body) as BodyInit,
  });

  return parseResponseOrThrowErr(res);
};

export const useClients = (
  page: number,
  limit: number,
  sort?: string | null,
  order?: SortOrder | null,
  isDRMMallClient?: boolean | null,
  status?: ClientStatus | null | undefined,
  searchTerm?: string | null,
  pause?: boolean,
) => {
  const offset = (page - 1) * limit;

  let sortObj: ClientsRequest['sort'] | undefined;
  let filterObj: ClientsRequest['filter'] | undefined;
  let searchObj: ClientsRequest['search'] | undefined;

  if (searchTerm) {
    searchObj = { name: searchTerm };
  }

  if (sort && order) {
    sortObj = { [sort]: order };
  }

  if (isDRMMallClient || isDRMMallClient === false) {
    filterObj = { isDRMMallClient };
  }

  if (status) {
    filterObj = { status };
  }

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    !pause
      ? {
          url: `${ApiUrls.CLIENTS}?limit=${limit}&offset=${offset}`,
          sort: sortObj,
          filter: filterObj,
          search: searchObj,
        }
      : null,
    getClients,
    { keepPreviousData: true },
  );

  return {
    clients: data?.clients,
    pagination: data?.pagination,
    mutate,
    isLoading,
    isValidating,
    isError: error,
  };
};
