import { PageUrls } from '@/config/urls';
import { Link } from 'react-router-dom';

type Props = {
  name: string | null;
  clientId?: string | null;
};

export default function VendorCell({ name, clientId }: Props) {
  if (clientId) {
    return <Link to={`${PageUrls.CLIENTS}/${clientId}`}>{name}</Link>;
  }

  return <>{name || '--'}</>;
}
