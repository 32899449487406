import { Button, Popconfirm } from 'antd';
import { useState } from 'react';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { ApiUrls } from '@/config/urls';
import useSWRMutation from 'swr/mutation';
import { createFetcher } from '@/lib/api/fetchers';
import { useTranslation } from 'react-i18next';
import { useNotify } from '@/lib/hooks/useNofity';
import { useStatementStore } from '@/store/useStatementStore';
import { StatementStatus } from '@/types';

type Props = {
  id: string;
};

export default function DeleteExpenseAction({ id }: Props) {
  const { t } = useTranslation(['statement', 'errors']);
  const [open, setOpen] = useState(false);
  const { notify } = useNotify();

  const { removeExpense, statement } = useStatementStore();

  const { trigger, isMutating } = useSWRMutation(
    `${ApiUrls.EXPENSE}/${id}`,
    createFetcher('DELETE'),
  );

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    try {
      await trigger({});
      removeExpense(id);
      setOpen(false);
    } catch (err) {
      if (err instanceof Error) {
        notify(
          t([`apiErrors.${err?.message}`, 'apiErrors.generic'], {
            ns: 'errors',
          }),
          'error',
        );
      }
    }
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Popconfirm
        title={t('expenses.delete-title')}
        description={t('expenses.delete-description')}
        open={open}
        onConfirm={handleOk}
        okButtonProps={{ loading: isMutating }}
        onCancel={handleCancel}
      >
        <Button
          icon={<DeleteOutlined />}
          onClick={showPopconfirm}
          disabled={isMutating || statement?.status !== StatementStatus.IN_PROGRESS}
        />
      </Popconfirm>
    </>
  );
}
