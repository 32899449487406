import { useTranslation } from 'react-i18next';
import range from 'lodash/range';
import { monthsMap } from '../utils';

const getYearsDropdown = () => {
  const currentYear = new Date().getFullYear();

  return range(2023, currentYear + 1).map(number => ({
    value: number,
    label: String(number),
  }));
};

export const useMonthsAndYearsOptions = () => {
  const { t } = useTranslation('common');

  return {
    monthsOptions: range(1, 13).map(number => ({
      value: number,
      //@ts-expect-error range covers all months
      label: t(`months.${monthsMap[number]}`),
    })),
    yearsOptions: getYearsDropdown(),
  };
};
