'use client';
import ResponsiveTableContainer from '@/components/common/responsiveTableContainer';
import { Tabs, Typography } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
const { Title } = Typography;

type DashboardHeaderProps = {
  title: string;
  links?: {
    href: string;
    label: string;
  }[];
  tag?: JSX.Element;
};

export default function DashboardHeader({ links, title, tag }: DashboardHeaderProps) {
  const location = useLocation();

  const activeTabKey = links?.find(link => link.href === location.pathname)?.href;

  const tabItems = links?.map(link => ({
    key: link.href,
    label: (
      <Link
        to={link.href}
        className={twMerge(
          'py-3  no-underline cursor-pointer  hover:text-link-blue text-base',
          location.pathname === link.href
            ? 'text-link-blue  border-link-blue'
            : 'text-text-primary',
        )}
      >
        {link.label}
      </Link>
    ),
    children: null,
  }));

  return (
    <div>
      <div className="flex items-center gap-2 mb-6">
        <Title level={3} className="!m-0">
          {title}
        </Title>
        {tag}
      </div>
      {tabItems && (
        <ResponsiveTableContainer>
          <Tabs items={tabItems} activeKey={activeTabKey} />
        </ResponsiveTableContainer>
      )}
    </div>
  );
}
