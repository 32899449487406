import { formatCurrency } from '@/lib/utils';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import ExpenseActions from './expense-actions';
import { AntSortOrder, ExpenseData } from '@/types';
import { tableWidths } from '@/styles/constants';
import dayjs from 'dayjs';

export const EXPENSES_PER_PAGE = 10;

export enum ExpensesSortableColumns {
  DATE = 'date',
}

const columns: ColumnsType<ExpenseData> = [
  {
    title: 'order-number',
    key: 'orderNumber',
    render: (_, { orderNumber }) => <>{orderNumber}</>,
    width: tableWidths.orderNumber,
  },
  {
    title: 'description',
    key: 'description',
    render: (_, { description }) => <>{description}</>,
    width: tableWidths.expenseDescription,
  },
  {
    title: 'date',
    key: 'date',
    render: (_, { date }) => <>{dayjs(date).format('MM/DD/YYYY')}</>,
    sorter: true,
    width: tableWidths.date,
  },
  {
    title: 'total',
    key: 'total',
    render: (_, { total }) => <>{formatCurrency(total)}</>,
    width: tableWidths.balance,
  },
  {
    title: 'note',
    key: 'note',
    render: (_, { note }) => <>{note}</>,
    width: tableWidths.expenseNote,
  },
  {
    title: 'actions',
    key: 'actions',
    render: (_, record) => <ExpenseActions expense={record} />,
    width: tableWidths.actions,
  },
];

export const useStatementExpensesTable = (
  sort?: ExpensesSortableColumns | null,
  order?: AntSortOrder | null,
  isAdmin?: boolean,
): ColumnsType<ExpenseData> => {
  const { t } = useTranslation('statement');

  return columns.map(column => {
    if (!isAdmin && column.key === 'actions') {
      return { ...column, hidden: true };
    }
    if (column.key === `${sort}`) {
      return {
        ...column,
        title: t(`expenses.common.${column.title}`),
        defaultSortOrder: order === 'ascend' ? ('ascend' as const) : ('descend' as const),
      };
    }
    return {
      ...column,
      title: t(`expenses.common.${column.title}`),
    };
  });
};
