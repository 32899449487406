'use client';

import { PageUrls } from '@/config/urls';
import { Link } from 'react-router-dom';

type Props = {
  id: string;
  name: string;
};

export default function ClientNameCell({ id, name }: Props) {
  return <Link to={`${PageUrls.CLIENTS}/${id}/summary`}>{name}</Link>;
}
