import type { ColumnsType } from 'antd/es/table';
import { OrderStatus, PendingShipment, SortOrder, UserRole } from '@/types';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate } from '@/lib/utils';
import FulfillmentStatus from './table/fulfillmentStatus';
import { tableWidths } from '@/styles/constants';
import { useCurrentUserContext } from '../providers/user/use-current-user-context';
import ViewLinkCell from '../common/view-link-cell';

const columns: ColumnsType<PendingShipment> = [
  {
    title: 'order-number',
    key: 'orderNumber',
    render: (_, { order }) => <>{order?.name}</>,
    width: tableWidths.orderNumber,
  },
  {
    title: 'product-name',
    key: 'productName',
    render: (_, { product }) => <>{product?.name}</>,
    width: tableWidths.productName,
  },
  {
    title: 'fulfillment-status',
    key: 'orderFulfillmentStatus',
    render: (_, { orderLineItem }) => (
      <FulfillmentStatus status={orderLineItem.status as OrderStatus} />
    ),
    width: tableWidths.status,
  },
  {
    title: 'order-date',
    key: 'orderCreatedAt',
    render: (_, { order }) => (
      <>{order?.createdAt ? formatDate(new Date(order.createdAt as string)) : '--'}</>
    ),
    sorter: true,
    width: tableWidths.date,
  },
  {
    title: 'product-sku',
    key: 'productVariant.sku',
    render: (_, { productVariant }) => <>{productVariant?.sku}</>,
    width: tableWidths.sku,
  },
  {
    title: 'product-price',
    key: 'productPrice',
    render: (_, { orderLineItem }) => <>{formatCurrency(orderLineItem.price)}</>,
    width: tableWidths.price,
  },
  {
    title: 'actions',
    key: 'actions',
    render: (_, { order }) => (
      <ViewLinkCell link={`https://admin.shopify.com/store/downrightmerchinc/orders/${order.id}`} />
    ),
    width: tableWidths.actions,
  },
];

export const usePendingShipmentsTableColumns = (
  sort?: string | null,
  order?: string | null,
): ColumnsType<PendingShipment> => {
  const { t } = useTranslation('clients');
  const { user } = useCurrentUserContext();

  return columns.map(column => {
    if (column.key === `order.${sort}`) {
      return {
        ...column,
        title: t(`pending-shipments.table.${column.title}`),
        defaultSortOrder: order === SortOrder.ASC ? ('ascend' as const) : ('descend' as const),
      };
    }
    if (column.key === 'actions' && user?.role !== UserRole.ADMIN) {
      return { hidden: true };
    }
    return {
      ...column,
      title: t(`pending-shipments.table.${column.title}`),
    };
  });
};
