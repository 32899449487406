import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { StatementResponse } from '@/lib/hooks/useStatement';
import { formatCurrency } from '@/lib/utils';
import StatementInvoiceStatus from './statement-invoice-status';
import { InvoicePaymentStatus, TabsState } from '@/types';
import { tableWidths } from '@/styles/constants';
import InvoicesDownloadCell from '../invoices/invoices-download-cell';

export const INVOICES_PER_PAGE = 5;

export type StatementInvoice = NonNullable<StatementResponse['statementData']['invoices']>[0];

export type StatementInvoiceTableDataType = StatementInvoice & {
  key: string;
  clientName: string;
  clientId: string;
};

const columns: ColumnsType<StatementInvoiceTableDataType> = [
  {
    key: 'sort',
  },
  {
    title: 'id',
    key: 'id',
    render: (_, { id }) => <>{id}</>,
    width: tableWidths.orderNumber,
  },
  {
    title: 'invoice-amount',
    key: 'totalAmount',
    render: (_, { totalAmount }) => <>{formatCurrency(totalAmount)}</>,
    width: tableWidths.invoiceTableBalance,
  },
  {
    title: 'opening-balance',
    key: 'openingBalance',
    render: (_, { openingBalance }) => <>{formatCurrency(openingBalance)}</>,
    width: tableWidths.invoiceTableBalance,
  },
  {
    title: 'royalty-amount-applied',
    key: 'royaltyAmountApplied',
    render: (_, { royaltyAmountApplied }) => <>{formatCurrency(royaltyAmountApplied || 0)}</>,
    width: tableWidths.royaltyAmountApplied,
  },
  {
    title: 'closing-balance',
    key: 'closingBalance',
    render: (_, { closingBalance }) => <>{formatCurrency(closingBalance || 0)}</>,
    width: tableWidths.invoiceTableBalance,
  },
  {
    title: 'payment-status',
    key: 'paymentStatus',
    width: tableWidths.status,
    render: (_, { paymentStatus }) => (
      <StatementInvoiceStatus paymentStatus={paymentStatus as InvoicePaymentStatus} />
    ),
  },
  {
    title: 'current-balance',
    key: 'currentBalance',
    render: (_, { currentBalance }) => <>{formatCurrency(currentBalance)}</>,
    width: tableWidths.invoiceTableBalance,
  },
  {
    title: 'actions',
    key: 'actions',
    width: tableWidths.actions,
    render: (_, { id, clientName, clientId }) => (
      <InvoicesDownloadCell invoiceId={id} clientName={clientName} clientId={clientId} />
    ),
  },
];

export const useStatementInvoiceTable = (
  isOrdering: boolean = false,
  activeTab: TabsState,
): ColumnsType<StatementInvoiceTableDataType> => {
  const { t } = useTranslation('invoice');

  return columns.map(column => {
    if (column.key === 'sort') {
      return {
        ...column,
        hidden: !isOrdering,
        width: tableWidths.actions,
      };
    }
    if (
      activeTab === TabsState.HIDDEN &&
      (column.key === 'royaltyAmountApplied' || column.key === 'closingBalance')
    ) {
      return {
        hidden: true,
      };
    }
    return {
      ...column,
      title: t(`table.${column.title}`),
    };
  });
};
