import { useRoutes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import Clients from './routes/clients/index.tsx';

import { ProtectedRoute } from './components/auth/protectedRoute.tsx';
import Layout from './components/dashboard/layout/index.tsx';
import LayoutClients from './components/clients/layout-clients/index.tsx';
import Statements from './routes/statements/index.tsx';
import Invoices from './routes/invoices/index.tsx';

import PendingShipments from './routes/pending-shipments/index.tsx';
import Loading from './components/common/loading.tsx';
import LayoutSettings from './components/settings/layout-settings/index.tsx';
import { Suspense, lazy } from 'react';
import { PublicRoute } from './components/auth/publicRoute.tsx';
import { ClientRoute } from './components/auth/clientRoute.tsx';
import { AdminRoute } from './components/auth/adminRoute.tsx';
import PageNotFound from './routes/page-not-found.tsx';
import ClientStatement from './routes/statements/client-statement.tsx';
import ClientStatementSoftGoods from './routes/statements/client-statement-soft-goods.tsx';
import ClientStatementExpenses from './routes/statements/client-statemenet-expenses.tsx';
import ClientStatementMedia from './routes/statements/client-statement-media.tsx';
import ClientStatementDigital from './routes/statements/client-statement-digital.tsx';
import ClientStatementRedirect from './routes/statements/client-statement-redirect.tsx';
import TermsOfService from './routes/legal/terms-of-service.tsx';
import PrivacyPolicy from './routes/legal/privacy-policy.tsx';
import AuthContainerLayout from './components/auth/authContainerLayout.tsx';
import AuthLayout from './components/auth/authLayout.tsx';
import UnsortedOrders from './routes/unsorted-orders/index.tsx';

const FAQ = lazy(() => import('./routes/faq/index.tsx'));
const Summary = lazy(() => import('./routes/summary/index.tsx'));
const ClientShipments = lazy(() => import('./routes/clients/client-shipments.tsx'));
const ClientStatements = lazy(() => import('./routes/clients/client-statements.tsx'));
const ClientInvoices = lazy(() => import('./routes/clients/client-invoices.tsx'));
const ClientProfile = lazy(() => import('./routes/clients/client-profile.tsx'));
const ClientMembers = lazy(() => import('./routes/clients/client-members.tsx'));
const ClientSummary = lazy(() => import('./routes/clients/client-summary.tsx'));
const ClientProducts = lazy(() => import('./routes/clients/client-products.tsx'));
const ResetPassword = lazy(() => import('./routes/auth/reset-password.tsx'));
const PasswordResetSuccess = lazy(
  () => import('./components/auth/password-reset-success-card/index.tsx'),
);
const SignUp = lazy(() => import('./routes/auth/signup.tsx'));
const Auth = lazy(() => import('./routes/auth/auth.tsx'));
const Quickbooks = lazy(() => import('./routes/settings/quickbooks.tsx'));
const CompanyProfile = lazy(() => import('./routes/settings/company-profile.tsx'));
const Profile = lazy(() => import('./routes/settings/profile.tsx'));
const Members = lazy(() => import('./routes/settings/members.tsx'));

const routes = [
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Navigate to="/summary" replace />{' '}
          </Suspense>
        ),
      },
      {
        path: '/summary',
        element: (
          <Suspense fallback={<Loading />}>
            <Summary />
          </Suspense>
        ),
      },
      {
        path: '/clients',
        element: (
          <Suspense fallback={<Loading />}>
            <AdminRoute>
              <Clients />
            </AdminRoute>
          </Suspense>
        ),
      },
      {
        path: '/clients/:id',
        element: (
          <AdminRoute>
            <LayoutClients />
          </AdminRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Loading />}>
                <ClientSummary />
              </Suspense>
            ),
          },
          {
            path: 'summary',
            element: (
              <Suspense fallback={<Loading />}>
                <ClientSummary />
              </Suspense>
            ),
          },
          {
            path: 'pending-shipments',
            element: (
              <Suspense fallback={<Loading />}>
                <ClientShipments />
              </Suspense>
            ),
          },
          {
            path: 'statements',
            element: (
              <Suspense fallback={<Loading />}>
                <ClientStatements />
              </Suspense>
            ),
          },
          {
            path: 'invoices',
            element: (
              <Suspense fallback={<Loading />}>
                <ClientInvoices />
              </Suspense>
            ),
          },
          {
            path: 'products',
            element: (
              <Suspense fallback={<Loading />}>
                <ClientProducts />
              </Suspense>
            ),
          },
          {
            path: 'profile',
            element: (
              <Suspense fallback={<Loading />}>
                <ClientProfile />
              </Suspense>
            ),
          },
          {
            path: 'members',
            element: (
              <Suspense fallback={<Loading />}>
                <ClientMembers />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'shipments',
        element: (
          <Suspense fallback={<Loading />}>
            <PendingShipments />
          </Suspense>
        ),
      },
      {
        path: 'unsorted-orders',
        element: (
          <Suspense fallback={<Loading />}>
            <UnsortedOrders />
          </Suspense>
        ),
      },
      {
        path: '/statements',
        element: (
          <Suspense fallback={<Loading />}>
            <Statements />
          </Suspense>
        ),
      },
      {
        path: '/statements/:id',
        element: (
          <Suspense fallback={<Loading />}>
            <ClientStatement />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: <ClientStatementRedirect />,
          },
          {
            path: 'soft-goods',
            element: <ClientStatementSoftGoods />,
          },
          {
            path: 'media',
            element: <ClientStatementMedia />,
          },
          {
            path: 'digital',
            element: <ClientStatementDigital />,
          },
          {
            path: 'expenses',
            element: <ClientStatementExpenses />,
          },
        ],
      },
      {
        path: '/invoices',
        element: (
          <Suspense fallback={<Loading />}>
            <ClientRoute>
              <Invoices />
            </ClientRoute>
          </Suspense>
        ),
      },
      {
        path: '/faq',
        element: (
          <Suspense fallback={<Loading />}>
            <FAQ />
          </Suspense>
        ),
      },
      {
        path: '/settings',
        element: (
          <Suspense fallback={<Loading />}>
            <LayoutSettings />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="profile" replace />,
          },
          {
            path: 'profile',
            element: (
              <Suspense fallback={<Loading />}>
                <Profile />
              </Suspense>
            ),
          },
          {
            path: 'company-profile',
            element: (
              <ClientRoute>
                <Suspense fallback={<Loading />}>
                  <CompanyProfile />
                </Suspense>
              </ClientRoute>
            ),
          },
          {
            path: 'members',
            element: (
              <Suspense fallback={<Loading />}>
                <Members />
              </Suspense>
            ),
          },
          {
            path: 'quickbooks',
            element: (
              <AdminRoute>
                <Suspense fallback={<Loading />}>
                  <Quickbooks />
                </Suspense>
              </AdminRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/auth',
    element: (
      <PublicRoute>
        <AuthLayout />
      </PublicRoute>
    ),
    children: [
      {
        element: <AuthContainerLayout withImage={true} />,
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Loading />}>
                <Auth />
              </Suspense>
            ),
          },
          {
            path: '/auth/reset-password',
            element: (
              <Suspense fallback={<Loading />}>
                <ResetPassword />
              </Suspense>
            ),
          },
          {
            path: '/auth/signup',
            element: (
              <Suspense fallback={<Loading />}>
                <SignUp />
              </Suspense>
            ),
          },
        ],
      },
      {
        element: <AuthContainerLayout />,
        children: [
          {
            path: '/auth/reset-password-success',
            element: (
              <Suspense fallback={<Loading />}>
                <PasswordResetSuccess />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/terms-of-service',
    element: <TermsOfService />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
];

export default function Routes() {
  const element = useRoutes(routes);
  return element;
}
