import DashboardSection from '@/components/dashboard/section';
import StatementInfoTile from '../statement-info-tile';
import { StatementSummary } from '@/types';
import { useTranslation } from 'react-i18next';
import { useStatementStore } from '@/store/useStatementStore';
import { twMerge } from 'tailwind-merge';
import BalancePopover from './components/balance-popover';

type Props = {
  summary: StatementSummary;
};

export default function StatementsHighLights(props: Props) {
  const { t } = useTranslation('statement');
  const { loading, updating, invoices, summary } = useStatementStore();

  const getRoyalties = () => {
    if (summary?.royaltiesDueAfterAppliedInvoices != null) {
      return summary?.royaltiesDueAfterAppliedInvoices;
    } else if (summary?.royaltiesDueAfterAdvance != null) {
      return summary?.royaltiesDueAfterAdvance;
    }
    return props.summary.royaltiesDue;
  };

  const getInvoiceBalance = () => {
    if (summary?.invoiceBalanceAfterAppliedRoyalties != null) {
      return summary?.invoiceBalanceAfterAppliedRoyalties;
    }
    return props.summary.invoiceBalanceTotal || 0;
  };

  return (
    <>
      <DashboardSection>
        <div
          className={twMerge(
            'grid  gap-4  sm:grid-cols-2 ',
            (!summary?.openAdvance && !invoices.hasQuickbooks) ||
              (!summary?.openAdvance && invoices.hasQuickbooks) ||
              (summary?.openAdvance != 0 && invoices.hasQuickbooks)
              ? 'md:grid-cols-[minmax(max-content,_1fr)_minmax(max-content,_1fr)_minmax(max-content,_1fr)_minmax(max-content,_1fr)] lg:grid-cols-4'
              : 'md:grid-cols-[minmax(max-content,_1fr)_minmax(max-content,_1fr)_minmax(max-content,_1fr)] lg:grid-cols-3',
          )}
        >
          <StatementInfoTile
            label={t('gross-sales')}
            value={props.summary?.grossSales.grossSalesTotal}
            loading={loading || updating}
          />
          <StatementInfoTile
            label={t('payment-processing')}
            value={props.summary?.paymentProcessing.paymentProcessingTotal}
            loading={loading || updating}
          />
          <StatementInfoTile
            label={t('net-profit')}
            value={props.summary?.netProfit.netProfitTotal}
            loading={loading || updating}
          />

          {invoices.hasQuickbooks && (
            <StatementInfoTile
              label={<BalancePopover />}
              value={getInvoiceBalance()}
              loading={loading || updating}
            />
          )}
          {!summary?.openAdvance && !invoices.hasQuickbooks && (
            <StatementInfoTile
              label={t('total-royalties-payable')}
              value={getRoyalties()}
              loading={loading || updating}
            />
          )}
        </div>
      </DashboardSection>
      {(summary?.openAdvance != 0 || invoices.hasQuickbooks) && (
        <DashboardSection>
          <div
            className={twMerge(
              'grid  gap-4  sm:grid-cols-2 ',
              invoices.hasQuickbooks && summary?.openAdvance != 0
                ? 'md:grid-cols-[minmax(max-content,_1fr)_minmax(max-content,_1fr)_minmax(max-content,_1fr)_minmax(max-content,_1fr)] lg:grid-cols-4'
                : 'md:grid-cols-[minmax(max-content,_1fr)_minmax(max-content,_1fr)_minmax(max-content,_1fr)] lg:grid-cols-3',
            )}
          >
            <StatementInfoTile
              label={t('royalties-due')}
              value={summary?.royaltiesDue || 0}
              loading={loading || updating}
            />
            {summary?.royaltiesDueAfterAdvance != 0 && summary?.openAdvance != 0 && (
              <StatementInfoTile
                label={t('royalties-applied-to-advances')}
                value={summary?.openAdvance || 0}
                loading={loading || updating}
              />
            )}
            {invoices.hasQuickbooks && (
              <StatementInfoTile
                label={t('royalties-applied-to-invoices')}
                value={summary?.royaltiesAppliedToInvoices || 0}
                loading={loading || updating}
              />
            )}
            <StatementInfoTile
              label={t('total-royalties-payable')}
              value={getRoyalties()}
              loading={loading || updating}
            />
          </div>
        </DashboardSection>
      )}
    </>
  );
}
