import { OrderStatus } from '@/types';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  status: OrderStatus;
};

const getOrderStatus = (status: OrderStatus) => {
  if (status === OrderStatus.FULFILLED)
    return { color: 'green', translation: 'pending-shipments.fulfilled' };
  if (status === OrderStatus.REFUNDED)
    return { color: 'orange', translation: 'pending-shipments.refunded' };
  if (status === OrderStatus.PARTLY_REFUNDED)
    return { color: 'yellow', translation: 'pending-shipments.partly-fulfilled' };
  return { color: 'volcano', translation: 'pending-shipments.unfulfilled' };
};

export default function FulfillmentStatus({ status }: Props) {
  const { t } = useTranslation('clients');

  const { color, translation } = getOrderStatus(status);

  return <Tag color={color}>{t(translation)}</Tag>;
}
