import { PageUrls } from '@/config/urls';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { Tabs } from 'antd';
import NavLinksThemeProvider from './navLinsThemeProvider';
import { StatementTabNavLink } from '@/types';

const navLinks = [
  { translationKey: 'nav.soft-goods', link: StatementTabNavLink.SOFT_GOODS },
  { translationKey: 'nav.media', link: StatementTabNavLink.MEDIA },
  { translationKey: 'nav.digital', link: StatementTabNavLink.DIGITAL },
  { translationKey: 'nav.expenses', link: StatementTabNavLink.EXPENSES },
];

type Props = {
  statementId: string | undefined;
  n: Record<StatementTabNavLink, number>;
};

export default function StatementsNavLinks({ statementId, n }: Props) {
  const { t } = useTranslation('statement');
  const location = useLocation();

  const activeTabKey = navLinks?.find(
    link => `${PageUrls.STATEMENTS}/${statementId}/${link.link}` === location.pathname,
  )?.link;

  const tabItems = navLinks?.map(link => ({
    key: link.link,
    label: (
      <Link
        to={`${PageUrls.STATEMENTS}/${statementId}/${link.link}`}
        className={twMerge(
          'pb-1 no-underline cursor-pointer  hover:text-link-blue text-sm block',
          location.pathname === `${PageUrls.STATEMENTS}/${statementId}/${link.link}`
            ? 'text-link-blue  border-link-blue'
            : 'text-text-primary',
        )}
      >
        {t(link.translationKey)} ({n[link.link]})
      </Link>
    ),
    children: null,
  }));

  return (
    <NavLinksThemeProvider>
      <Tabs items={tabItems} activeKey={activeTabKey} type="card" />
    </NavLinksThemeProvider>
  );
}
