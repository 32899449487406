import { useClientsColumns } from '@/components/clients/useClientsTableColumns';
import { CLIENTS_PER_PAGE, useClients } from '@/lib/hooks/useClients';
import { Client, ClientStatus, ClientTableDataType, DRMClientFilterValues } from '@/types';
import { Table, Empty } from 'antd';
import type { TableProps } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import DashboardHeader from '@/components/dashboard/header';
import Search from 'antd/es/input/Search';
import AddClientModal from '@/components/clients/add-client-modal';
import { useTranslation } from 'react-i18next';
import { ArrayParam, useQueryParams } from 'use-query-params';
import { useTableSearch } from '@/lib/hooks/useTableSearch';
import { useTablePage } from '@/lib/hooks/useTablePage';
import { useTableSort } from '@/lib/hooks/useTableSort';
import ResponsiveTableContainer from '@/components/common/responsiveTableContainer';

const formatClients = (client: Client[]): ClientTableDataType[] =>
  client.map(client => ({
    ...client,
    key: client.id,
    tourAccountManager: client.tourAccountManager?.name,
    eComAccountManager: client.eComAccountManager?.name,
    accountsPayableManager: client.accountsPayableManager?.name,
  }));

const formatIsDRMMallClient = (isDRMMallClient: (string | null)[] | null | undefined) => {
  if (
    isDRMMallClient &&
    isDRMMallClient.includes(DRMClientFilterValues.TRUE) &&
    !isDRMMallClient.includes(DRMClientFilterValues.FALSE)
  ) {
    return true;
  }
  if (
    isDRMMallClient &&
    isDRMMallClient.includes(DRMClientFilterValues.FALSE) &&
    !isDRMMallClient.includes(DRMClientFilterValues.TRUE)
  ) {
    return false;
  }
  return undefined;
};

const formatStatus = (status: (string | null)[] | null | undefined) => {
  if (status && status.includes(ClientStatus.ACTIVE) && !status.includes(ClientStatus.INACTIVE)) {
    return ClientStatus.ACTIVE;
  }
  if (status && status.includes(ClientStatus.INACTIVE) && !status.includes(ClientStatus.ACTIVE)) {
    return ClientStatus.INACTIVE;
  }
  return undefined;
};

export default function Clients() {
  const { t } = useTranslation('clients');

  const [query, setQuery] = useQueryParams({
    status: ArrayParam,
    isDRMMallClient: ArrayParam,
  });

  const { searchTerm, onTableSearch } = useTableSearch();
  const { page, limit, onPageChange } = useTablePage();
  const { sort, setSort, order, orderForApi, setOrder } = useTableSort(['name']);

  const formattedIsDRMMallClient = formatIsDRMMallClient(query?.isDRMMallClient);
  const formattedStatus = formatStatus(query.status);

  const columns = useClientsColumns(sort, order, query?.isDRMMallClient, query?.status);

  const { clients, isLoading, mutate, pagination } = useClients(
    page,
    limit,
    sort,
    orderForApi,
    formattedIsDRMMallClient,
    formattedStatus,
    searchTerm?.trim(),
  );

  const onChange: TableProps<ClientTableDataType>['onChange'] = (_, filters, sorter) => {
    const o = sorter as SorterResult<ClientTableDataType>;

    setOrder(o?.order);
    setSort(o?.columnKey as string);

    setQuery(query => {
      return {
        ...query,
        isDRMMallClient: filters?.isDRMMallClient as [],
        status: filters?.status as [],
      };
    });
  };

  const formattedClients = formatClients(clients || []);

  const totalClients = pagination?.count || 0;

  return (
    <>
      <div>
        <DashboardHeader title={t('title')} />
        <div className="flex flex-col items-center gap-4 mb-6 justify-stretch sm:justify-between sm:flex-row">
          <div className="w-full sm:w-[22rem] flex">
            <Search
              defaultValue={searchTerm || ''}
              placeholder={t('search-placeholder')}
              onSearch={(value, event, info) => onTableSearch(value, event, info)}
              enterButton
              allowClear
            />
          </div>
          <div className="w-full sm:w-fit">
            <AddClientModal onSuccess={() => mutate()} />
          </div>
        </div>
        <ResponsiveTableContainer>
          <Table
            columns={columns}
            dataSource={formattedClients}
            onChange={onChange}
            loading={isLoading}
            scroll={{ x: 2000 }}
            locale={{
              emptyText: (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('table.empty')} />
              ),
            }}
            pagination={
              totalClients > CLIENTS_PER_PAGE
                ? {
                    current: page,
                    total: totalClients,
                    pageSize: limit,
                    onChange: onPageChange,
                    position: ['bottomLeft'],
                    showSizeChanger: true,
                  }
                : false
            }
          />
        </ResponsiveTableContainer>
      </div>
    </>
  );
}
