import { ReactNode } from 'react';

type Props = {
  children: ReactNode | undefined;
  columnNumber?: number;
};

export default function ProfileFormLayout({ children, columnNumber }: Props) {
  return <div className={`grid md:grid-cols-${columnNumber || 2} md:gap-4`}>{children}</div>;
}
