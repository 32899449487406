import { Tag } from 'antd';
import { StatementStatus } from '@/types';
import { useTranslation } from 'react-i18next';

type Props = {
  status: StatementStatus | undefined;
};

export default function StatementStatusTag({ status }: Props) {
  const { t } = useTranslation('statement');

  const translationKey =
    status === StatementStatus.APPROVED
      ? 'table.approved'
      : status === StatementStatus.REVIEWED
        ? 'table.reviewed'
        : 'table.in-progress';

  return (
    <>
      {status && (
        <Tag
          color={
            status === StatementStatus.APPROVED
              ? 'green'
              : status === StatementStatus.REVIEWED
                ? 'yellow'
                : 'orange'
          }
        >
          {t(translationKey)}
        </Tag>
      )}
    </>
  );
}
