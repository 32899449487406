import { Navigate } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { PropsWithChildren } from 'react';
import Loading from '../common/loading';

export function PublicRoute({ children }: PropsWithChildren) {
  const sessionContext = useSessionContext();

  if (sessionContext.loading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <Loading />
      </div>
    );
  }

  if (sessionContext.doesSessionExist) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
}
