import { formatCurrency } from '@/lib/utils';
import { Typography } from 'antd';
import SkeletonButton from 'antd/es/skeleton/Button';

type Props = {
  label: string | JSX.Element;
  value: string | number;
  loading?: boolean;
};

export default function StatementInfoTile({ label, value, loading = false }: Props) {
  return (
    <div className="flex flex-col gap-1 text-center">
      {loading ? (
        <>
          <SkeletonButton style={{ height: 20 }} active />
          <SkeletonButton style={{ height: 32, width: '90%' }} active />
        </>
      ) : (
        <>
          <Typography.Text className="text-sm text-text-tertiary'">{label}</Typography.Text>
          <Typography.Text className="text-2xl">{formatCurrency(value)}</Typography.Text>
        </>
      )}
    </div>
  );
}
