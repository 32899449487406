import { Avatar, Dropdown, Typography } from 'antd';
import DownOutlined from '@ant-design/icons/DownOutlined';
import Session from 'supertokens-auth-react/recipe/session';
import type { MenuProps } from 'antd';
import SuperTokens from 'supertokens-auth-react';
import { twMerge } from 'tailwind-merge';
import { useCurrentUserContext } from '@/components/providers/user/use-current-user-context';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;

type UserInfoProps = {
  isCollapsed: boolean;
};

export default function UserInfo({ isCollapsed }: UserInfoProps) {
  const { t } = useTranslation('common');
  const { user } = useCurrentUserContext();
  const logout = async () => {
    await Session.signOut();
    SuperTokens.redirectToAuth();
  };

  const getNameInitials = (fullName: string) => fullName?.split(' ').map(name => name.charAt(0));

  const logoutMenu: MenuProps['items'] = [
    { label: t('side-menu.logout'), key: 0, onClick: () => logout() },
  ];

  return (
    <Dropdown
      menu={{ items: logoutMenu }}
      placement="top"
      trigger={['click']}
      className="cursor-pointer"
    >
      <div className="grid grid-cols-[min-content_1fr_min-content] items-center gap-4 px-3 py-1  overflow-hidden min-h-14">
        <Avatar style={{ backgroundColor: '#000000a6' }}>{getNameInitials(user!.name)}</Avatar>
        <div className={twMerge(!isCollapsed ? 'block  ' : 'hidden ')}>
          <Text className="block font-semibold">{user!.name}</Text>
          <div className="truncate max-w-28">
            <Text type="secondary">{user!.client?.name || t('side-menu.admin')}</Text>
          </div>
        </div>
        <div className={twMerge(!isCollapsed ? 'block' : 'hidden')}>
          <DownOutlined />
        </div>
      </div>
    </Dropdown>
  );
}
