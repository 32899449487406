import { PropsWithChildren } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { useUser } from '@/lib/hooks/useUser';
import { CurrentUserContext } from './current-user.context';

export const CurrentUserProvider = ({ children }: PropsWithChildren) => {
  const sessionContext = useSessionContext();

  const session = !sessionContext.loading && sessionContext.doesSessionExist;

  const { user, isLoading, isError } = useUser(!session);

  return (
    <CurrentUserContext.Provider value={{ user, isLoading, isError }}>
      {children}
    </CurrentUserContext.Provider>
  );
};
