import { InvoicePaymentStatus } from '@/types';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  paymentStatus: InvoicePaymentStatus;
};

const getInvoiceStatus = (paymentStatus: InvoicePaymentStatus) => {
  if (paymentStatus === InvoicePaymentStatus.FULLY_PAID)
    return { color: 'green', translation: 'table.paid' };
  else if (paymentStatus === InvoicePaymentStatus.OVERDUE)
    return { color: 'orange', translation: 'table.overdue' };
  return { color: 'yellow', translation: 'table.not-paid' };
};

export default function StatementInvoiceStatus({ paymentStatus }: Props) {
  const { t } = useTranslation('invoice');

  const { color, translation } = getInvoiceStatus(paymentStatus);

  return <Tag color={color}>{t(translation)}</Tag>;
}
