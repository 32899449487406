export default function TermsOfService() {
  return (
    <div style={{ padding: 10 }}>
      <b>Terms of Service (Last Updated: February 27, 2024)</b>

      <div>
        <p>
          Welcome to Down Right Merch Customer Connector App ("the App"). These Terms of Service
          ("Terms") form a binding agreement between you ("User" or "you") and Down Right Merch
          ("we", "our", or "us") outlining the rules and conditions for using the App.
        </p>

        <b>Acceptance of Terms</b>

        <p>
          By accessing and using the App, you signify your agreement and acceptance of these Terms.
          If you do not agree, please refrain from using the App.
        </p>

        <b>Description of Service</b>

        <p>
          The App provides a service for reading your company's customer balance and invoice data
          from your connected Intuit production account. The App does not store any sensitive
          financial information beyond the intended purpose.
        </p>

        <b>User Responsibilities</b>

        <p>You are responsible for maintaining the security of your Intuit account credentials.</p>
        <p>The App should not be used for any illegal or unauthorized purposes.</p>
        <p>
          You acknowledge that the accuracy and completeness of data presented by the App are
          dependent on the data within your Intuit account.
        </p>

        <b>Intellectual Property</b>

        <p>
          The App and its content, features, and functionality are owned or licensed by Down Right
          Merch. No unauthorized use or reproduction of this intellectual property is permitted.
        </p>

        <b>Disclaimer of Warranties</b>

        <p>
          THE APP IS PROVIDED "AS IS". WE MAKE NO WARRANTIES OR GUARANTEES ABOUT THE RELIABILITY,
          ACCURACY, OR COMPLETENESS OF THE APP OR ITS SERVICES. WE ARE NOT LIABLE FOR ANY DAMAGES
          ARISING FROM THE USE OF THE APP.
        </p>

        <b>Limitation of Liability</b>

        <p>
          IN NO EVENT SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, OR CONSEQUENTIAL DAMAGES, EVEN IF
          WE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>

        <b>Indemnification</b>

        <p>
          You shall indemnify and hold us harmless against any losses or damage arising from any
          claims based on your breach of these Terms.
        </p>

        <b>Changes to Terms of Service</b>

        <p>
          We reserve the right to modify these Terms at any time. Continued use of the App after
          changes signifies your acceptance of the new Terms.
        </p>

        <b>Termination</b>

        <p>
          We may terminate your access to the App without notice for violations of these Terms or
          for any other reason at our discretion.
        </p>

        <b>Governing Law</b>

        <p>
          These Terms shall be governed and interpreted in accordance with the laws of the Essex
          County, Massachusetts.
        </p>

        <b>Dispute Resolution</b>

        <p>Any disputes arising from these Terms shall be resolved via arbitration.</p>

        <b>Contact Us</b>

        <p>
          For questions or concerns related to these Terms of Service, please contact us at
          hello@downrightmerch.com.
        </p>
      </div>
    </div>
  );
}
