import { Button } from 'antd';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: () => void;
  isLoading: boolean;
};

export default function DownloadButton({ onClick, isLoading = false }: Props) {
  const { t } = useTranslation('common');

  return (
    <Button type="link" icon={<DownloadOutlined />} onClick={onClick} loading={isLoading}>
      {t('actions.download')}
    </Button>
  );
}
