const baseApiUrl = import.meta.env.VITE_API_URL!;

export const ApiUrls = {
  SIGN_IN: `${baseApiUrl}/v1/auth/signin`,
  SIGN_UP: `${baseApiUrl}/v1/auth/signup`,
  CHANGE_PASSWORD: `${baseApiUrl}/v1/auth/change-password`,
  VERIFY_SIGN_UP_TOKEN: `${baseApiUrl}/v1/auth/verify-signup-token`,
  SEND_RESET_PASSWORD: `${baseApiUrl}/v1/auth/send-reset-password-email`,
  SEND_SIGN_UP_EMAIL: `${baseApiUrl}/v1/auth/resend-signup-email`,
  USER: `${baseApiUrl}/v1/user`,
  USERS: `${baseApiUrl}/v1/user/users`,
  ME: `${baseApiUrl}/v1/user/me`,
  CLIENT: `${baseApiUrl}/v1/client`,
  CLIENT_ADMIN: `${baseApiUrl}/v1/client/admin`,
  CLIENT_SUMMARY: `${baseApiUrl}/v1/client-summary`,
  CLIENT_STATISTICS: `${baseApiUrl}/v1/client-summary/statistics`,
  CREATE_DRM_CLIENT: `${baseApiUrl}/v1/client/drm`,
  CLIENTS: `${baseApiUrl}/v1/client/clients`,
  ORDER: `${baseApiUrl}/v1/order`,
  FAQ: `${baseApiUrl}/v1/faq`,
  FAQS: `${baseApiUrl}/v1/faq/faqs`,
  QUICKBOOKS: `${baseApiUrl}/v1/quickbooks`,
  QUICKBOOKS_AUTH: `${baseApiUrl}/v1/quickbooks/auth`,
  QUICKBOOKS_INVOICES: `${baseApiUrl}/v1/quickbooks/invoices`,
  STATEMENT: `${baseApiUrl}/v1/statement`,
  STATEMENT_DOWNLOAD: `${baseApiUrl}/v1/statement/generate-spreadsheet`,
  STATEMENT_IS_PAID: `${baseApiUrl}/v1/statement/update-statement-is-paid-flag`,
  STATEMENT_OPEN_ADVANCE: `${baseApiUrl}/v1/statement/update-open-advance`,
  STATEMENT_NOTES: `${baseApiUrl}/v1/statement/update-notes`,
  STATEMENT_HIDE_ITEM: `${baseApiUrl}/v1/statement/change-statement-items-visibility`,
  STATEMENT_HIDE_INVOICE: `${baseApiUrl}/v1/statement/change-statement-invoices-visibility`,
  STATEMENT_CHANGE_INVOICE_ORDER: `${baseApiUrl}/v1/statement/change-statement-invoices-order`,
  PRODUCT: `${baseApiUrl}/v1/product`,
  EXPENSE: `${baseApiUrl}/v1/expense`,
};

export enum PageUrls {
  HOME = '/',
  LOG_IN = '/auth',
  SIG_UP = '/auth/signup',
  RESET_PASSWORD = '/auth/reset-password',
  RESET_PASSWORD_SUCCESS = '/auth/reset-password-success',
  SUMMARY = '/summary',
  CLIENTS = '/clients',
  STATEMENTS = '/statements',
  INVOICES = '/invoices',
  SETTINGS = '/settings',
  PROFILE = '/settings/profile',
  COMPANY_PROFILE = '/settings/company-profile',
  MEMBERS = '/settings/members',
  QUICKBOOKS = '/settings/quickbooks',
  SHIPMENTS = '/shipments',
  UNSORTED_ORDERS = '/unsorted-orders',
  FAQ = '/faq',
}
