import { screenWidths } from '@/styles/constants';
import { useWindowSize } from 'react-use';

export const useMediaQuery = () => {
  const { width } = useWindowSize();

  const isDesktop = width > screenWidths.desktop;

  return isDesktop;
};
