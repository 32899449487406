import { InvoicePaymentStatus, SortOrder } from '@/types';
import { components } from '@/types/api-schema';
import useSWR, { Fetcher } from 'swr';
import { defaultHeaders, parseResponseOrThrowErr } from '../api/fetchers';
import { ApiUrls } from '@/config/urls';

export const INVOICES_PER_PAGE = 10;

type InvoicesResponse = components['schemas']['PaginatedClientInvoicesResponse'];

type InvoicesRequest = components['schemas']['GetPaginatedClientInvoicesInput'];

const DEFAULT_SORT = {
  DueDate: 'desc' as const,
};

const getInvoices: Fetcher<
  InvoicesResponse,
  {
    url: string;
    sort: InvoicesRequest['sort'];
    filter: InvoicesRequest['filter'];
  }
> = async ({ url, sort, filter }) => {
  const body: InvoicesRequest = {
    sort: sort ? { ...sort } : { ...DEFAULT_SORT },
    filter: filter,
  };

  const res = await fetch(url, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify(body) as BodyInit,
  });

  return parseResponseOrThrowErr(res);
};

export const useInvoices = (
  page: number,
  limit: number,
  sort?: string | null,
  order?: SortOrder | null,
  clientId?: string,
  status?: InvoicePaymentStatus | undefined,
) => {
  const offset = (page - 1) * limit;

  let sortObj: InvoicesRequest['sort'] | undefined;
  let filterObj: InvoicesRequest['filter'] = {};

  if (sort && order) {
    sortObj = { [sort]: order };
  }

  if (status) {
    filterObj = { ...filterObj, status };
  }

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    clientId
      ? {
          url: `${ApiUrls.QUICKBOOKS_INVOICES}/${clientId}?limit=${limit}&offset=${offset}`,
          sort: sortObj,
          filter: filterObj,
        }
      : null,
    getInvoices,
    { keepPreviousData: true },
  );

  return {
    invoices: data?.invoices,
    pagination: data?.pagination,
    mutate,
    isLoading,
    isValidating,
    isError: error,
  };
};
