import { toast } from 'react-toastify';

export const useNotify = () => {
  const notify = (message: string, type: 'success' | 'error' = 'success') => {
    toast(message, {
      type,
      position: 'top-right',
      hideProgressBar: true,
      autoClose: 3000,
    });
  };

  return {
    notify,
  };
};
