import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { InventoryStatus, OrderTransactionData, UserRole } from '@/types';
import { format } from 'date-fns';
import { tableWidths } from '@/styles/constants';
import { formatCurrency } from '@/lib/utils';
import { useCurrentUserContext } from '../providers/user/use-current-user-context';
import ViewLinkCell from '../common/view-link-cell';
import InventoryStatusCell from './inventory-status-cell';

export const TRANSACTIONS_PER_PAGE = 10;

export enum SortableTransactionsColumns {
  DESCRIPTION = 'description',
  ORDER_NUMBER = 'orderNumber',
  FULFILLMENT_DATE = 'fulfillmentDate',
  ORDER_DATE = 'orderDate',
  SKU = 'sku',
  PRODUCT_PRICE = 'productPrice',
  QUANTITY = 'qty',
  GROSS_SALE = 'grossSale',
  COGS_PER_UNIT = 'cogsPerUnit',
  TOTAL_COGS = 'totalCogs',
  DISCOUNT = 'discount',
  NET = 'net',
  COMMISSION = 'commission',
  TERRITORY = 'territory',
  INVENTORY_STATUS = 'inventoryStatus',
}

const columns: ColumnsType<OrderTransactionData> = [
  {
    title: 'product-name',
    key: 'description',
    render: (_, { description }) => <>{description}</>,
    sorter: true,
    width: tableWidths.expenseDescription,
  },
  {
    title: 'order-number',
    key: 'orderNumber',
    sorter: true,
    render: (_, { orderNumber }) => <>{orderNumber}</>,
    width: tableWidths.orderNumber,
  },
  {
    title: 'fulfillment-date',
    key: 'fulfillmentDate',
    render: (_, { fulfillmentDate }) => <>{format(new Date(fulfillmentDate), 'MM/dd/yyyy')}</>,
    sorter: true,
    width: tableWidths.date,
  },
  {
    title: 'order-date',
    key: 'orderDate',
    render: (_, { orderDate }) => <>{format(new Date(orderDate), 'MM/dd/yyyy')}</>,
    sorter: true,
    width: tableWidths.date,
  },
  {
    title: 'product-sku',
    key: 'sku',
    render: (_, { sku }) => <>{sku}</>,
    sorter: true,
    width: tableWidths.sku,
  },
  {
    title: 'product-price',
    key: 'productPrice',
    width: tableWidths.balance,
    sorter: true,
    render: (_, { productPrice }) => <>{formatCurrency(productPrice)}</>,
  },
  {
    title: 'quantity',
    key: 'qty',
    render: (_, { qty }) => <>{qty}</>,
    sorter: true,
    width: tableWidths.quantity,
  },
  {
    title: 'gross-sale',
    key: 'grossSale',
    render: (_, { grossSale }) => <>{formatCurrency(grossSale)}</>,
    sorter: true,
    width: tableWidths.balance,
  },
  {
    title: 'cogs-per-unit',
    key: 'cogsPerUnit',
    render: (_, { cogsPerUnit }) => <>{formatCurrency(cogsPerUnit)}</>,
    sorter: true,
    width: tableWidths.cogs,
  },
  {
    title: 'total-cogs',
    key: 'totalCogs',
    render: (_, { totalCogs }) => <>{formatCurrency(totalCogs)}</>,
    sorter: true,
    width: tableWidths.cogs,
  },
  {
    title: 'discount',
    key: 'discount',
    render: (_, { discount }) => <>{formatCurrency(discount)}</>,
    sorter: true,
    width: tableWidths.quantity,
  },
  {
    title: 'net',
    key: 'net',
    render: (_, { net }) => <>{formatCurrency(net)}</>,
    sorter: true,
    width: tableWidths.quantity,
  },
  {
    title: 'handling',
    key: 'handling',
    width: tableWidths.quantity,
  },
  {
    title: 'commission',
    key: 'commission',
    render: (_, { commission }) => <>{formatCurrency(commission)}</>,
    width: tableWidths.price,
    sorter: true,
  },
  {
    title: 'territory',
    key: 'territory',
    render: (_, { territory }) => <>{territory}</>,
    width: tableWidths.territory,
    sorter: true,
  },
  {
    title: 'inventory-status',
    key: 'inventoryStatus',
    render: (_, { inventoryStatus }) => (
      <InventoryStatusCell status={inventoryStatus as InventoryStatus} />
    ),
    width: tableWidths.orderNumber,
    sorter: true,
  },
  {
    title: 'actions',
    key: 'actions',
    render: (_, { orderId }) => (
      <ViewLinkCell link={`https://admin.shopify.com/store/downrightmerchinc/orders/${orderId}`} />
    ),
    width: tableWidths.actions,
  },
];

export const useTransactionsTable = (
  sort?: string | null,
  order?: string | null,
  handling?: number,
): ColumnsType<OrderTransactionData> => {
  const { t } = useTranslation('clients');
  const { user } = useCurrentUserContext();

  return columns.map(column => {
    if (column.key === `handling`) {
      return {
        ...column,
        title: t(`pending-shipments.table.${column.title}`),
        render: () => formatCurrency(handling),
      };
    }
    if (column.key === 'actions' && user?.role !== UserRole.ADMIN) {
      return { hidden: true };
    }
    if (column.key === `${sort}`) {
      return {
        ...column,
        title: t(`pending-shipments.table.${column.title}`),
        defaultSortOrder: order === 'ascend' ? ('ascend' as const) : ('descend' as const),
      };
    }
    return {
      ...column,
      title: t(`pending-shipments.table.${column.title}`),
    };
  });
};
