import { useInvoicesTable } from '../useInvoicesTable';
import { Empty, Table, TableProps } from 'antd';
import { useTablePage } from '@/lib/hooks/useTablePage';
import { useTableSort } from '@/lib/hooks/useTableSort';
import { SorterResult } from 'antd/es/table/interface';
import { INVOICES_PER_PAGE, useInvoices } from '@/lib/hooks/useInvoices';
import { StringParam, useQueryParam } from 'use-query-params';
import { Invoice, InvoicePaymentStatus, InvoiceTableDataType } from '@/types';
import ErrorComponent from '@/components/common/errorComponent';
import ResponsiveTableContainer from '@/components/common/responsiveTableContainer';
import { tableScroll } from '@/styles/constants';
import { useTranslation } from 'react-i18next';

type Props = { clientId?: string; clientName?: string };

const formatStatus = (status: string | null | undefined) => {
  if (status && Object.values(InvoicePaymentStatus).includes(status as InvoicePaymentStatus)) {
    return status as InvoicePaymentStatus;
  }
  return undefined;
};

const formatInvoices = (
  invoices: Invoice[],
  clientId: string,
  clientName: string,
): InvoiceTableDataType[] => {
  return invoices.map(invoice => ({
    ...invoice,
    key: invoice.id,
    clientId,
    clientName,
  }));
};

export default function InvoicesTable({ clientId, clientName }: Props) {
  const { t } = useTranslation('invoice');
  const { page, limit, onPageChange } = useTablePage();
  const { sort, setSort, order, orderForApi, setOrder } = useTableSort([
    'DueDate',
    'Balance',
    'TotalAmt',
  ]);

  const [status, setStatus] = useQueryParam('status', StringParam);

  const onChange: TableProps<InvoiceTableDataType>['onChange'] = (_, filters, sorter) => {
    const o = sorter as SorterResult<Invoice>;

    setStatus((filters?.paymentStatus as unknown as InvoicePaymentStatus) || null);
    setOrder(o?.order);
    setSort(o?.columnKey as string);
  };

  const formattedStatus = formatStatus(status);

  const columns = useInvoicesTable(sort, order, formattedStatus);

  const { invoices, isLoading, isError } = useInvoices(
    page,
    limit,
    sort,
    orderForApi,
    clientId,
    formattedStatus,
  );

  const formattedInvoices = formatInvoices(invoices || [], clientId || '', clientName || '');

  const total = invoices?.length || 0;

  if (isError) {
    return <ErrorComponent message={isError?.message} />;
  }

  return (
    <ResponsiveTableContainer>
      <Table
        columns={columns}
        dataSource={formattedInvoices}
        onChange={onChange}
        loading={isLoading}
        scroll={{ x: tableScroll }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('table.empty')} />,
        }}
        pagination={
          total > INVOICES_PER_PAGE
            ? {
                current: page,
                total: total,
                pageSize: limit,
                onChange: onPageChange,
                position: ['bottomLeft'],
                showSizeChanger: true,
              }
            : false
        }
      />
    </ResponsiveTableContainer>
  );
}
