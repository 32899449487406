import { ApiUrls } from '@/config/urls';
import { Fetcher } from 'swr';
import { CustomError } from '../customError';
import { components } from '@/types/api-schema';
import { defaultHeaders } from '../api/fetchers';
import useSWRImmutable from 'swr/immutable';

type UserResponse = components['schemas']['UserResponseWithClient'];

const fetcher: Fetcher<UserResponse, string> = async (url: string) => {
  const res = await fetch(url, { headers: defaultHeaders });

  if (res.status === 200 || res.status === 201) {
    return await res.json();
  }
  const info = await res.json();
  const error = new CustomError(info.message, res.status);

  throw error;
};

export const useUser = (pause: boolean = false) => {
  const { data, error, isLoading, isValidating, mutate } = useSWRImmutable(
    pause ? null : ApiUrls.ME,
    fetcher,
  );

  return {
    user: data,
    mutate,
    isLoading,
    isValidating,
    isError: error,
  };
};
