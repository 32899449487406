import { ApiUrls } from '@/config/urls';
import useSWR, { Fetcher } from 'swr';
import { CustomError } from '../customError';
import { components } from '@/types/api-schema';
import { defaultHeaders } from '../api/fetchers';
import { SortOrder, StatementStatus } from '@/types';

type StatementsResponse = components['schemas']['GetPaginatedStatementsResponse'];

type StatementsRequest = components['schemas']['GetPaginatedStatementsRequestBody'];
type StatementsAdminRequest = components['schemas']['GetPaginatedStatementsAdminRequestBody'];

export type StatementSortOptions =
  components['schemas']['GetPaginatedStatementsRequestBody']['sort'];

export const STATEMENTS_PER_PAGE = 10;

const fetcher: Fetcher<
  StatementsResponse,
  { url: string; sort: StatementsRequest['sort']; filter: StatementsRequest['filter'] }
> = async ({ url, sort, filter }) => {
  const body: StatementsRequest = {
    sort: sort ? { ...sort } : {},
    filter,
  };

  const res = await fetch(url, {
    headers: defaultHeaders,
    method: 'POST',
    body: JSON.stringify(body) as BodyInit,
  });

  if (res.status === 200 || res.status === 201) {
    return await res.json();
  }
  const info = await res.json();
  const error = new CustomError(info.message, res.status);

  throw error;
};

export const useStatements = (
  isAdmin: boolean,
  page = 1,
  limit: number,
  sort?: string | null,
  order?: SortOrder | null,
  clientId?: string | undefined | null,
  month?: number | undefined | null,
  year?: number | undefined | null,
  status?: StatementStatus | undefined,
) => {
  const offset = (page - 1) * limit;

  let sortObj: StatementsRequest['sort'] | undefined;
  let filterObj: StatementsRequest['filter'] | StatementsAdminRequest['filter'] = {};
  const url = isAdmin
    ? `${ApiUrls.STATEMENT}/admin/statements?limit=${limit}&offset=${offset}`
    : `${ApiUrls.STATEMENT}/statements?limit=${limit}&offset=${offset}`;

  if (sort && order) {
    sortObj = { [sort]: order };
  }

  if (month) {
    filterObj = { ...filterObj, month };
  }

  if (year) {
    filterObj = { ...filterObj, year };
  }

  if (status) {
    filterObj = { ...filterObj, status };
  }

  if (clientId) {
    filterObj = { ...filterObj, clientId };
  }

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    {
      url,
      sort: sortObj,
      filter: filterObj,
    },
    fetcher,
    {
      keepPreviousData: true,
    },
  );

  return {
    statements: data,
    mutate,
    isLoading,
    isValidating,
    isError: error,
  };
};
