import { Button, Empty, Table, Typography } from 'antd';
import DashboardSection from '@/components/dashboard/section';
import type { TableProps } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import Search from 'antd/es/input/Search';
import { AntSortOrder, OrderTransactionData, StatementStatus, TabsState } from '@/types';
import {
  SortableTransactionsColumns,
  useTransactionsTable,
} from '@/components/statements/useTransactionsTable';
import { ORDERS_PER_PAGE } from '@/lib/hooks/usePendingOrders';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import { useStatementStore } from '../../../store/useStatementStore';
import { compareAsc, compareDesc } from 'date-fns';
import StatementsTableTabs from '../statement-table-tabs';
import useSWRMutation from 'swr/mutation';
import { ApiUrls } from '@/config/urls';
import { createFetcher } from '@/lib/api/fetchers';
import { useNotify } from '@/lib/hooks/useNofity';
import {
  ChangeLineItemVisibilityInput,
  StatementTransactions,
  TransactionItems,
} from '@/store/types';
import ResponsiveTableContainer from '@/components/common/responsiveTableContainer';

const formatSortParam = (param: string | null | undefined) => {
  if (
    param &&
    Object.values(SortableTransactionsColumns).includes(param as SortableTransactionsColumns)
  ) {
    return param as SortableTransactionsColumns;
  }
};

const formatOrderParam = (param: string | null | undefined) => {
  if (param && Object.values(AntSortOrder).includes(param as AntSortOrder)) {
    return param as AntSortOrder;
  }
};

function sortTransactions(
  transactions: TransactionItems,
  sortField: SortableTransactionsColumns | undefined,
  order: AntSortOrder | undefined,
) {
  if (sortField && order) {
    return [...transactions].sort((a, b) => {
      if (order === AntSortOrder.ASC) {
        if (sortField === 'orderDate' || sortField === 'fulfillmentDate') {
          return compareAsc(new Date(a[sortField]), new Date(b[sortField]));
        } else if (typeof a[sortField] === 'number' && typeof b[sortField] === 'number') {
          return Number(a[sortField]) - Number(b[sortField]);
        } else if (typeof a[sortField] === 'string' && typeof b[sortField] === 'string') {
          return String(a[sortField]).localeCompare(String(b[sortField]));
        } else {
          return 0;
        }
      } else {
        if (sortField === 'orderDate' || sortField === 'fulfillmentDate') {
          return compareDesc(new Date(a[sortField]), new Date(b[sortField]));
        } else if (typeof a[sortField] === 'number' && typeof b[sortField] === 'number') {
          return Number(b[sortField]) - Number(a[sortField]);
        } else if (typeof a[sortField] === 'string' && typeof b[sortField] === 'string') {
          return String(b[sortField]).localeCompare(String(a[sortField]));
        } else {
          return 0;
        }
      }
    });
  } else {
    return transactions;
  }
}

const filterTransactions = (
  transactions: OrderTransactionData[],
  filter: string | undefined | null,
) => {
  if (!filter || typeof filter !== 'string') {
    return transactions;
  }
  return [...transactions].filter(transaction =>
    transaction.orderNumber.toLowerCase().includes(filter.toLowerCase()),
  );
};

type Props = {
  transactionType: keyof StatementTransactions;
  title: string;
  isAdmin: boolean;
};

export default function TransactionsTableSection({
  transactionType,
  title,
  isAdmin = false,
}: Props) {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { t } = useTranslation(['statement', 'errors']);
  const { notify } = useNotify();

  const state = useStatementStore();

  const {
    setPage,
    setSort,
    setSearch,
    setActiveTab,
    statement,
    loading,
    updating,
    fetchStatement,
    summary,
  } = state;

  const {
    searchTerm,
    activeTab,
    visible: visibleTransactions,
    hidden: hiddenTransactions,
  } = state[transactionType];

  const { page, items, limit, sort, order } = state[transactionType][activeTab];

  const { trigger, isMutating } = useSWRMutation(
    `${ApiUrls.STATEMENT_HIDE_ITEM}/${statement?.clientId}`,
    createFetcher<ChangeLineItemVisibilityInput>('POST'),
  );

  const onPageChange = (page: number, limit: number) => {
    if (sectionRef?.current) {
      sectionRef.current.scrollIntoView();
    }
    setPage(page, limit, transactionType, activeTab);
  };

  const onTableSearch = (searchTerm: string) => {
    setSearch(transactionType, searchTerm);
  };

  const columns = useTransactionsTable(sort, order, summary?.handling.handlingFee);

  const onChange: TableProps<OrderTransactionData>['onChange'] = (_, __, sorter) => {
    const o = sorter as SorterResult<OrderTransactionData>;

    const formattedSortColumn = formatSortParam(o?.columnKey as string);
    const formattedOrder = formatOrderParam(o.order);

    setSort(transactionType, activeTab, formattedSortColumn, formattedOrder);
  };

  const formattedTransactions = filterTransactions(
    sortTransactions(items, sort, order),
    searchTerm,
  );
  const total = formattedTransactions.length;

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onChangeVisibility = async () => {
    const formattedKeys = selectedRowKeys.map(item => {
      const [lineItemId, fulfillmentId] = item.toString().split('-');
      return {
        lineItemId: Number(lineItemId),
        fulfillmentId: Number(fulfillmentId),
        isHiddenFromStatement: activeTab === TabsState.VISIBLE ? true : false,
      };
    });

    try {
      await trigger({
        body: formattedKeys,
      });
      setSelectedRowKeys([]);
      notify(
        t(
          activeTab === TabsState.VISIBLE
            ? 'actions.line-items-hidden'
            : 'actions.line-items-revealed',
        ),
      );
      await fetchStatement(statement!.id, false);
    } catch (err) {
      let errorMessage = 'generic';
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      notify(t([`apiErrors.${errorMessage}`, 'apiErrors.generic'], { ns: 'errors' }), 'error');
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div ref={sectionRef}>
      <DashboardSection>
        <Typography.Title level={4} className="!m-0">
          {title}
        </Typography.Title>

        <div className="grid items-center md:grid-cols-2 lg:grid-cols-3">
          <Search
            defaultValue={searchTerm || ''}
            placeholder={t('search.placeholder')}
            onSearch={onTableSearch}
            enterButton
            allowClear
          />
          <div />
          {hasSelected && (
            <div className="flex items-center gap-2 justify-self-end">
              <Typography.Text className="font-medium">
                {selectedRowKeys.length} {t('statement-selected')}
              </Typography.Text>
              <Button
                type="primary"
                onClick={onChangeVisibility}
                loading={isMutating}
                disabled={isMutating}
              >
                {activeTab === TabsState.VISIBLE
                  ? t('actions.hide-transaction', { count: selectedRowKeys.length })
                  : t('actions.reveal-transaction', { count: selectedRowKeys.length })}
              </Button>
            </div>
          )}
        </div>
        <ResponsiveTableContainer inDashboardSection={true}>
          {isAdmin && (
            <StatementsTableTabs
              activeTab={activeTab}
              setActiveTab={(val: TabsState) => {
                setActiveTab(val, transactionType);
                setSelectedRowKeys([]);
              }}
              visibleLabel={t('table.visible-transactions', {
                noOfTransactions: visibleTransactions.items.length,
              })}
              hiddenLabel={t('table.hidden-transactions', {
                noOfTransactions: hiddenTransactions.items.length,
              })}
            />
          )}

          <Table
            rowSelection={
              statement?.status === StatementStatus.IN_PROGRESS ? rowSelection : undefined
            }
            columns={columns}
            dataSource={formattedTransactions}
            onChange={onChange}
            rowKey={values => `${values.id}-${values.fulfillmentId}`}
            loading={loading || updating}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('table.transactions-empty')}
                />
              ),
            }}
            scroll={{ x: 3000 }}
            pagination={
              total > ORDERS_PER_PAGE
                ? {
                    current: page,
                    total: total,
                    pageSize: limit,
                    onChange: onPageChange,
                    position: ['bottomLeft'],
                    showSizeChanger: true,
                  }
                : false
            }
          />
        </ResponsiveTableContainer>
      </DashboardSection>
    </div>
  );
}
