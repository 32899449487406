import SideMenu from '@/components/layout/side-menu';
import { ProtectedRoute } from '@/components/auth/protectedRoute';
import Footer from '@/components/layout/footer';
import { Outlet } from 'react-router-dom';
import { dashboardContainerPadding } from '@/styles/constants';
import { useMediaQuery } from '@/lib/hooks/useMediaQuery';

export default function Layout() {
  const isDesktop = useMediaQuery();

  return (
    <ProtectedRoute>
      <div>
        <div className="grid grid-cols-[min-content_1fr] h-screen">
          <SideMenu />
          <div
            className={`grid grid-rows-[1fr_min-content] bg-main-background`}
            style={{
              padding: isDesktop
                ? dashboardContainerPadding.desktop
                : dashboardContainerPadding.mobile,
            }}
          >
            <Outlet />
            <Footer />
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
}
