import { useStatementStore } from '@/store/useStatementStore';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import PopoverContent from './popover-content';

export default function BalancePopover() {
  const { t } = useTranslation('statement');
  const { summary } = useStatementStore();

  const invoiceBalanceAfterAppliedRoyalties = summary?.invoiceBalanceAfterAppliedRoyalties;
  const invoiceBalanceTotal = summary?.invoiceBalanceTotal;

  return (
    <>
      {invoiceBalanceAfterAppliedRoyalties != null &&
      invoiceBalanceAfterAppliedRoyalties != invoiceBalanceTotal ? (
        <Popover content={<PopoverContent />}>
          <div className="flex items-center justify-center gap-1">
            {t('total-open-invoices')}
            <QuestionCircleOutlined className="mt-0.5" />
          </div>
        </Popover>
      ) : (
        <>{t('total-open-invoices')}</>
      )}
    </>
  );
}
