import { PageUrls } from '@/config/urls';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LogoutButton from '../auth/logoutButton';

type Props = {
  message: string;
  withLogout?: boolean;
};

export default function ErrorComponent({ message, withLogout = false }: Props) {
  const { t } = useTranslation(['common', 'errors']);
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center w-full h-full">
      <Result
        status="500"
        subTitle={t([`apiErrors.${message}`, 'apiErrors.generic'], {
          ns: 'errors',
        })}
        extra={
          !withLogout ? (
            <Button type="primary" onClick={() => navigate(PageUrls.HOME)}>
              {t('error.back-home')}
            </Button>
          ) : (
            <LogoutButton />
          )
        }
      />
    </div>
  );
}
