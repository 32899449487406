import { useCurrentUserContext } from '../providers/user/use-current-user-context';
import { UserRole } from '@/types';
import { Navigate } from 'react-router-dom';

export function AdminRoute({ children }: { children: React.ReactNode }) {
  const { user } = useCurrentUserContext();

  if (user?.role !== UserRole.ADMIN) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
}
