import ErrorComponent from '@/components/common/errorComponent';
import Loading from '@/components/common/loading';
import DashboardHeader from '@/components/dashboard/header';
import { useClient } from '@/lib/hooks/useClient';
import { Client, ClientStatus } from '@/types';
import { Outlet, useParams, useOutletContext } from 'react-router-dom';
import ClientStatusTag from '../client-status-tag';
import { useTranslation } from 'react-i18next';

type ContextType = {
  client: Client;
};

export default function LayoutClients() {
  const params = useParams();
  const { t } = useTranslation('common');

  const clientId = params?.id;

  const { client, isLoading, isError } = useClient(clientId);

  const links = [
    {
      href: `/clients/${clientId}/summary`,
      label: t('admin-clients-nav.summary'),
    },
    {
      href: `/clients/${clientId}/pending-shipments`,
      label: t('admin-clients-nav.shipments'),
    },
    {
      href: `/clients/${clientId}/statements`,
      label: t('admin-clients-nav.statements'),
    },
    {
      href: `/clients/${clientId}/invoices`,
      label: t('admin-clients-nav.invoices'),
    },
    {
      href: `/clients/${clientId}/products`,
      label: 'Products',
    },
    {
      href: `/clients/${clientId}/profile`,
      label: t('admin-clients-nav.profile'),
    },
    {
      href: `/clients/${clientId}/members`,
      label: t('admin-clients-nav.members'),
    },
  ];

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !client) {
    return <ErrorComponent message={isError?.message} />;
  }

  const tag =
    client.status === ClientStatus.INACTIVE ? (
      <ClientStatusTag status={ClientStatus.INACTIVE} />
    ) : undefined;

  return (
    <div className="grid grid-rows-[min-content_1fr] h-full">
      <DashboardHeader links={links} title={client.name} tag={tag} />
      <div>
        <Outlet
          context={{
            client,
          }}
        />
      </div>
    </div>
  );
}

// eslint-disable-next-line
export const useCurrentClient = () => {
  return useOutletContext<ContextType>();
};
