import DashboardHeader from '@/components/dashboard/header';
import { useCurrentUserContext } from '@/components/providers/user/use-current-user-context';
import PendingShipmentsComponent from '@/components/shipments/pending-shipments-component';
import { useTranslation } from 'react-i18next';

export default function PendingShipments() {
  const { t } = useTranslation('common');
  const { user } = useCurrentUserContext();

  const clientId = user?.clientId;

  return (
    <div className="grid grid-rows-[min-content_1fr] h-full">
      <DashboardHeader title={t('side-menu.navigation.pending-shipments')} />
      <div>
        <PendingShipmentsComponent clientId={clientId} />
      </div>
    </div>
  );
}
