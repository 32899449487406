import { useRef, useState } from 'react';
import { z } from 'zod';
import { Button, Form, Input, Modal, Typography } from 'antd';
import { components } from '@/types/api-schema';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';
import { createFetcher } from '@/lib/api/fetchers';
import { ApiUrls } from '@/config/urls';
import ErrorNotification from '@/components/form/error-notification';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { useNotify } from '@/lib/hooks/useNofity';
import FormItemWrapper from '@/components/form/form-item-wrapper';
import { formatVendorName } from '@/lib/utils';

const { Text } = Typography;

type CreateDRMClientInputType = components['schemas']['CreateDRMClientInput'];

type CreateDRMClientForm = Pick<
  components['schemas']['CreateDRMClientInput'],
  'name' | 'shopifyVendor'
>;

const createClientFormSchema: z.ZodType<CreateDRMClientForm> = z
  .object({
    name: z.string({ required_error: 'common.required-field' }),
    shopifyVendor: z.string({ required_error: 'common.required-field' }),
  })
  .strict();

export type CreateClientFormSchema = z.infer<typeof createClientFormSchema>;

type Props = {
  onSuccess: () => void;
};

export default function AddClientModal({ onSuccess }: Props) {
  const { t } = useTranslation(['clients', 'common', 'errors']);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { notify } = useNotify();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    trigger,
    error,
    isMutating,
    reset: resetMutation,
  } = useSWRMutation(ApiUrls.CREATE_DRM_CLIENT, createFetcher<CreateDRMClientInputType>('POST'));

  const { control, handleSubmit, reset, getValues } = useForm<CreateClientFormSchema>({
    resolver: zodResolver(createClientFormSchema),
  });

  const onSubmit: SubmitHandler<CreateClientFormSchema> = async data => {
    try {
      await trigger({
        body: {
          name: data.name,
          shopifyVendor: data.shopifyVendor,
        },
      });
      setIsModalOpen(false);
      onSuccess();
      reset();
      notify(t('add-modal.notification-success'));
    } catch (err) {
      // error is handled by swr hook
    }
  };

  const submitForm = () => {
    buttonRef.current?.click();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    submitForm();
  };

  const handleCancel = () => {
    reset();
    resetMutation();
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal} className="w-full">
        {t('add-modal.title')}
      </Button>
      <Modal
        maskClosable={false}
        title={t('add-modal.title')}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: isMutating ? true : false }}
        cancelText={t('actions.cancel', { ns: 'common' })}
        okText={t('actions.submit', { ns: 'common' })}
      >
        <div className="py-4">
          {error && (
            <ErrorNotification
              text={t([`apiErrors.${error?.message}`, 'apiErrors.generic'], {
                ns: 'errors',
              })}
            />
          )}
        </div>
        <form className="pb-4" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            render={({ field, formState: { errors } }) => (
              <FormItemWrapper
                hasError={!!errors.name}
                errorMessage={errors.name?.message && t(errors.name.message, { ns: 'errors' })}
                label={t('table.name')}
              >
                <Input {...field} />
              </FormItemWrapper>
            )}
          />
          <Controller
            name="shopifyVendor"
            control={control}
            render={({ field, formState: { errors } }) => (
              <>
                <Form.Item
                  validateStatus={errors.shopifyVendor ? 'error' : 'success'}
                  help={
                    errors.shopifyVendor?.message &&
                    t(errors.shopifyVendor.message, { ns: 'errors' })
                  }
                >
                  <label className="flex flex-col gap-1">
                    <Text type="secondary">{t('table.shopify-vendor-name')}</Text>
                    <Input {...field} />
                    {getValues('shopifyVendor') && (
                      <Text type="warning">
                        {t('add-modal.shopify-vendor-stored-as')}
                        {': '}
                        {formatVendorName(getValues('shopifyVendor'))}
                      </Text>
                    )}
                  </label>
                </Form.Item>
              </>
            )}
          />
          <button type="submit" className="hidden" ref={buttonRef}></button>
        </form>
      </Modal>
    </>
  );
}
