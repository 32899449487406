import { useClients } from '@/lib/hooks/useClients';
import { useDebounce } from 'react-use';
import { useMonthsAndYearsOptions } from '@/lib/hooks/useMonthsAndYearsOptions';
import { useStatements } from '@/lib/hooks/useStatements';
import { Client, StatementStatus } from '@/types';
import { Select } from 'antd';
import { useState } from 'react';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { useTablePage } from '@/lib/hooks/useTablePage';
import { useTableSort } from '@/lib/hooks/useTableSort';
import StatementsTable from '../statements-table';
import ResponsiveTableContainer from '@/components/common/responsiveTableContainer';
import ErrorComponent from '@/components/common/errorComponent';
import StatementDateFilters from '../statement-date-filters';

const statusDropdownOptions = [
  { value: StatementStatus.APPROVED, labelKey: 'table.approved' },
  { value: StatementStatus.REVIEWED, labelKey: 'table.reviewed' },
  { value: StatementStatus.IN_PROGRESS, labelKey: 'table.in-progress' },
];

const formatClients = (clients: Client[]) =>
  clients.map(client => ({ value: client.id, label: client.name }));

const formatStatus = (status: string | null | undefined) => {
  if (status && Object.values(StatementStatus).includes(status as StatementStatus)) {
    return status as StatementStatus;
  }
  return undefined;
};

type Props = {
  isSingleClientView: boolean;
  clientId?: string;
  isAdmin?: boolean;
};

export default function StatementsTableSection({
  isSingleClientView,
  clientId,
  isAdmin = false,
}: Props) {
  const { t } = useTranslation('statement');

  const [query, setQuery] = useQueryParams({
    clientId: StringParam,
    status: StringParam,
    year: NumberParam,
    month: NumberParam,
  });

  const { page, limit, setPage } = useTablePage();

  const { sort, orderForApi } = useTableSort(['approvalDate']);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
  const [selectedDateFilter, setSelectedDateFilter] = useState<
    { month: number; year: number } | undefined
  >();

  useDebounce(
    () => {
      setDebouncedSearchTerm(searchTerm);
    },
    200,
    [searchTerm],
  );

  const { monthsOptions, yearsOptions } = useMonthsAndYearsOptions();

  const onDropdownChange = (
    value: string | number,
    name: 'clientId' | 'status' | 'month' | 'year',
  ) => {
    setQuery({ ...query, [name]: value });
    setPage(1);
  };

  const formattedStatus = isAdmin ? formatStatus(query.status) : StatementStatus.APPROVED;
  const formattedClientId = clientId || query.clientId;

  const { clients, isLoading: isLoadingClients } = useClients(
    1,
    100,
    null,
    null,
    null,
    null,
    debouncedSearchTerm,
    !(isAdmin && !clientId),
  );

  const { statements, isError, isLoading } = useStatements(
    isAdmin,
    page,
    limit,
    sort,
    orderForApi,
    formattedClientId,
    Number(query.month),
    Number(query.year),
    formattedStatus,
  );

  const clientsOptions = clients ? [...formatClients(clients)] : [];

  const formattedStatements = statements?.statements || [];

  async function onDateFilterClick(selected: { month: number; year: number }) {
    const isAlreadySelected =
      selectedDateFilter?.month === selected.month && selectedDateFilter.year === selected.year;
    if (isAlreadySelected) {
      setSelectedDateFilter(undefined);
      setQuery({ ...query, year: undefined, month: undefined });
    } else {
      setSelectedDateFilter(selected);
      setQuery({ ...query, year: selected.year, month: selected.month });
    }
    setPage(1);
  }

  if (isError) {
    return <ErrorComponent message={isError?.message} />;
  }

  return (
    <>
      <div
        className={twMerge(
          'grid  gap-2 mb-6',
          isAdmin && !clientId
            ? 'md:grid-cols-[1.5fr_1fr_1fr_1fr] lg:grid-cols-[1.5fr_1fr_1fr_1fr_2fr]'
            : 'md:grid-cols-[1.5fr_1fr_1fr] lg:grid-cols-[1fr_1fr_1fr_2fr]',
        )}
      >
        {isAdmin && !clientId && (
          <Select
            showSearch
            allowClear
            filterOption={false}
            placeholder={t('dropdowns.client-default')}
            value={
              formattedStatements.find(statement => statement.clientId === query.clientId)
                ?.clientName || undefined
            }
            onChange={value => onDropdownChange(value, 'clientId')}
            onSearch={value => setSearchTerm(value)}
            options={clientsOptions}
            loading={isLoadingClients}
          />
        )}
        {isAdmin && (
          <Select
            allowClear
            placeholder={t('dropdowns.status-default')}
            defaultValue={
              statusDropdownOptions.find(option => option.value === query?.status)?.value ||
              undefined
            }
            onChange={value => onDropdownChange(value, 'status')}
            options={statusDropdownOptions.map(({ value, labelKey }) => ({
              value,
              label: t(labelKey),
            }))}
          />
        )}
        <Select
          allowClear
          placeholder={t('dropdowns.years-default')}
          defaultValue={
            yearsOptions.find(option => option.value === query?.year)?.value || undefined
          }
          onChange={value => onDropdownChange(value, 'year')}
          options={yearsOptions}
        />
        <Select
          allowClear
          placeholder={t('dropdowns.months-default')}
          defaultValue={
            monthsOptions.find(option => option.value === query?.month)?.value || undefined
          }
          onChange={value => onDropdownChange(value, 'month')}
          options={monthsOptions}
        />
      </div>

      {isAdmin && (
        <StatementDateFilters
          onDateFilterClick={onDateFilterClick}
          selectedDateFilter={selectedDateFilter}
          isLoading={isLoading}
        />
      )}

      <ResponsiveTableContainer>
        <StatementsTable
          clientId={formattedClientId}
          status={formattedStatus}
          year={String(query?.year)}
          month={String(query?.month)}
          isAdmin={isAdmin}
          isSingleClientView={isSingleClientView}
        />
      </ResponsiveTableContainer>
    </>
  );
}
