import { Form, Typography } from 'antd';
import { PropsWithChildren } from 'react';

const { Text } = Typography;

type Props = {
  hasError?: boolean;
  errorMessage?: string;
  label: string;
};

export default function FormItemWrapper({
  hasError,
  errorMessage,
  label,
  children,
}: PropsWithChildren<Props>) {
  return (
    <Form.Item validateStatus={hasError ? 'error' : 'success'} help={errorMessage}>
      <label className="flex flex-col gap-1">
        <Text type="secondary" className="text-sm font-medium">
          {label}
        </Text>
        {children}
      </label>
    </Form.Item>
  );
}
