import { STATEMENTS_PER_PAGE, useStatements } from '@/lib/hooks/useStatements';
import { Statement, StatementStatus } from '@/types';
import { Empty, Table, TableProps } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useStatementsTable } from '../useStatementsTable';
import { useTableSort } from '@/lib/hooks/useTableSort';
import { useTablePage } from '@/lib/hooks/useTablePage';
import { useTranslation } from 'react-i18next';

type Props = {
  clientId?: string | null;
  status?: StatementStatus;
  year?: string | null;
  month?: string | null;
  isSingleClientView?: boolean;
  isAdmin: boolean;
};

export default function StatementsTable({
  clientId,
  month,
  year,
  status,
  isSingleClientView,
  isAdmin,
}: Props) {
  const { t } = useTranslation('statement');
  const { page, limit, onPageChange } = useTablePage();

  const { sort, setSort, order, orderForApi, setOrder } = useTableSort(['approvalDate', 'period']);

  const { statements, isLoading: isLoadingStatements } = useStatements(
    isAdmin,
    page,
    limit,
    sort,
    orderForApi,
    clientId,
    Number(month),
    Number(year),
    status,
  );

  const columns = useStatementsTable(isAdmin, sort, order, isSingleClientView);

  const onChange: TableProps<Statement>['onChange'] = (_, __, sorter) => {
    const o = sorter as SorterResult<Statement>;

    setOrder(o?.order);
    setSort(o?.columnKey as string);
  };

  const total = statements?.pagination.count || 0;

  const formattedStatements = statements?.statements || [];

  return (
    <Table
      columns={columns}
      dataSource={formattedStatements}
      onChange={onChange}
      loading={isLoadingStatements}
      rowKey={'id'}
      scroll={{ x: isSingleClientView || (!isSingleClientView && !isAdmin) ? 1000 : 4000 }}
      locale={{
        emptyText: (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('table.statements-empty')} />
        ),
      }}
      pagination={
        total > STATEMENTS_PER_PAGE
          ? {
              current: page,
              total: total,
              pageSize: limit,
              onChange: onPageChange,
              position: ['bottomLeft'],
              showSizeChanger: true,
            }
          : false
      }
    />
  );
}
