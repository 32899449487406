import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

type NavLinkProps = {
  href: string;
  labelKey: string;
  icon: JSX.Element;
  isCollapsed: boolean;
};

export default function NavLink({ href, icon, isCollapsed, labelKey }: NavLinkProps) {
  const { t } = useTranslation('common');
  const location = useLocation();

  const locationSegment = location.pathname.split('/')[1];
  const hrefSegment = href.split('/')[1]?.split('?')[0];

  const isActive = locationSegment === hrefSegment;

  return (
    <Link
      to={href}
      className={twMerge(
        'flex gap-2  py-2 align-middle  no-underline',
        isActive ? 'bg-[#E6F4FF] rounded-md text-[#002C8C]' : 'text-text-primary',
        isCollapsed ? 'px-5' : 'px-6',
      )}
    >
      {icon}
      <span
        className={twMerge(
          'overflow-hidden duration-150 line-clamp-1',
          isCollapsed ? 'opacity-0 ' : 'opacity-100',
        )}
      >
        {t(labelKey)}
      </span>
    </Link>
  );
}
