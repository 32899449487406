import { ConfigProvider } from 'antd';

const NavLinksThemeProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <ConfigProvider
      theme={{
        token: { colorBorderSecondary: 'transparent', margin: -4 },
        components: {
          Tabs: {
            cardBg: 'transparent',
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default NavLinksThemeProvider;
