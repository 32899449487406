import { Outlet } from 'react-router-dom';

type Props = {
  withImage?: boolean;
};

export default function AuthContainerLayout({ withImage }: Props) {
  return (
    <div className="flex items-center justify-center flex-1 md:p-4 lg:p-8 ">
      <>
        {withImage ? (
          <div className="flex align-middle max-w-[58rem] w-full justify-end bg-white">
            <div className="grid items-stretch gap-0 md:grid-cols-[1fr_1.5fr] lg:grid-cols-2 w-full ">
              <div className="bg-[url('../assets/login-image.jpg')] bg-cover bg-center " />
              <div className="flex items-center  md:min-h-[38rem] justify-center ">
                <Outlet />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex align-middle bg-white">
            <div>
              <Outlet />
            </div>
          </div>
        )}
      </>
    </div>
  );
}
