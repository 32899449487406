import ErrorNotification from '@/components/form/error-notification';
import { ApiUrls } from '@/config/urls';
import { createFetcher } from '@/lib/api/fetchers';
import { useNotify } from '@/lib/hooks/useNofity';
import { useStatementStore } from '@/store/useStatementStore';
import { StatementStatus } from '@/types';
import { Button, Modal, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWRMutation from 'swr/mutation';

type Props = {
  status: StatementStatus;
  id: string;
};

export function StatementsActions({ id }: Props) {
  const { t } = useTranslation(['statement', 'errors']);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const { updateStatement, statement } = useStatementStore();
  const { notify } = useNotify();

  const { trigger: triggerReviewStatement, isMutating: reviewMutating } = useSWRMutation(
    `${ApiUrls.STATEMENT}/${id}/review`,
    createFetcher('POST'),
  );

  const {
    trigger: triggerApproveStatement,
    error: approveError,
    isMutating: approveMutating,
  } = useSWRMutation(`${ApiUrls.STATEMENT}/${id}/approve`, createFetcher('POST'));

  const {
    trigger: triggerRejectStatement,
    error: rejectError,
    isMutating: rejectMutating,
  } = useSWRMutation(`${ApiUrls.STATEMENT}/${id}/reject`, createFetcher('POST'));

  const reviewStatement = async () => {
    try {
      const statement = await triggerReviewStatement({});
      updateStatement(statement);
      notify(t('statement-reviewed'));
    } catch (err) {
      if (err instanceof Error) {
        notify(
          t([`apiErrors.${err?.message}`, 'apiErrors.generic'], {
            ns: 'errors',
          }),
          'error',
        );
      }
    }
  };

  const approveStatement = async () => {
    try {
      const statement = await triggerApproveStatement({});
      updateStatement(statement);
      setIsApproveModalOpen(false);
    } catch (err) {
      // swr handles error
    }
  };

  const rejectStatement = async () => {
    try {
      const statement = await triggerRejectStatement({});
      updateStatement(statement);
      setIsRejectModalOpen(false);
    } catch (err) {
      // swr handles error
    }
  };

  return (
    <div className="flex flex-col gap-2 sm:flex-row">
      {statement?.status === StatementStatus.REVIEWED && (
        <>
          <Modal
            title={t('approve-modal.title')}
            open={isApproveModalOpen}
            onOk={approveStatement}
            onCancel={() => setIsApproveModalOpen(false)}
            okButtonProps={{ loading: approveMutating ? true : false }}
            cancelText={t('actions.cancel', { ns: 'common' })}
            okText={t('approve-statement')}
          >
            <div className="py-4">
              {approveError && (
                <ErrorNotification
                  text={t([`apiErrors.${approveError?.message}`, 'apiErrors.generic'], {
                    ns: 'errors',
                  })}
                />
              )}
              <Typography.Text>{t('approve-modal.text')}</Typography.Text>
            </div>
          </Modal>

          <Button onClick={() => setIsApproveModalOpen(true)} type="primary">
            {t('approve-statement')}
          </Button>
        </>
      )}
      {statement?.status === StatementStatus.IN_PROGRESS && (
        <Button type="primary" onClick={reviewStatement} loading={reviewMutating}>
          {t('review-statement')}
        </Button>
      )}
      {statement?.status !== StatementStatus.IN_PROGRESS && (
        <>
          <Button onClick={() => setIsRejectModalOpen(true)}> {t('reject-statement')}</Button>
          <Modal
            title={t('reject-modal.title')}
            open={isRejectModalOpen}
            onOk={rejectStatement}
            onCancel={() => setIsRejectModalOpen(false)}
            okButtonProps={{ loading: rejectMutating ? true : false }}
            cancelText={t('actions.cancel', { ns: 'common' })}
            okText={t('reject-statement')}
          >
            <div className="py-4">
              {rejectError && (
                <ErrorNotification
                  text={t([`apiErrors.${rejectError?.message}`, 'apiErrors.generic'], {
                    ns: 'errors',
                  })}
                />
              )}
              <Typography.Text>{t('reject-modal.text')}</Typography.Text>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
}
