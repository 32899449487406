import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button, Empty, Table, Typography } from 'antd';
import DashboardSection from '@/components/dashboard/section';
import { useTranslation } from 'react-i18next';
import React, { useRef, useState } from 'react';
import {
  StatementInvoice,
  StatementInvoiceTableDataType,
  useStatementInvoiceTable,
} from '../useStatmentsInvoicesTable';
import { useStatementStore } from '../../../store/useStatementStore';
import StatementsTableTabs from '../statement-table-tabs';
import useSWRMutation from 'swr/mutation';
import { ApiUrls } from '@/config/urls';
import { createFetcher } from '@/lib/api/fetchers';
import { StatementStatus, TabsState } from '@/types';
import { useNotify } from '@/lib/hooks/useNofity';
import {
  ChangeInvoicesOrderInput,
  ChangeInvoiceVisibilityInput,
  InvoiceItems,
} from '@/store/types';
import ResponsiveTableContainer from '@/components/common/responsiveTableContainer';
import StatementInvoiceDraggableRow from '../statment-invoice-draggable-row';

const INVOICES_PER_PAGE = 5;

type Props = {
  isAdmin: boolean;
};

const formatInvoices = (
  invoices: StatementInvoice[],
  clientId: string,
  clientName: string,
): StatementInvoiceTableDataType[] => {
  return invoices.map(invoice => ({
    ...invoice,
    key: invoice.id,
    clientId,
    clientName,
  }));
};

export default function StatementInvoicesTable({ isAdmin = false }: Props) {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [isOrdering, setIsOrdering] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { t } = useTranslation(['statement', 'common', 'errors']);
  const { notify } = useNotify();

  const {
    invoices,
    setActiveInvoices,
    setActiveTab,
    setPage,
    statement,
    updating,
    loading,
    fetchStatement,
    summary,
    updateStatement,
  } = useStatementStore();

  const { activeTab } = invoices;

  const formattedInvoices = formatInvoices(
    invoices[activeTab].items,
    statement!.clientId,
    summary!.accountName,
  );

  const columns = useStatementInvoiceTable(isOrdering, activeTab);

  const total = formattedInvoices.length;

  const onPageChange = (page: number, limit: number) => {
    if (sectionRef?.current) {
      sectionRef.current.scrollIntoView();
    }
    setPage(page, limit, 'invoices', activeTab);
  };

  const { trigger, isMutating } = useSWRMutation(
    `${ApiUrls.STATEMENT_HIDE_INVOICE}/${statement?.id}`,
    createFetcher<ChangeInvoiceVisibilityInput>('POST'),
  );

  const { trigger: changeInvoicesOrder, isMutating: isChangingOrder } = useSWRMutation(
    `${ApiUrls.STATEMENT_CHANGE_INVOICE_ORDER}/${statement?.id}`,
    createFetcher<ChangeInvoicesOrderInput>('POST'),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (over?.id && active.id !== over.id) {
      const invoicesBeforeOrdering = [...invoices.visible.items];
      const activeIndex = invoicesBeforeOrdering.findIndex(item => item.id === active.id);
      const overIndex = invoicesBeforeOrdering.findIndex(item => item.id === over.id);

      const orderedInvoices = arrayMove(invoicesBeforeOrdering, activeIndex, overIndex);
      setActiveInvoices(orderedInvoices);
      onChangeOrder(orderedInvoices, invoicesBeforeOrdering);
    }
  };

  const startOrdering = () => {
    setIsOrdering(true);
  };

  const endOrdering = () => {
    setIsOrdering(false);
  };

  const changeTabState = (val: TabsState) => {
    if (isOrdering) {
      setIsOrdering(false);
    }
    setActiveTab(val, 'invoices');
  };

  const onChangeOrder = async (orderedInvoices: InvoiceItems, invoicesBefore: InvoiceItems) => {
    const orderedInvoiceIds = orderedInvoices.map(invoice => invoice.id);
    try {
      const res = await changeInvoicesOrder({ body: orderedInvoiceIds });
      notify(t('actions.invoice-order-updated'));
      updateStatement(res);
    } catch (err) {
      setActiveInvoices(invoicesBefore);
      let errorMessage = 'generic';
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      notify(t([`apiErrors.${errorMessage}`, 'apiErrors.generic'], { ns: 'errors' }), 'error');
    }
  };

  const onChangeVisibility = async () => {
    const formattedKeys = selectedRowKeys.map(item => {
      return {
        id: item as string,
        isHiddenFromStatement: activeTab === TabsState.VISIBLE ? true : false,
      };
    });

    try {
      await trigger({
        body: formattedKeys,
      });
      setSelectedRowKeys([]);
      notify(
        t(activeTab === TabsState.VISIBLE ? 'actions.invoice-hidden' : 'actions.invoice-revealed'),
      );
      await fetchStatement(statement!.id, false);
    } catch (err) {
      let errorMessage = 'generic';
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      notify(t([`apiErrors.${errorMessage}`, 'apiErrors.generic'], { ns: 'errors' }), 'error');
    }
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div ref={sectionRef}>
      <DashboardSection>
        <div className="flex justify-between">
          <Typography.Title level={4} className="!m-0 !mb-1">
            {t('invoices')}
          </Typography.Title>

          {hasSelected && (
            <div className="flex items-center gap-2 justify-self-end">
              <Typography.Text className="font-medium">
                {selectedRowKeys.length} {t('statement-selected')}
              </Typography.Text>
              <Button
                type="primary"
                onClick={onChangeVisibility}
                loading={isMutating}
                disabled={isMutating}
              >
                {activeTab === TabsState.VISIBLE
                  ? t('actions.hide-invoice', { count: selectedRowKeys.length })
                  : t('actions.reveal-invoice', { count: selectedRowKeys.length })}
              </Button>
            </div>
          )}

          {isAdmin && activeTab === TabsState.VISIBLE && !hasSelected ? (
            isOrdering ? (
              <div className="flex gap-2">
                <Button type="primary" onClick={endOrdering}>
                  {t('actions.done', { ns: 'common' })}
                </Button>
              </div>
            ) : (
              <Button
                onClick={startOrdering}
                disabled={statement?.status !== StatementStatus.IN_PROGRESS}
              >
                {t('change-invoices-order')}
              </Button>
            )
          ) : null}
        </div>
        <ResponsiveTableContainer inDashboardSection={true}>
          {isAdmin && (
            <StatementsTableTabs
              activeTab={activeTab}
              setActiveTab={changeTabState}
              visibleLabel={t('table.visible-invoices', {
                noOfInvoices: invoices.visible.items.length,
              })}
              hiddenLabel={t('table.hidden-invoices', {
                noOfInvoices: invoices.hidden.items.length,
              })}
            />
          )}
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
              items={formattedInvoices.map(invoice => invoice.key)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                rowSelection={
                  statement?.status === StatementStatus.IN_PROGRESS && !isOrdering
                    ? rowSelection
                    : undefined
                }
                components={{
                  body: {
                    row: StatementInvoiceDraggableRow,
                  },
                }}
                columns={columns}
                dataSource={formattedInvoices}
                scroll={{ x: 1500 }}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={t('table.invoices-empty')}
                    />
                  ),
                }}
                rowKey="id"
                pagination={
                  total > INVOICES_PER_PAGE
                    ? {
                        current: invoices[activeTab].page,
                        total: total,
                        pageSize: invoices[activeTab].limit,
                        onChange: onPageChange,
                        position: ['bottomLeft'],
                        showSizeChanger: true,
                      }
                    : false
                }
                loading={loading || updating || isChangingOrder}
              />
            </SortableContext>
          </DndContext>
        </ResponsiveTableContainer>
      </DashboardSection>
    </div>
  );
}
