import { useTranslation } from 'react-i18next';

type Props = {
  isDRMMallClient: boolean;
};

export default function DRMCell({ isDRMMallClient }: Props) {
  const { t } = useTranslation('clients');

  const translationKey = isDRMMallClient ? 'table.yes' : 'table.no';
  return <>{t(translationKey)}</>;
}
