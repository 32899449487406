import { PageUrls } from '@/config/urls';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  return (
    <div className="flex items-center justify-center w-full h-full">
      <Result
        status="404"
        title="404"
        subTitle={t('page-not-found')}
        extra={
          <Button type="primary" onClick={() => navigate(PageUrls.HOME)}>
            {t('error.back-home')}
          </Button>
        }
      />
    </div>
  );
}
