import { calculateCommissions } from '@/lib/utils';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  royaltyRate: string;
  royaltyRateMedia: string;
  royaltyRateDigital: string;
};

export default function ClientRatesCell({
  royaltyRate,
  royaltyRateMedia,
  royaltyRateDigital,
}: Props) {
  const { t } = useTranslation('statement');

  const commissions = calculateCommissions({
    commissionRateMerch: Number(royaltyRate),
    commissionRateDigital: Number(royaltyRateMedia),
    commissionRateMedia: Number(royaltyRateDigital),
  });

  return <Popover content={t('transactions-popover')}>{commissions}</Popover>;
}
