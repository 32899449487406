export default function PrivacyPolicy() {
  return (
    <div style={{ padding: 10 }}>
      <b>Privacy Policy (Last Updated: February 27, 2024)</b>

      <div>
        <p>
          This Privacy Policy ("Policy") outlines how Down Right Merch Customer Connector App ("the
          App") collects, uses, and protects information obtained when connecting to your Intuit
          production account.
        </p>

        <b>Understanding Our Purpose</b>

        <p>
          The App is designed to help you easily access and view your company's customer balance and
          invoice data directly from your Intuit production account.
        </p>

        <b>Information Collection</b>

        <p>
          <b>Information We Collect:</b> The App will collect your Intuit customer ID to facilitate
          the connection to your account.
        </p>
        <p>
          The App will access and retrieve customer balance and invoice data specifically authorized
          by you.
        </p>

        <p>
          <b>Information We Do Not Collect or Store:</b> We do not collect or store any other
          personally identifiable information such as names, addresses, or financial details beyond
          the specified customer balance and invoice data.
        </p>

        <b>How We Use Your Information</b>

        <p>
          The App uses the collected information solely to display your company's customer balance
          and invoice data within the App's interface.
        </p>
        <p>
          We will not share, sell, or otherwise distribute your information to any third-party
          entities for any purpose.
        </p>

        <b>Data Security</b>

        <p>
          We understand the sensitivity of your financial data. All access to your Intuit account is
          performed through Intuit's secure authentication and data transmission protocols.
        </p>
        <p>We do not store retrieved data within the App, minimizing any potential risks.</p>

        <b>Changes to this Privacy Policy</b>

        <p>
          We reserve the right to modify this Policy as needed. If we make substantial changes, we
          will provide a clear notification within the App and/or via email.
        </p>

        <b>Your Consent</b>

        <p>
          By using the App, you consent to the collection, use, and limited sharing of data as
          outlined in this Policy.
        </p>

        <b>Contact Us</b>

        <p>
          If you have questions or concerns about our privacy practices, please contact us at:
          hello@downrightmerch.com
        </p>
      </div>
    </div>
  );
}
