import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;

export default function Footer() {
  const { t } = useTranslation('common');
  return (
    <div className="pt-6 mt-6 text-center border-0 border-t border-solid border-t-border-gray">
      <Text>{t('footer.copyrights')}</Text>
    </div>
  );
}
