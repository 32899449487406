import { useSideMenuStore } from '@/store/useSideMenuStore';
import { sideMenuWidth } from '@/styles/constants';
import { useWindowSize } from 'react-use';

export const useDashboardContainerWidth = () => {
  const { width } = useWindowSize();
  const { isSideMenuOpen } = useSideMenuStore();

  const sideMenuWidthCalc = isSideMenuOpen ? sideMenuWidth.desktop : sideMenuWidth.mobile;

  const dashboardContainerWidth = width - sideMenuWidthCalc;

  return dashboardContainerWidth;
};
