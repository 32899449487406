import Footer from '../layout/footer';
import { Outlet } from 'react-router-dom';

export default function AuthLayout() {
  return (
    <div className="flex flex-col min-h-screen p-3 bg-main-background">
      <Outlet />
      <div className="py-6">
        <Footer />
      </div>
    </div>
  );
}
