import { useMediaQuery } from '@/lib/hooks/useMediaQuery';
import { dashboardSectionPadding } from '@/styles/constants';
import { PropsWithChildren } from 'react';

export default function DashboardSection({ children }: PropsWithChildren) {
  const isDesktop = useMediaQuery();

  return (
    <div
      className="flex flex-col gap-6 bg-white rounded-md"
      style={{
        padding: isDesktop ? dashboardSectionPadding.desktop : dashboardSectionPadding.mobile,
      }}
    >
      {children}
    </div>
  );
}
