import { ApiUrls } from '@/config/urls';
import useSWR, { Fetcher } from 'swr';
import { components } from '@/types/api-schema';
import { defaultHeaders, parseResponseOrThrowErr } from '../api/fetchers';
import { SortOrder } from '@/types';

type OrderResponse = components['schemas']['OrdersResponse'];

type OrderRequest = components['schemas']['OrdersBody'];

export type UnsortedOrdersSortOptions = components['schemas']['OrdersBody']['sort'];

export const ORDERS_PER_PAGE = 10;

const DEFAULT_SORT = { orderCreatedAt: 'desc' as const };

const getOrders: Fetcher<
  OrderResponse,
  {
    url: string;
    sort?: OrderRequest['sort'];
    filter?: OrderRequest['filter'];
  }
> = async ({ url, sort, filter }) => {
  const body: OrderRequest = {
    sort: sort ? { ...sort } : { ...DEFAULT_SORT },
    filter: { ...filter },
  };

  const res = await fetch(url, {
    headers: defaultHeaders,
    method: 'POST',
    body: JSON.stringify(body) as BodyInit,
  });

  return parseResponseOrThrowErr(res);
};

export const useUnsortedOrders = (
  page = 1,
  limit: number,
  sort?: keyof UnsortedOrdersSortOptions | null,
  order?: SortOrder | null,
  from?: string,
  to?: string,
) => {
  const offset = (page - 1) * limit;

  let sortObj: OrderRequest['sort'] | undefined;
  let searchObj: OrderRequest['search'] | undefined;
  let filterObj: OrderRequest['filter'] | undefined;

  if (sort && order) {
    sortObj = { [sort]: order };
  }

  if (from && to) {
    filterObj = {
      timePeriod: {
        from,
        to,
      },
    };
  }

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    {
      url: `${ApiUrls.ORDER}/unsorted-orders?limit=${limit}&offset=${offset}`,
      sort: sortObj,
      search: searchObj,
      filter: filterObj,
    },
    getOrders,
    {
      keepPreviousData: true,
    },
  );

  return {
    unsortedOrders: data,
    mutate,
    isLoading,
    isValidating,
    isError: error,
  };
};
