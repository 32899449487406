import { twMerge } from 'tailwind-merge';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import EyeInvisibleOutlined from '@ant-design/icons/EyeInvisibleOutlined';
import { TabsState } from '@/types';
import { Tabs } from 'antd';

type Props = {
  setActiveTab: (val: TabsState) => void;
  activeTab: TabsState;
  visibleLabel: string;
  hiddenLabel: string;
};

export default function StatementsTableTabs({
  setActiveTab,
  activeTab,
  visibleLabel,
  hiddenLabel,
}: Props) {
  const tabItems = [
    {
      key: TabsState.VISIBLE,
      label: (
        <div
          onClick={() => setActiveTab(TabsState.VISIBLE)}
          className={twMerge(
            ' no-underline cursor-pointer  hover:text-link-blue ',
            activeTab === TabsState.VISIBLE
              ? 'text-link-blue  border-link-blue'
              : 'text-text-primary',
          )}
        >
          <div className="flex gap-2">
            <EyeOutlined />
            {visibleLabel}
          </div>
        </div>
      ),
      children: null,
    },
    {
      key: TabsState.HIDDEN,
      label: (
        <div
          onClick={() => setActiveTab(TabsState.HIDDEN)}
          className={twMerge(
            'no-underline cursor-pointer  hover:text-link-blue ',
            activeTab === TabsState.HIDDEN
              ? 'text-link-blue  border-link-blue'
              : 'text-text-primary',
          )}
        >
          <div className="flex gap-2">
            <EyeInvisibleOutlined />
            {hiddenLabel}
          </div>
        </div>
      ),
      children: null,
    },
  ];

  const activeTabKey = activeTab === TabsState.VISIBLE ? TabsState.VISIBLE : TabsState.HIDDEN;

  return <Tabs items={tabItems} activeKey={activeTabKey} />;
}
