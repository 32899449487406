import DashboardHeader from '@/components/dashboard/header';
import { PageUrls } from '@/config/urls';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useCurrentUserContext } from '@/components/providers/user/use-current-user-context';

export default function LayoutSettings() {
  const { t } = useTranslation('settings');
  const { user } = useCurrentUserContext();

  const links = [
    {
      href: PageUrls.PROFILE,
      label: t('links.profile'),
    },
    {
      href: PageUrls.COMPANY_PROFILE,
      label: t('links.company-profile'),
    },
    {
      href: PageUrls.MEMBERS,
      label: t('links.manage-members'),
    },
    {
      href: PageUrls.QUICKBOOKS,
      label: t('links.quickbooks'),
    },
  ];

  const filteredLinks = links.filter(link =>
    (link.href === PageUrls.COMPANY_PROFILE && user!.role === 'admin') ||
    (link.href === PageUrls.QUICKBOOKS && user!.role !== 'admin')
      ? null
      : link,
  );

  return (
    <div className="grid grid-rows-[min-content_1fr] bg-main-background">
      <DashboardHeader links={filteredLinks} title={t('title')} />
      <div>
        <Outlet />
      </div>
    </div>
  );
}
