import { PropsWithChildren } from 'react';
import SuperTokensReact, { SuperTokensWrapper } from 'supertokens-auth-react';
import SessionReact from 'supertokens-auth-react/recipe/session';
import EmailPasswordReact from 'supertokens-auth-react/recipe/emailpassword';
import { appInfo } from '@/config/appInfo';

SuperTokensReact.init({
  appInfo,
  recipeList: [EmailPasswordReact.init(), SessionReact.init()],
});

export const SuperTokensProvider = ({ children }: PropsWithChildren) => {
  return <SuperTokensWrapper>{children}</SuperTokensWrapper>;
};
