import { ORDERS_PER_PAGE, PendingShipmentSortOptions } from '@/lib/hooks/usePendingOrders';
import { Table, Empty } from 'antd';
import type { TableProps } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import { PendingShipment } from '@/types';
import { useTranslation } from 'react-i18next';
import { useTablePage } from '@/lib/hooks/useTablePage';
import { useTableSort } from '@/lib/hooks/useTableSort';
import ResponsiveTableContainer from '@/components/common/responsiveTableContainer';
import { tableScroll } from '@/styles/constants';
import { useUnsortedOrders } from '@/lib/hooks/useUnsortedOrders';
import { useUnsortedOrdersTableColumns } from '../useUnsortedOrdersTableColumns';
import ErrorComponent from '@/components/common/errorComponent';

type Props = {
  from?: string;
  to?: string;
};

export default function UnsortedOrdersTable({ from, to }: Props) {
  const { t } = useTranslation('clients');

  const { sort, setSort, order, orderForApi, setOrder } = useTableSort<
    keyof PendingShipmentSortOptions
  >(['orderCreatedAt']);

  const { page, limit, onPageChange } = useTablePage();

  const { unsortedOrders, isLoading, isError } = useUnsortedOrders(
    page,
    limit,
    sort,
    orderForApi,
    from,
    to,
  );

  const columns = useUnsortedOrdersTableColumns(sort, order);

  const total = unsortedOrders?.pagination.count || 0;

  const onChange: TableProps<PendingShipment>['onChange'] = (_, __, sorter) => {
    const o = sorter as SorterResult<PendingShipment>;
    setOrder(o?.order);
    setSort(o?.columnKey as string);
  };

  if (isError) {
    return <ErrorComponent message={isError?.message} />;
  }

  return (
    <>
      <ResponsiveTableContainer>
        <Table
          columns={columns}
          dataSource={unsortedOrders?.orders || []}
          onChange={onChange}
          loading={isLoading}
          scroll={{ x: tableScroll }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('pending-shipments.table.empty')}
              />
            ),
          }}
          rowKey={item => item.orderLineItem.id}
          pagination={
            total > ORDERS_PER_PAGE
              ? {
                  current: page,
                  total: total,
                  pageSize: limit,
                  onChange: onPageChange,
                  position: ['bottomLeft'],
                  showSizeChanger: true,
                }
              : false
          }
        />
      </ResponsiveTableContainer>
    </>
  );
}
