import { StringParam, useQueryParam } from 'use-query-params';
import { useTablePage } from './useTablePage';

export const useTableSearch = () => {
  const { setPage } = useTablePage();

  const [searchTerm, setSearchTerm] = useQueryParam('searchTerm', StringParam);

  const onTableSearch = (
    value: string,
    _: unknown,
    info?: {
      source?: 'clear' | 'input';
    },
  ) => {
    if (info?.source === 'input') {
      setSearchTerm(value);
      setPage(1);
    } else {
      setSearchTerm(null);
      setPage(1);
    }
  };

  return { searchTerm, setSearchTerm, onTableSearch };
};
