import { PaginationProps } from 'antd';
import { useEffect } from 'react';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

export const useTablePage = (initialLimit = 10) => {
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
  const [limit, setLimit] = useQueryParam('limit', withDefault(NumberParam, initialLimit));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page, limit]);

  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    if (limit === pageSize) {
      setPage(page);
    } else {
      setPage(1);
      setLimit(pageSize);
    }
  };

  return { page, setPage, limit, onPageChange };
};
