import { formatCurrency, formatDate } from '@/lib/utils';
import { type ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import InvoicesStatusCell from './invoices-status-cell';
import { InvoicePaymentStatus, InvoiceTableDataType } from '@/types';
import InvoicesDownloadCell from './invoices-download-cell';
import { tableWidths } from '@/styles/constants';

const columns: ColumnsType<InvoiceTableDataType> = [
  {
    title: 'id',
    key: 'Id',
    render: (_, { id }) => <>{id}</>,
    width: tableWidths.invoiceNumber,
  },
  {
    title: 'due-date',
    key: 'DueDate',
    dataIndex: 'DueDate',
    render: (_, { dueDate }) => <>{formatDate(dueDate)}</>,
    sorter: true,
    width: tableWidths.date,
  },
  {
    title: 'invoice-amount',
    key: 'TotalAmt',
    dataIndex: 'TotalAmt',
    render: (_, { totalAmount }) => <>{formatCurrency(totalAmount)}</>,
    sorter: true,
    width: tableWidths.balance,
  },
  {
    title: 'remaining-balance',
    key: 'Balance',
    dataIndex: 'Balance',
    render: (_, { balance }) => <>{formatCurrency(balance)}</>,
    sorter: true,
    width: tableWidths.balance,
  },
  {
    title: 'payment-status',
    key: 'paymentStatus',
    render: (_, { status }) => <InvoicesStatusCell status={status as InvoicePaymentStatus} />,
    width: tableWidths.status,
    filters: [
      {
        text: 'paid',
        value: InvoicePaymentStatus.FULLY_PAID,
      },
      {
        text: 'not-paid',
        value: InvoicePaymentStatus.NOT_PAID,
      },
      {
        text: 'overdue',
        value: InvoicePaymentStatus.OVERDUE,
      },
    ],
    filterMultiple: false,
  },
  {
    title: 'actions',
    key: 'actions',
    width: tableWidths.actions,
    render: (_, { id, clientName, clientId }) => (
      <InvoicesDownloadCell invoiceId={id} clientName={clientName} clientId={clientId} />
    ),
  },
];

export const useInvoicesTable = (
  sort?: string | null,
  order?: string | null,
  status?: InvoicePaymentStatus,
): ColumnsType<InvoiceTableDataType> => {
  const { t } = useTranslation('invoice');

  const defaultStatus = status ? [status] : [];

  return columns.map(column => {
    if (column.key === `${sort}`) {
      return {
        ...column,
        title: t(`table.${column.title}`),
        defaultSortOrder: order === 'ascend' ? ('ascend' as const) : ('descend' as const),
      };
    }
    if (column.key === 'paymentStatus') {
      return {
        ...column,
        title: t(`table.${column.title}`),
        defaultFilteredValue: defaultStatus,
        filters: column.filters?.map(item => ({ ...item, text: t(`table.${item.text}`) })),
      };
    }
    return {
      ...column,
      title: t(`table.${column.title}`),
    };
  });
};
