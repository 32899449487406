import { PageUrls } from '@/config/urls';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
  statementId: string;
};

export default function StatementActionCell({ statementId }: Props) {
  const { t } = useTranslation('common');
  return <Link to={`${PageUrls.STATEMENTS}/${statementId}`}>{t('actions.view')}</Link>;
}
