import { Typography } from 'antd';
import SkeletonButton from 'antd/es/skeleton/Button';
import { twMerge } from 'tailwind-merge';

const { Text } = Typography;

type Props = {
  fieldName: string | JSX.Element;
  value?: string | number | JSX.Element;
  variant?: 'sm' | 'md';
  loading?: boolean;
};

export default function InfoTile({ fieldName, value, variant = 'sm', loading = false }: Props) {
  return (
    <div className="flex items-center justify-between ">
      <div className="flex flex-col w-full gap-2 text-center sm:w-auto sm:text-left">
        {loading ? (
          <>
            <SkeletonButton style={{ height: 24 }} active />
            <SkeletonButton style={{ height: 20 }} active />
          </>
        ) : (
          <>
            <Text
              type="secondary"
              className={twMerge(
                '!m-0 font-medium  leading-0',
                variant === 'sm' ? 'text-sm' : 'text-base !text-text-primary',
              )}
            >
              {fieldName}
            </Text>
            <Text className="!m-0  text-sm min-h-5">{value}</Text>
          </>
        )}
      </div>
    </div>
  );
}
