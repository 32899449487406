import DownloadButton from '@/components/common/downloadButton';
import { ApiUrls } from '@/config/urls';
import { defaultHeaders } from '@/lib/api/fetchers';
import { useState } from 'react';
import kebabCase from 'lodash/kebabCase';

type Props = {
  statementId: string;
  date: string;
  clientName: string;
};

export function StatementDownload({ statementId, clientName, date }: Props) {
  const [isDownloading, setIsDownloading] = useState(false);

  const onDownLoad = () => {
    setIsDownloading(true);

    fetch(`${ApiUrls.STATEMENT_DOWNLOAD}/${statementId}`, {
      headers: defaultHeaders,
    })
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);

        const hiddenElement = document.createElement('a');

        hiddenElement.href = url;
        hiddenElement.download = `${kebabCase(clientName).replace(/-/g, '_')}_${kebabCase(date).replace(/-/g, '_')}_statement.xlsx`;
        hiddenElement.target = '_blank';

        hiddenElement.click();
        setIsDownloading(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch(_ => {
        setIsDownloading(false);
      });
  };

  return <DownloadButton onClick={onDownLoad} isLoading={isDownloading} />;
}
