import { formatCurrency } from '@/lib/utils';
import round from 'lodash/round';

type Props = {
  paymentProcessing: string;
  paymentProcessingFlat: string;
};

export default function ClientPaymentProcessingCell({
  paymentProcessing,
  paymentProcessingFlat,
}: Props) {
  const paymentProcessingFormatted = `${round(Number(paymentProcessing) * 100, 2)}%`;

  const paymentProcessingFlatFormatted = formatCurrency(paymentProcessingFlat);

  return (
    <>
      {paymentProcessingFormatted} + {paymentProcessingFlatFormatted}
    </>
  );
}
