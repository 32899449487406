import { createContext } from 'react';
import { UserWithClient } from '@/types';

type CurrentUserContext = {
  user: UserWithClient | undefined;
  isLoading: boolean;
  isError: boolean;
};

export const CurrentUserContext = createContext<CurrentUserContext>({
  user: undefined,
  isLoading: false,
  isError: false,
});
