import { ApiUrls } from '@/config/urls';
import useSWR, { Fetcher } from 'swr';
import { components } from '@/types/api-schema';
import { defaultHeaders, parseResponseOrThrowErr } from '../api/fetchers';
import { SortOrder } from '@/types';

type OrderResponse = components['schemas']['OrdersResponse'];

type OrderRequest = components['schemas']['OrdersBody'];

export type PendingShipmentSortOptions = components['schemas']['OrdersBody']['sort'];

export const ORDERS_PER_PAGE = 10;

const DEFAULT_SORT = { orderCreatedAt: 'asc' as const };

const getOrders: Fetcher<
  OrderResponse,
  {
    url: string;
    sort?: OrderRequest['sort'];
    search: OrderRequest['search'];
    filter?: OrderRequest['filter'];
  }
> = async ({ url, sort, search, filter }) => {
  const body: OrderRequest = {
    sort: sort ? { ...sort } : { ...DEFAULT_SORT },
    search,
    filter: { ...filter },
  };

  const res = await fetch(url, {
    headers: defaultHeaders,
    method: 'POST',
    body: JSON.stringify(body) as BodyInit,
  });

  return parseResponseOrThrowErr(res);
};

export const usePendingOrders = (
  id: string | undefined | null,
  page = 1,
  limit: number,
  sort?: keyof PendingShipmentSortOptions | null,
  order?: SortOrder | null,
  searchTerm?: string | null,
) => {
  const offset = (page - 1) * limit;

  let sortObj: OrderRequest['sort'] | undefined;
  let searchObj: OrderRequest['search'] | undefined;
  let filterObj: OrderRequest['filter'] | undefined;

  if (id) {
    filterObj = { clientId: id };
  }

  if (searchTerm) {
    searchObj = { name: searchTerm };
  }

  if (sort && order) {
    sortObj = { [sort]: order };
  }

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    {
      url: `${ApiUrls.ORDER}/pending-orders?limit=${limit}&offset=${offset}`,
      sort: sortObj,
      search: searchObj,
      filter: filterObj,
    },
    getOrders,
    {
      keepPreviousData: true,
    },
  );

  return {
    pendingOrders: data,
    mutate,
    isLoading,
    isValidating,
    isError: error,
  };
};
