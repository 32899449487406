import { ClientStatus } from '@/types';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  status: ClientStatus;
};

export default function ClientStatusTag({ status }: Props) {
  const { t } = useTranslation('clients');

  const translationKey = status === ClientStatus.ACTIVE ? 'active' : 'inactive';
  return (
    <>
      {status && (
        <Tag color={status === ClientStatus.ACTIVE ? 'green' : 'red'}>{t(translationKey)}</Tag>
      )}
    </>
  );
}
