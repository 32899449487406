import { useRef, forwardRef, useImperativeHandle } from 'react';
import { DatePicker, Form, Input, Typography } from 'antd';
import { components } from '@/types/api-schema';
import { z } from 'zod';
import { Controller, SubmitHandler, UseFormReset, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import FormItemWrapper from '@/components/form/form-item-wrapper';
import dayjs from 'dayjs';

const { Text } = Typography;

type CreateExpenseInputType = components['schemas']['CreateExpenseRequestBody'];

export type CreateExpenseForm = Omit<CreateExpenseInputType, 'statementId'>;

const validateNumber = (val: unknown) => {
  if (val === '0') {
    return true;
  }
  return Boolean(val) && Number(val);
};

const createExpenseFormSchema: z.ZodType<CreateExpenseForm> = z
  .object({
    orderNumber: z.string({ required_error: 'common.required-field' }),
    description: z.string({ required_error: 'common.required-field' }),
    date: z.string({ required_error: 'common.required-field' }),
    total: z
      .string({ required_error: 'common.required-field' })
      .refine(validateNumber, { message: 'common.number' }),
    note: z.string({ required_error: 'common.required-field' }).optional(),
  })
  .strict();

export type CreateExpenseFormSchema = z.infer<typeof createExpenseFormSchema>;

interface Props {
  onSubmit: SubmitHandler<CreateExpenseForm>;
  defaultValues?: Partial<CreateExpenseForm>;
}

export type AddEditFormHandle = {
  resetForm: UseFormReset<CreateExpenseForm>;
  submitForm: () => void;
};

export default forwardRef<AddEditFormHandle, Props>(function AddEditExpenseForm(
  { defaultValues, onSubmit },
  ref,
) {
  const { t } = useTranslation(['statement', 'common', 'errors']);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { control, handleSubmit, reset } = useForm<CreateExpenseForm>({
    resolver: zodResolver(createExpenseFormSchema),
    defaultValues: {
      orderNumber: defaultValues?.orderNumber || undefined,
      description: defaultValues?.description || undefined,
      date: defaultValues?.date || undefined,
      total: defaultValues?.total || undefined,
      note: defaultValues?.note || undefined,
    },
  });

  useImperativeHandle(ref, () => ({
    resetForm: reset,
    submitForm: () => {
      buttonRef.current?.click();
    },
  }));

  return (
    <>
      <form className="pb-4" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="description"
          control={control}
          render={({ field, formState: { errors } }) => (
            <>
              <Form.Item
                validateStatus={errors.description ? 'error' : 'success'}
                help={
                  errors.description?.message && t(errors.description.message, { ns: 'errors' })
                }
              >
                <label className="flex flex-col gap-1">
                  <Text type="secondary">{t('expenses.common.description')}</Text>
                  <Input {...field} />
                </label>
              </Form.Item>
            </>
          )}
        />

        <Controller
          name="orderNumber"
          control={control}
          render={({ field, formState: { errors } }) => (
            <FormItemWrapper
              hasError={!!errors.orderNumber}
              errorMessage={
                errors.orderNumber?.message && t(errors.orderNumber.message, { ns: 'errors' })
              }
              label={t('expenses.common.order-number')}
            >
              <Input {...field} />
            </FormItemWrapper>
          )}
        />

        <Controller
          name="date"
          control={control}
          render={({ field, formState: { errors } }) => (
            <>
              <Form.Item
                validateStatus={errors.date ? 'error' : 'success'}
                help={errors.date?.message && t(errors.date.message, { ns: 'errors' })}
              >
                <label className="flex flex-col gap-1">
                  <Text type="secondary">{t('expenses.common.date')}</Text>
                  <DatePicker
                    onChange={date => {
                      field.onChange(date ? dayjs(date.valueOf()).format('MM/DD/YYYY') : null);
                    }}
                    status={errors.date ? 'error' : undefined}
                    value={field.value ? dayjs(field.value as string) : null}
                    ref={field.ref}
                    name={field.name}
                    onBlur={field.onBlur}
                    format={'MM-DD-YYYY'}
                  />
                </label>
              </Form.Item>
            </>
          )}
        />

        <Controller
          name="total"
          control={control}
          render={({ field, formState: { errors } }) => (
            <>
              <Form.Item
                validateStatus={errors.total ? 'error' : 'success'}
                help={errors.total?.message && t(errors.total.message, { ns: 'errors' })}
              >
                <label className="flex flex-col gap-1">
                  <Text type="secondary">{t('expenses.common.total')}</Text>
                  <Input prefix={'$'} {...field} />
                </label>
              </Form.Item>
            </>
          )}
        />

        <Controller
          name="note"
          control={control}
          render={({ field, formState }) => (
            <>
              <Form.Item
                validateStatus={formState.errors.note ? 'error' : 'success'}
                help={formState.errors.note?.message}
              >
                <label className="flex flex-col gap-1">
                  <Text type="secondary">{t('expenses.common.note')}</Text>
                  <Input {...field} />
                </label>
              </Form.Item>
            </>
          )}
        />
        <button type="submit" className="hidden" ref={buttonRef}></button>
      </form>
    </>
  );
});
