import { InventoryStructure, PayoutMethod, SortOrder } from '@/types';
import { format } from 'date-fns';
import { startOfMonth, endOfMonth } from 'date-fns';
import round from 'lodash/round';

export const formatDate = (value: string | number | Date | undefined) => {
  if (value && new Date(value)) {
    return format(new Date(value), 'MM/dd/yyyy');
  }
  return '--';
};

export const formatAntTableOrder = (order: string | null | undefined) => {
  if (order === 'ascend') return SortOrder.ASC;
  if (order === 'descend') return SortOrder.DESC;
  return undefined;
};

export const formatCurrency = (value: string | number | undefined) => {
  if (Number(value)) {
    return Number(value).toLocaleString('us-US', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'narrowSymbol',
    });
  }
  return (0).toLocaleString('us-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
  });
};

export const monthsMap = {
  1: 'january',
  2: 'february',
  3: 'march',
  4: 'april',
  5: 'may',
  6: 'june',
  7: 'july',
  8: 'august',
  9: 'september',
  10: 'october',
  11: 'november',
  12: 'december',
};

export const formatVendorName = (name: string) => name.toLowerCase().replace(/[^a-z0-9]/g, '');

export const getFirstAndLastDayOfMonth = (month: number | string, year: number | string) => {
  const date = new Date(`${month}/15/${year}`);

  const firstDay = startOfMonth(date);

  const lastDay = endOfMonth(date);

  return { firstDay, lastDay };
};

export const calculateCommissions = ({
  commissionRateMerch,
  commissionRateDigital,
  commissionRateMedia,
}: {
  commissionRateMerch: number;
  commissionRateDigital: number;
  commissionRateMedia: number;
}) =>
  `${round(commissionRateMerch * 100, 2)}%/${round(commissionRateMedia * 100, 2)}%/${round(
    commissionRateDigital * 100,
    2,
  )}%`;

export const getPayoutMethodLabelKey = (payoutMethod: PayoutMethod) => {
  return `additional-info.payout-method.${payoutMethod}`;
};

export const getInventoryStructureLabelKey = (inventoryStructure: InventoryStructure) => {
  return `additional-info.inventory-structure.${inventoryStructure.replace(/_/g, '-')}`;
};
