import DownloadButton from '@/components/common/downloadButton';
import { ApiUrls } from '@/config/urls';
import { useState } from 'react';
import kebabCase from 'lodash/kebabCase';

type Props = {
  invoiceId: string;
  clientName: string;
  clientId: string;
};

export default function InvoicesDownloadCell({ invoiceId, clientId, clientName }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const onClick = () => {
    setIsLoading(true);
    fetch(`${ApiUrls.QUICKBOOKS}/${clientId}/invoice/${invoiceId}/pdf`)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);

        const hiddenElement = document.createElement('a');

        hiddenElement.href = url;
        hiddenElement.download = `invoice_${kebabCase(clientName)}_${invoiceId}.pdf`;
        hiddenElement.target = '_blank';

        hiddenElement.click();
        setIsLoading(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch(_ => {
        setIsLoading(false);
      });
  };

  return <DownloadButton onClick={onClick} isLoading={isLoading} />;
}
