import DashboardSection from '@/components/dashboard/section';
import {
  EXPENSES_PER_PAGE,
  ExpensesSortableColumns,
  useStatementExpensesTable,
} from '@/components/expenses/useStatementExpensesTable';
import { Empty, Table, TableProps, Typography } from 'antd';
import Search from 'antd/es/input/Search';
import { useTranslation } from 'react-i18next';
import AddExpenseModal from '@/components/expenses/add-expense-modal';
import { useParams } from 'react-router-dom';
import { useStatementStore } from '@/store/useStatementStore';
import { AntSortOrder, ExpenseData, UserRole } from '@/types';
import { ExpenseItems } from '@/store/types';
import { compareAsc, compareDesc } from 'date-fns';
import { SorterResult } from 'antd/es/table/interface';
import { useCurrentUserContext } from '@/components/providers/user/use-current-user-context';
import ResponsiveTableContainer from '@/components/common/responsiveTableContainer';
import { tableScroll } from '@/styles/constants';
import { useRef } from 'react';

const formatSortParam = (param: string | null | undefined) => {
  if (param && Object.values(ExpensesSortableColumns).includes(param as ExpensesSortableColumns)) {
    return param as ExpensesSortableColumns;
  }
};

const formatOrderParam = (param: string | null | undefined) => {
  if (param && Object.values(AntSortOrder).includes(param as AntSortOrder)) {
    return param as AntSortOrder;
  }
};

function sortExpenses(
  expenses: ExpenseItems,
  sortField: ExpensesSortableColumns | undefined,
  order: AntSortOrder | undefined,
) {
  if (sortField && order) {
    return [...expenses].sort((a, b) => {
      if (order === AntSortOrder.ASC) {
        return compareAsc(new Date(a[sortField] as string), new Date(b[sortField] as string));
      } else {
        return compareDesc(new Date(a[sortField] as string), new Date(b[sortField] as string));
      }
    });
  } else {
    return expenses;
  }
}

const filterExpenses = (expenses: ExpenseItems, filter: string | undefined | null) => {
  if (!filter || typeof filter !== 'string') {
    return expenses;
  }
  return [...expenses].filter(transaction =>
    transaction.orderNumber.toLowerCase().includes(filter.toLowerCase()),
  );
};

export default function ClientStatementExpenses() {
  const sectionRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation('statement');

  const params = useParams();
  const { user } = useCurrentUserContext();

  const isAdmin = user?.role === UserRole.ADMIN;
  const statementId = params.id;

  const { expenseItems, setExpensesPage, setExpensesSort, setExpensesSearch } = useStatementStore();

  const { page, items, limit, sort, order, searchTerm } = expenseItems;

  const onPageChange = (page: number, limit: number) => {
    if (sectionRef?.current) {
      sectionRef.current.scrollIntoView();
    }
    setExpensesPage(page, limit);
  };

  const onTableSearch = (searchTerm: string) => {
    setExpensesSearch(searchTerm);
  };

  const onChange: TableProps<ExpenseData>['onChange'] = (_, __, sorter) => {
    const o = sorter as SorterResult<ExpenseData>;

    const formattedSortColumn = formatSortParam(o?.columnKey as string);
    const formattedOrder = formatOrderParam(o.order);

    setExpensesSort(formattedSortColumn, formattedOrder);
  };

  const columns = useStatementExpensesTable(sort, order, isAdmin);

  const formattedExpenses = filterExpenses(sortExpenses(items, sort, order), searchTerm);

  const total = items.length;

  return (
    <div ref={sectionRef}>
      <DashboardSection>
        <div className="flex flex-col items-stretch justify-between gap-2 sm:items-center sm:flex-row">
          <Typography.Title level={4} className="!m-0">
            {t('nav.expenses')}
          </Typography.Title>
          {isAdmin && <AddExpenseModal statementId={statementId} />}
        </div>
        <div className="grid items-center md:grid-cols-2 lg:grid-cols-3">
          <Search
            defaultValue={searchTerm || ''}
            placeholder={t('search.placeholder')}
            onSearch={onTableSearch}
            enterButton
            allowClear
          />
          <div />
        </div>
        <ResponsiveTableContainer inDashboardSection={true}>
          <Table
            columns={columns}
            dataSource={formattedExpenses}
            onChange={onChange}
            rowKey="id"
            scroll={{ x: tableScroll }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('expenses.common.table-empty')}
                />
              ),
            }}
            pagination={
              total > EXPENSES_PER_PAGE
                ? {
                    current: page,
                    total: total,
                    pageSize: limit,
                    onChange: onPageChange,
                    position: ['bottomLeft'],
                    showSizeChanger: true,
                  }
                : false
            }
          />
        </ResponsiveTableContainer>
      </DashboardSection>
    </div>
  );
}
