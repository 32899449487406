import DashboardHeader from '@/components/dashboard/header';
import { useCurrentUserContext } from '@/components/providers/user/use-current-user-context';
import StatementsTableSection from '@/components/statements/statements-table-section';
import { UserRole } from '@/types';
import { useTranslation } from 'react-i18next';

export default function Statements() {
  const { t } = useTranslation('statement');
  const { user } = useCurrentUserContext();

  const clientId = user!.clientId || undefined;

  const isAdmin = user!.role === UserRole.ADMIN;

  return (
    <div>
      <DashboardHeader title={t('statements')} />
      <StatementsTableSection isSingleClientView={false} clientId={clientId} isAdmin={isAdmin} />
    </div>
  );
}
