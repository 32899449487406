import { PageUrls } from '@/config/urls';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  message?: string;
};

export default function ErrorScreen({ message }: Props) {
  const { t } = useTranslation(['common', 'errors']);

  return (
    <div className="w-screen h-screen">
      <div className="flex items-center justify-center w-full h-full">
        <Result
          status="500"
          subTitle={t([`apiErrors.${message}`, 'apiErrors.generic'], {
            ns: 'errors',
          })}
          extra={<a href={PageUrls.HOME}>{t('error.back-home')}</a>}
        />
      </div>
    </div>
  );
}
