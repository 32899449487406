import { InventoryStatus } from '@/types';
import { useTranslation } from 'react-i18next';

type Props = {
  status: InventoryStatus;
};

export default function InventoryStatusCell({ status }: Props) {
  const { t } = useTranslation('clients');

  return <>{t(`pending-shipments.table.${status}`)}</>;
}
