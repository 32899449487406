import { ApiUrls } from '@/config/urls';
import useSWR, { Fetcher } from 'swr';
import { CustomError } from '../customError';
import { components } from '@/types/api-schema';
import { defaultHeaders } from '../api/fetchers';

export type StatementResponse = components['schemas']['GetStatementResponse'];

export const getStatement: Fetcher<StatementResponse, { url: string }> = async ({ url }) => {
  const res = await fetch(url, {
    headers: defaultHeaders,
  });

  if (res.status === 200 || res.status === 201) {
    return await res.json();
  }
  const info = await res.json();
  const error = new CustomError(info.message, res.status);

  throw error;
};

export const useStatement = (statementId?: string, clientId?: string | null) => {
  const baseUrl = `${ApiUrls.STATEMENT}/${statementId}`;

  const url = clientId ? `${baseUrl}?clientId=${clientId}` : baseUrl;

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    statementId ? { url } : null,
    getStatement,
  );

  return {
    statementData: data,
    mutate,
    isLoading,
    isValidating,
    isError: error,
  };
};
