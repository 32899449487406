import { SessionAuth, useSessionContext } from 'supertokens-auth-react/recipe/session';
import { Flex, Spin } from 'antd';
import { useCurrentUserContext } from '../providers/user/use-current-user-context';
import ErrorComponent from '../common/errorComponent';

export function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const sessionContext = useSessionContext();

  const { isLoading: isLoadingUser, isError } = useCurrentUserContext();

  if (sessionContext.loading || isLoadingUser) {
    return (
      <Flex align="center" justify="center" gap="middle" className="w-screen h-screen">
        <Spin size="large" />
      </Flex>
    );
  }

  if (isError) {
    return (
      <div className="w-screen h-screen ">
        <ErrorComponent message="user-fetch-failed" withLogout />
      </div>
    );
  }

  return <SessionAuth>{children}</SessionAuth>;
}
