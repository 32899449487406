import { useRef, useState } from 'react';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { Button, Modal } from 'antd';
import { components } from '@/types/api-schema';
import { SubmitHandler } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';
import { createFetcher } from '@/lib/api/fetchers';
import { ApiUrls } from '@/config/urls';
import ErrorNotification from '@/components/form/error-notification';
import { useTranslation } from 'react-i18next';
import { ExpenseData, StatementStatus } from '@/types';
import AddEditExpenseForm, {
  AddEditFormHandle,
  CreateExpenseForm,
  CreateExpenseFormSchema,
} from '../add-edit-expense-form';
import { useStatementStore } from '@/store/useStatementStore';

type EditExpenseBodyType = components['schemas']['UpdateExpenseRequestBody'];

type Props = {
  expense: ExpenseData;
};

export default function EditExpenseModal({ expense }: Props) {
  const { t } = useTranslation(['statement', 'common', 'errors']);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const childRef = useRef<AddEditFormHandle>(null);

  const { editExpense, statement } = useStatementStore();

  const { trigger, error, isMutating } = useSWRMutation(
    `${ApiUrls.EXPENSE}/${expense.id}`,
    createFetcher<EditExpenseBodyType>('PUT'),
  );

  const onSubmit: SubmitHandler<CreateExpenseFormSchema> = async data => {
    try {
      const res = await trigger({
        body: {
          ...data,
        },
      });

      const expenseData = {
        orderNumber: res?.orderNumber || undefined,
        description: res?.description || undefined,
        date: (res?.date as string) || undefined,
        total: res?.total || undefined,
        note: res?.note || undefined,
      };

      editExpense(res);
      resetForm(expenseData);
      setIsModalOpen(false);
      resetForm(expenseData);
    } catch (err) {
      // error is handled by swr hook
    }
  };

  const resetForm = (formValues: Partial<CreateExpenseForm>) =>
    childRef.current?.resetForm(formValues);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    childRef.current?.submitForm();
  };

  const handleCancel = () => {
    childRef.current?.resetForm();
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        disabled={statement?.status !== StatementStatus.IN_PROGRESS}
        icon={<EditOutlined />}
        onClick={showModal}
      />

      <Modal
        maskClosable={false}
        title={t('expenses.add')}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: isMutating ? true : false }}
        cancelText={t('actions.cancel', { ns: 'common' })}
        okText={t('actions.submit', { ns: 'common' })}
      >
        <div className="py-4">
          {error && (
            <ErrorNotification
              text={t([`apiErrors.${error?.message}`, 'apiErrors.generic'], {
                ns: 'errors',
              })}
            />
          )}
        </div>
        <AddEditExpenseForm
          ref={childRef}
          onSubmit={onSubmit}
          defaultValues={{
            orderNumber: expense.orderNumber || undefined,
            description: expense?.description || undefined,
            date: (expense?.date as string) || undefined,
            total: expense?.total || undefined,
            note: expense?.note || undefined,
          }}
        />
      </Modal>
    </>
  );
}
