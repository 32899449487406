import { RangePickerProps } from 'antd/es/date-picker';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useTablePage } from './useTablePage';

const FromParamWithDefault = withDefault(
  StringParam,
  format(startOfMonth(new Date()), 'yyyy-MM-dd'),
);
const ToParamWithDefault = withDefault(StringParam, format(endOfMonth(new Date()), 'yyyy-MM-dd'));

const FromParam = StringParam;
const ToParam = StringParam;

export default function useRangeParams(withDefault = true) {
  const { setPage } = useTablePage();

  const [query, setQuery] = useQueryParams({
    from: withDefault ? FromParamWithDefault : FromParam,
    to: withDefault ? ToParamWithDefault : ToParam,
  });

  const onRangePickerChange: RangePickerProps['onChange'] = (_: unknown, dates: string[]) => {
    const [startDate, endDate] = dates;
    if (startDate && endDate) {
      setQuery({
        from: startDate,
        to: endDate,
      });
      setPage(1);
    }
  };

  return { from: query.from, to: query.to, onChange: onRangePickerChange };
}
