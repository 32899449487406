import { usePendingShipmentsTableColumns } from '@/components/shipments/usePendingShipmentsTableColumns';
import {
  ORDERS_PER_PAGE,
  PendingShipmentSortOptions,
  usePendingOrders,
} from '@/lib/hooks/usePendingOrders';
import { Table, Empty } from 'antd';
import type { TableProps } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import Search from 'antd/es/input/Search';
import { PendingShipment } from '@/types';
import { useTranslation } from 'react-i18next';
import { useTablePage } from '@/lib/hooks/useTablePage';
import { useTableSearch } from '@/lib/hooks/useTableSearch';
import { useTableSort } from '@/lib/hooks/useTableSort';
import ResponsiveTableContainer from '@/components/common/responsiveTableContainer';
import { tableScroll } from '@/styles/constants';
import ErrorComponent from '@/components/common/errorComponent';

type Props = {
  clientId: string | undefined | null;
};

export default function PendingShipmentsComponent({ clientId }: Props) {
  const { t } = useTranslation('clients');

  const { sort, setSort, order, orderForApi, setOrder } = useTableSort<
    keyof PendingShipmentSortOptions
  >(['orderCreatedAt']);

  const { page, limit, onPageChange } = useTablePage();
  const { searchTerm, onTableSearch } = useTableSearch();

  const { pendingOrders, isLoading, isError } = usePendingOrders(
    clientId,
    page,
    limit,
    sort,
    orderForApi,
    searchTerm?.trim(),
  );

  const columns = usePendingShipmentsTableColumns(sort, order);

  const total = pendingOrders?.pagination.count || 0;

  const onChange: TableProps<PendingShipment>['onChange'] = (_, __, sorter) => {
    const o = sorter as SorterResult<PendingShipment>;
    setOrder(o?.order);
    setSort(o?.columnKey as string);
  };

  if (isError) {
    return <ErrorComponent message={isError?.message} />;
  }

  return (
    <>
      <div className="w-full sm:w-[22rem] flex mb-6">
        <Search
          defaultValue={searchTerm || ''}
          placeholder={t('pending-shipments.search-placeholder')}
          onSearch={(value, event, info) => onTableSearch(value, event, info)}
          enterButton
          allowClear
        />
      </div>
      <ResponsiveTableContainer>
        <Table
          columns={columns}
          dataSource={pendingOrders?.orders || []}
          onChange={onChange}
          loading={isLoading}
          scroll={{ x: tableScroll }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('pending-shipments.table.empty')}
              />
            ),
          }}
          rowKey={item => item.orderLineItem.id}
          pagination={
            total > ORDERS_PER_PAGE
              ? {
                  current: page,
                  total: total,
                  pageSize: limit,
                  onChange: onPageChange,
                  position: ['bottomLeft'],
                  showSizeChanger: true,
                }
              : false
          }
        />
      </ResponsiveTableContainer>
    </>
  );
}
